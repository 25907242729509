import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';

import { submitFeedback, completeFeedback } from './actions';
import { triggerGAEvent } from '../../helpers/googleTagManager';

import './contact.scss';

const Modal = lazy(() => import('../../components/modal/Modal'));
const ReCaptcha = lazy(() => import('../../components/re-captcha/ReCaptcha'));

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            company: '',
            fname: '',
            email: '',
            phone: '',
            city: '',
            state: '',
            captchaId: '',
            captchaValidation: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);

        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        const { location: { state } } = this.props;
        const shouldTrigger = state && state.gaFrom

        window.scrollTo(0, 0);

        if (shouldTrigger) {
            triggerGAEvent({
                label: state.gaFrom,
                action: 'Button Click',
                category: 'Contact Us',
            });
        }
    }

    resetForm() {
      this.setState({
          message: '',
          company: '',
          fname: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          captchaId: '',
          captchaValidation: ''
      }, () => {
          this.recaptchaRef.current.reset();
      });
    }

    handleModalClose() {
        this.resetForm();
        this.props.dispatch(completeFeedback())
        this.props.history.push('/');
    }

    handleSubmit(e) {

        e.preventDefault();
        if (!this.state.captchaId) {
            return this.setState({ captchaValidation: 'Please solve the captcha above.'});
        }

        this.props.submitFeedback(this.state.message, this.state.company, this.state.fname, this.state.email, this.state.phone, this.state.city, this.state.state, this.state.captchaId);

        triggerGAEvent({
            label: 'Contact Us Submit',
            action: 'Button Click',
            category: 'Contact Us',
        });
    }

    handleInput(e) {
        const updates = {};
        updates[e.target.name] = e.target.value;

        this.setState(updates);
    }

    onCaptchaChange(value) {
        this.setState({
            captchaId: value,
            captchaValidation: ''
        });
    }

    getContactForm() {

        return (
            <form className="contact-form" data-gtm-contact-us-form  onSubmit={this.handleSubmit}>
                <div className="two-columns">
                    <div>
                        <div className="form-group">
                            <input
                                required
                                type="text"
                                name="company"
                                placeholder="Company name*"
                                onChange={this.handleInput}
                                value={this.state.company}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                required
                                type="text"
                                name="fname"
                                placeholder="Your name*"
                                onChange={this.handleInput}
                                value={this.state.fname}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                required
                                type="email"
                                name="email"
                                placeholder="Email address*"
                                onChange={this.handleInput}
                                value={this.state.email}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone number"
                                onChange={this.handleInput}
                                value={this.state.phone}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="city"
                                placeholder="City*"
                                required
                                onChange={this.handleInput}
                                value={this.state.city}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="state"
                                placeholder="State*"
                                required
                                onChange={this.handleInput}
                                value={this.state.state}
                            />
                        </div>
                        <div className="form-group">
                            <label>Your message</label>
                            <textarea
                                required
                                name="message"
                                placeholder="Please provide more information to help us direct your inquiry*"
                                rows="7"
                                onChange={this.handleInput}
                                value={this.state.message}
                            />
                        </div>
                        <Suspense fallback={<p className="loading">Loading...</p>}>
                            <ReCaptcha recaptchaRef={this.recaptchaRef} onChange={this.onCaptchaChange} />
                        </Suspense>
                        { !!this.state.captchaValidation && (
                            <p style={{color: '#900'}} >{this.state.captchaValidation}</p>
                        )}
                        <div className="submit">
                            {this.props.sending && <button disabled>Submiting, please wait...</button>}
                            {!this.props.sending && <button type="submit">Submit</button>}
                        </div>
                    </div>

                    <div>
                        <div className="form-group">
                            <label><strong>By Mail</strong></label>
                            <p>Ken’s Foods, Inc.<br />
                            P.O. Box 849<br />
                            One D’Angelo Drive<br />
                            Marlborough, MA 01752-0849</p>
                        </div>
                        <div className="form-group">
                            <label><strong>By Phone</strong></label>
                            <p>General Inquiries<br />
                            800-633-5800<br /><br />
                            Foodservice Sales and Marketing<br />
                            800-633-5800, ext.4297</p>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div className="body-wrapper contact pampas">
                <h2>Contact us</h2>
                {this.getContactForm()}
                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <Modal visible={this.props.showSuccessModal} onClose={() => this.handleModalClose()}>
                        <h2>Email was sent successfully!</h2>
                        <p className="descr">Your message has been successfully sent and we appreciate you contacting us. We will contact you shortly.</p>
                    </Modal>
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sending: state.contact.sending,
    showSuccessModal: state.contact.showSuccessModal
});

const mapDispatchToProps = (dispatch) => {
    return {
        submitFeedback: (message, company, fname, email, phone, city, state, captcha) => dispatch(submitFeedback(message, company, fname, email, phone, city, state, captcha)),
        dispatch: (a) => dispatch(a)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
