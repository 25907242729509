import React, { PureComponent } from 'react';

import './checkbox.scss';

class Checkbox extends PureComponent {

    render() {

        let label = null;

        if (this.props.label) {
            label = <span>{this.props.label}</span>;
        }

        return (
          <label className="checkbox">
              {label}
              <input type="checkbox" onChange={this.props.onChange} name={this.props.name} />
              <span className="checkmark"></span>
          </label>
        );
    }
}

export default Checkbox;
