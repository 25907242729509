import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { clearErrors } from '../../actions/errorActions'
import { withRouter } from 'react-router-dom'

import './modalError.scss'

class ModalError extends PureComponent {
    constructor(props) {
        super(props)

        this.clearErrors = this.clearErrors.bind(this)
    }

    clearErrors() {
        this.props.clearErrors()
    }

    render() {
        let errors = null

        if (this.props.errors.length > 0) {
            errors = this.props.errors.map((item, index) => (
                <p key="index">{item}</p>
            ))
        }

        if (this.props.errors.length === 0) {
            return null
        }
        return (
            <div className="modal-body visible">
                <div className="modal-content-wrapper error">
                    <button
                        className="modal-action close"
                        onClick={this.clearErrors}
                    />
                    <h2>Oops! Something went wrong.</h2>
                    {errors}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errors: state.errors,
})

export default withRouter(
    connect(
        mapStateToProps,
        { clearErrors }
    )(ModalError)
)
