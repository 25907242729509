import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import reactStringReplace from 'react-string-replace';

import Modal from './modal/Modal';
import { triggerGAEvent } from '../helpers/googleTagManager';

class ModalPlusOne extends PureComponent {

    handleGAClick(label, cb) {
        triggerGAEvent({
            cb, label,
            action: 'Button click',
            category: 'Plus One Pop Up',
        });
    }

    _parseIngredient(item) {
        const { selected, location, hideButtonGroup, selectedFromSearch } = this.props;
        const isSelectedFromMemcached = selectedFromSearch && (location.search || hideButtonGroup);
        const plusOne = isSelectedFromMemcached ? selectedFromSearch.details : selected;
        const weight = item.weight && this.props.uoms.find((uom) => uom.id === item.weight);

        if (item.productId && plusOne.related && plusOne.related.products) {
            const product = plusOne.related.products.find((p) => p.id === item.productId);
            if (product) {
                return (
                    <>
                        <span>{item.amount} {weight ? weight.name : item.weight}</span>{' '}
                        <span><a href={product.url}>{item.ingredient}</a></span>
                    </>
                );
            }
        }

        return (
            <>
                <span>
                    {item.amount} {weight ? weight.name : item.weight}
                </span>{' '}
                <span>
                    {item.ingredient}
                    {item.notes && `, ${item.notes}`}
                </span>
            </>
        );
    }

    _parseDirections(plusOne) {
        const { steps, related } = plusOne;

        return steps.map((step, i) => (step ? (
            <p key={`plus-one-step-${i}`}>
                {
                    reactStringReplace(step, /\[(.*?)\]/, (match, i) => {
                        let product = null;

                        if (related.products) {
                            product = related.products.find(p => p.code === match);
                        }

                        if (product) {
                            return (
                                <a key={`plus-one-step-product-${i}`} href={product.url}>
                                    {product.title} ({product.code})
                                </a>
                            );
                        }

                        return null;
                    })
                }
            </p>
        ) : null));
    }

    render() {

        let plusOne = null;
        const { visible, selected, location, hideButtonGroup, selectedFromSearch } = this.props;
        const isSelectedFromMemcached = selectedFromSearch && (location.search || hideButtonGroup);

        if (isSelectedFromMemcached) {
            plusOne = selectedFromSearch.details;
        } else {
            plusOne = selected;
        }

        return (
            <Modal visible={visible} onClose={this.props.onClose}>
                <span className="subtitle">make it your own</span>
                <h2 className="plus-one-modal-title">{plusOne.title}</h2>
                <div className="two-columns ingredients">
                    <div>
                        <strong>Ingredients</strong>
                        <ul>
                            {!!plusOne.ingredients.length && plusOne.ingredients.map((ingredient, idx) =>
                                <li key={`plus-one-ingredient-${idx}`}>{this._parseIngredient(ingredient)}</li>
                            )}
                        </ul>
                    </div>
                    <div>
                        <strong>Directions</strong>
                        {!!plusOne.steps.length && this._parseDirections(plusOne)}
                    </div>
                </div>
                {!hideButtonGroup && (
                    <div className="button-group">
                        <button className="plus-one-print-btn" data-gtm-plus-one-print onClick={() => this.handleGAClick('Print It', window.open(plusOne.pdfFilePath))}>print it</button>
                        <button className="plus-one-email-btn" data-gtm-plus-one-email onClick={() => this.handleGAClick('Email It', this.props.onEmailItClick)}>email it</button>
                    </div>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    uoms: state.lookup.uoms,
    location: state.router.location,
    selected: state.plusOnes.selected,
    selectedFromSearch: state.searchResults.selected
});

export default connect(mapStateToProps)(ModalPlusOne);
