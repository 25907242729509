//errors
export const ADD_ERROR = "ADD_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//screen resize
export const IS_MOBILE = 'IS_MOBILE';

// app level needs
export const LOAD_LOOKUP_DATA = 'LOAD_LOOKUP_DATA'
export const LOAD_LOOKUP_DATA_COMPLETE = 'LOAD_LOOKUP_DATA_COMPLETE';
