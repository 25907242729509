import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import './pouches.scss';

import pouch1 from '../../assets/img/products/ke0020b3.png';
import pouch2 from '../../assets/img/products/ke0572b3png_1540985062224.png';
import pouch3 from '../../assets/img/products/ke0788b3.png';
import pouch4 from '../../assets/img/products/ke0033b3.png';
import pouch5 from '../../assets/img/products/ke0636b3.png';
import pouch6 from '../../assets/img/products/ke1057b3.png';
import pouch7 from '../../assets/img/products/ke0041b3.png';
import pouch8 from '../../assets/img/products/ke0031b3.png';

import deliveryman from '../../assets/img/hero-pouches.jpg';
import greek from '../../assets/img/products/ke0788b3.png';
import bar from '../../assets/img/pouches-img1.jpg';
import salad from '../../assets/img/pouches-img2.jpg';
import laptop from '../../assets/img/pouches-img3.jpg';

const pouchesSlider = [
    {img: pouch1, link: "/products/creamy-italian-dressing/pouch"},
    {img: pouch2, link: "/products/sweet-and-spicy-honey-mustard/pouch"},
    {img: pouch3, link: "/products/greek-dressing/pouch"},
    {img: pouch4, link: "/products/country-french-with-orange-blossom-honey-dressing/pouch"},
    {img: pouch5, link: "/products/kens-essentials-lite-balsamic-with-olive-oil-vinaigrette/pouch"},
    {img: pouch6, link: "/products/kens-metropolitan-select-balsamic-vinaigrette/pouch"},
    {img: pouch7, link: "/products/lite-ranch-dressing-pouch/pouch"},
    {img: pouch8, link: "/products/parmesan-and-peppercorn-dressing/pouch"}
];

class Pouches extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getPouches() {
      return (

          pouchesSlider.map((item, idx) => {
              return (
                  <Link key={idx} to={item.link}>
                      <img src={item.img} alt="" />
                  </Link>
              )
          })
      );
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 8,
            slidesToScroll: 8,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <div className="hero-wrapper pouches">
                    <div className="hero-img">
                        <img src={deliveryman} alt="" />
                    </div>
                    <div>
                        <h1 className="no-shadow">It’s a <span>to-go</span> world</h1>
                        <div className="descr no-shadow">Ken’s pouches give customers what they want: the perfect flavor in the perfect size. Which is great, because on-the-go dining continues to grow like crazy.</div>
                    </div>
                    <div>
                        <img className="pouch-img" src={greek} alt="" />
                        <Link to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b68'}}} className="button">
                            See all pouches
                        </Link>
                    </div>
                </div>

                <div className="body-wrapper">
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={bar} alt="" />
                                <span>32%</span>
                            </div>
                            <div className="descr">of consumers believe they buy prepared food more often now than 2 years ago.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={salad} alt="" />
                                <span>86%</span>
                            </div>
                            <div className="descr">of consumers dine off-premise at least once a month.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={laptop} alt="" />
                                <span>30%</span>
                            </div>
                            <div className="descr">of consumers say they typically purchase more food when ordering carryout versus dining in.</div>
                        </div>
                    </div>
                    <div className="remark">Sources: Technomic, Forbes</div>
                </div>

                <div className="body-wrapper column pampas">
                    <Slider {...settings}>
                        {this.getPouches()}
                    </Slider>
                    <h2>Take on the takeout market and win</h2>
                    <div className="descr short">To-go diners want flavor and Ken’s gives it to them. In a wide variety of convenient, single serve pouches that will have them coming back for more.</div>
                    <Link to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b68'}}} className="button">
                        See all pouches
                    </Link>
                </div>

                <div className="body-wrapper column">
                    <h2>And speaking of delivery...</h2>
                    <Link className="button" to="/get-sample">request a sample</Link>
                </div>

                <div className="body-wrapper">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button">contact us</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pouches;
