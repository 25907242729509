import {
    CLEAR_ERRORS
} from './types';

export function clearErrors() {

    return {
        type: CLEAR_ERRORS
    }
}
