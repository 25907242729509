import _cloneDeep from 'lodash/cloneDeep'
import _shuffle from 'lodash/shuffle'

export const getListSortOrder = ({ sortOrder, sortParameter, data = [] }) => {
    const nextSortOrder = _cloneDeep(sortOrder)

    const nextData = data.map((e) => e.id)

    // old sort order
    if (sortOrder.sortParameter === sortParameter && sortOrder.order) {
        const newData = nextData.filter((id) => !sortOrder.order.includes(id))

        nextSortOrder.order = sortOrder.order.concat(_shuffle(newData))
    }
    // new sort order
    else {
        nextSortOrder.sortParameter = sortParameter

        nextSortOrder.order = _shuffle(nextData)
    }

    return nextSortOrder
}

export const updateListByOrder = ({ list = [], data = [], sortOrder }) => {
    const order = sortOrder.order

    return list.concat(
        data.sort((a, b) => {
            const indexA = order.indexOf(a.id)
            const indexB = order.indexOf(b.id)

            return indexA - indexB
        })
    )
}
