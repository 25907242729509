import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './signature.scss';

import packages from '../../assets/img/products-signature.png';
import taste1 from '../../assets/img/signature-img1.jpg';
import taste2 from '../../assets/img/signature-img2.jpg';
import taste3 from '../../assets/img/signature-img3.jpg';
import board1 from '../../assets/img/signature-board1.jpg';
import board2 from '../../assets/img/signature-board2.jpg';
import board3 from '../../assets/img/signature-board3.jpg';
import product1 from '../../assets/img/products/ke2632_2.png';
import product2 from '../../assets/img/products/ke2335_2.png';
import product3 from '../../assets/img/products/ke1118_2.png';

class Signature extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <div className="body-wrapper column">
                    <h1>Ken’s Signature Sauces</h1>
                    <img className="packages" src={packages} alt="" />
                    <div className="descr">These unique, artisan-quality sauces come in a wide range of flavors -<br/> with an even wider range of uses.</div>
                    <Link to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517056'}}} className="button">see all ken's signature sauces</Link>
                </div>

                <div className="hero-wrapper signature">
                    <h2>Kick things up a notch</h2>
                </div>

                <div className="body-wrapper">
                    <div className="descr short">Let your imagination run wild with our Signature Sauces. Use them as bold sandwich spreads, burger toppings, dipping sauces, glazes. The possibilities are endless… and oh-so delicious.</div>
                </div>

                <div className="body-wrapper column pampas">
                    <h2>People’s tastes are changing</h2>
                    <div className="taste-wrapper">
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste1} alt="" />
                                <h2>Unique<br/>flavors</h2>
                            </div>
                            <div className="descr">42% of consumers expect restaurants to offer signature flavors they can’t get anywhere else</div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste2} alt="" />
                                <h2>Bold and<br/> craveable</h2>
                            </div>
                            <div className="descr">45% of consumers say they crave bold flavors</div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste3} alt="" />
                                <h2>Adventurous palates</h2>
                            </div>
                            <div className="descr">Foodie culture has made consumers more interested in their food and more willing to learn about new cuisines</div>
                        </div>
                    </div>
                    <div className="remark">Source: Technomic Consumer Trend Reports 2019</div>
                </div>

                <div className="body-wrapper signature column">
                    <h2>Ken’s Signature sauces. Open to anything.</h2>
                    <div className="descr short">Open your menu, and your mind, to the endless possibilities of Ken’s Signature sauces. These super versatile sauces can take any recipe from ordinary to extraordinary. Just like that.</div>
                    <div className="taste-wrapper">
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={board1} alt="" />
                            </div>
                            <div className="taste-content">
                                <p>A classic beer mustard, <Link to="/products/kens-signature-brewpub-style-mustard">Ken’s Signature Brewpub Style Mustard</Link> combines spicy brown and classic Dijon mustards with brown sugar, soy, and beer barley malt for rich, delicious pub flavor.</p>
                                <img src={product1} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-content">
                                <p><Link to="/products/kens-signature-balsamic-fig-glaze">Ken’s Signature Balsamic Fig Glaze</Link> has a distinct flavor you won’t soon forget. Smooth notes of sweet fig combine with aged balsamic for a heavy-bodied, delicious glaze that’s incredibly versatile.</p>
                                <img src={product2} alt="" />
                            </div>
                            <div className="taste-img">
                                <img src={board2} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={board3} alt="" />
                            </div>
                            <div className="taste-content">
                                <p>Stone ground mustard, horseradish, garlic, lemon notes and a touch of sweetness give <Link to="/products/kens-signature-bistro-sauce">Ken’s Signature Bistro Sauce</Link> its unique flavor. Smooth and creamy, it’s a must-add for sandwiches, sides and more.</p>
                                <img src={product3} alt="" />
                            </div>
                        </div>
                    </div>
                    <Link to="/recipes#allRecipes" className="button">see all ken's signature sauce recipes</Link>
                </div>

                <div className="body-wrapper">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button">contact us</Link>
                    </div>
                </div>
            </div>
        );
    }
}


export default Signature;
