import _Get from 'lodash/get';
import _Clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    sitekey: null
};

export default (state = initialState, action) => {
    const updatedState = _Clone(state);

    switch (action.type) {
        case types.GET_RECAPTCHA_SITEKEY_SUCCESS:
            updatedState.sitekey = _Get(action, 'payload.sitekey');
            break;
        default:
            break;
    }

    return updatedState;
}
