import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import './home.scss';

import { search, loadBlocks } from './actions';
import ImageClient from '../../helpers/imageHandler';

const Search = lazy(() => import('../../components/search/Search'));
const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.blocks.length) {
            this.props.loadBlocks();
        }
    }

    handleSearchChange(searchTerm) {
        searchTerm && this.props.search(searchTerm);
    }

    renderBlocks() {
        const { blocks } = this.props;

        return blocks.length ? blocks.map((block, index) => {
            const imagePath = ImageClient.fitIn(block.image, { width: 300, height: 300 });

            return (
                <Link to={{ pathname: block.link }} className="block" key={`${block.title}-${index}`}>
                    <div className="block-img"><img className="no-zoom" src={imagePath} alt={block.title} /></div>
                    <div className={ (block.title === "Green Goddess" || block.title === "Avocado Ranch") ? "block-txt category bottom-title" : "block-txt category" }>
                        <div className="block-category">{block.title}</div>
                        <div className="block-title">{block.caption}</div>
                    </div>
                </Link>
            )
        }) : <p>Loading...</p>;
    }

    render() {
        return (
            <div>
                <div className="hero-wrapper home">
                    <h1><span className="accent">Welcome to Ken’s Foodservice.</span><br /> Family-owned. Flavor-obsessed.</h1>
                    <Suspense fallback={<p className="loading">Loading...</p>}>
                        <Search
                            className="search-wrapper"
                            results={this.props.searchResults}
                            loading={this.props.loadingResults}
                            placeholder={'What can we get you today?'}
                            onChange={(e) => { this.handleSearchChange(e.target.value) }}
                        />
                    </Suspense>
                </div>
                <div className="body-wrapper">
                    {this.renderBlocks()}
                    <Suspense fallback={<p>Loading...</p>}>
                        <FeedbackWrapper page="home" />
                    </Suspense>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    blocks: state.home.blocks,
    searchResults: state.home.searchResults,
    loadingResults: state.home.loadingResults
});

export default connect(mapStateToProps, { search, loadBlocks })(Home);
