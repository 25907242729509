import React, { PureComponent, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';

import './essentials.scss';

import packages from '../../assets/img/products-essentials.png';
import girl from '../../assets/img/essentials-girl.jpg';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

const blocks = [
    {txt: "NO", title: "high fructose corn syrup"},
    {txt: "NO", title: "artificial flavors"},
    {txt: "NO", title: "artificial preservatives"}
];

class Essentials extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getBlocks() {
        return (
            blocks.map((item, idx) => {
                return (
                    <div className="block" key={idx}>
                        <div className="block-img"><span>{item.txt}</span></div>
                        <div className="block-txt">
                            <div className="block-title">{item.title}</div>
                        </div>
                    </div>
                )
            })
        );
    }

    render() {

        return (
            <div>
                <div className="body-wrapper column">
                    <h1 className="short">Ken’s Essentials<br /> clean label dressings</h1>
                    <img className="packages" src={packages} alt="" />
                    <div className="descr">All the flavor your customers want, with none of the ingredients they don’t.</div>
                    <div className="button-group">
                        <Link className="button" to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517054', package: '5b6c4eaeb7400b01f9a16e00'}}}>see all ken's essentials gallons</Link>
                        <Link className="button" to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517054'}}}>see all ken's essentials</Link>
                    </div>
                </div>

                <div className="body-wrapper essentials pampas">
                    <h2>Clean up with clean label</h2>
                    <div className="feature-wrapper single">
                        <div>
                            <div className="feature-block">
                                <img src={girl} alt="" />
                                <span>77%</span>
                            </div>
                            <div className="descr">
                                of consumers say that the ingredients on food labels significantly influence their purchase decision.<br /> Follow the trend, follow the money.
                            </div>
                        </div>
                    </div>
                    <div className="remark">Source: Technomic Consumer Trend Reports 2019</div>
                </div>

                <div className="body-wrapper essentials column">
                    <h2>Right on the no’s</h2>
                    <div className="blocks">
                        {this.getBlocks()}
                    </div>
                    <div className="descr">Today’s customers want to spend less time trying to pronounce ingredients,<br/> and more time enjoying them.</div>
                </div>

                <div className="hero-wrapper essentials">
                    <h2>Packed with flavor</h2>
                    <div className="descr">With Ken’s, there’s no compromising on flavor. Ever.</div>
                    <Link className="button" to={{ pathname: '/products', state: { attribute: { hfcs: 'No', artificialFlavors: 'No', artificialPreservatives: 'No' }}}}>See all ken's clean label dressings</Link>
                </div>

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="essentials" />
                </Suspense>
            </div>
        );
    }
}


export default Essentials;
