import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import './dipping.scss';

import logo from '../../assets/img/offers/dipping-cups/kens-sbr-logos-white.png';
import fingerSauce from '../../assets/img/offers/dipping-cups/finger-sauce.png';
import pizzaRanch from '../../assets/img/offers/dipping-cups/pizza-ranch.png';
import picklesSauce from '../../assets/img/offers/dipping-cups/pickles-sauce.png';

import cup1 from '../../assets/img/offers/dipping-cups/buffalo-sauce.png';
import cup2 from '../../assets/img/offers/dipping-cups/hickory-sauce.png';
import cup3 from '../../assets/img/products/ke0634a5_kens_honey_mustard_cup_thumbnail.png';
import cup4 from '../../assets/img/products/ke0665a5_kens_bleu_cheese_cup_thumbnail.png';
import cup5 from '../../assets/img/products/ke2509a5_jalapeno_ranch_cup_thumbnaill.png';
import cup6 from '../../assets/img/products/ke0740a1_sweet_and_sour_sauce_cup_thumbnail.png';

const saucesSlider = [
    {img: cup1, name: "Sweet baby ray’s Buffalo sauce", link: "https://www.sbrfoodservice.com/products/buffalo-wing-sauce"},
    {img: cup2, name: "Sweet baby ray’s Hickory & Brown sugar barbecue sauce", link: "https://www.sbrfoodservice.com/products/hickory-barbecue-sauce"},
    {img: cup3, name: "Ken's Honey Mustard", link: "/products/kens-essentials-honey-mustard-dressing/cup"},
    {img: cup4, name: "Ken's Bleu Cheese", link: "/products/bleu-cheese-dressing/cup"},
    {img: cup5, name: "Ken's Jalapeno Ranch", link: "/products/jalapeno-ranch-dressing/cup"},
    {img: cup6, name: "Ken's Sweet & Sour Sauce", link: "/products/sweet-and-sour-sauce/cup"}
];

class Dipping extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getSauces() {
        return (
            saucesSlider.map((item, idx) => {
                return (
                    <div key={idx}>
                        {item.link.match('https') ? (
                            <a target="_blank" rel="noopener noreferrer" href={item.link} className="flavor-item">
                                <img src={item.img} alt="" />
                                <span>{item.name}</span>
                            </a>
                        ) : (
                            <Link to={item.link} className="flavor-item">
                                <img src={item.img} alt="" />
                                <span>{item.name}</span>
                            </Link>
                        )}
                    </div>
                )
            })
        );
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <div className="hero-wrapper dipping">
                    <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                    <h1>Make every dunk epic</h1>
                </div>

                <div className="body-wrapper dipping">
                    <h2 className="cross-line">EVERYONE'S DOING IT.</h2>
                    <div className="benefits-wrapper">
                        <div>
                            <div className="descr">
                                <span>Chicken Fingers + Sweet Baby Ray's Original Barbecue Sauce</span>
                                <p>This combo continues to be the reigning king.</p>
                                <a href="https://www.sbrfoodservice.com/products/original-award-winning-barbecue-sauce" target="_blank" rel="noopener noreferrer">Learn More &gt;</a>
                            </div>
                            <img src={fingerSauce} alt="" />
                        </div>
                        <div>
                            <div className="descr">
                                <span>Pizza + Ken’s Homestyle Ranch</span>
                                <p><strong>70%</strong> of millennials like dipping their pizza.</p>
                                <a href="https://www.kensfoodservice.com/products/homestyle-ranch-dressing" target="_blank" rel="noopener noreferrer">Learn More &gt;</a>
                            </div>
                            <img src={pizzaRanch} alt="" />
                        </div>
                        <div>
                            <div className="descr">
                                <span>Fried Pickles + Ken’s Boom Boom Sauce</span>
                                <p><strong>50%</strong> of diners prefer very spicy sauces.</p>
                                <a href="https://www.kensfoodservice.com/products/boom-boom-sauce" target="_blank" rel="noopener noreferrer">Learn More &gt;</a>
                            </div>
                            <img src={picklesSauce} alt="" />
                        </div>
                    </div>
                </div>

                <div className="body-wrapper dipping background">
                    <h2>THE (FLAVOR) OPTIONS ARE ENDLESS.</h2>
                </div>

                <div className="body-wrapper dipping">
                    <Slider {...settings}>
                        {this.getSauces()}
                    </Slider>
                </div>

                <div className="body-wrapper dipping column primary">
                    <h2>WANT A TASTE? YOU GOT IT.</h2>
                    <Link className="button" to="/get-sample">request a sample</Link>
                    <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Dipping Cups' }}}>request a sample</Link>
                </div>
            </div>
        );
    }
}

export default Dipping;
