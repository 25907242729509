import { actionTypes as types } from './constants';

import { stringNormalizer } from '../../helpers/normalizer';

export const search = (searchTerm) => ({
    type: types.SEARCH_START,
    payload: stringNormalizer(searchTerm)
});

export const clearSearch = () => ({
    type: types.SEARCH_CLEAR,
});

export const loadBlocks = () => ({
    type: types.LOAD_BLOCKS_START
});
