export const triggerGAEvent = ({ action, category, label, cb }) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: action,
            category,
            eventCallback: cb
        });
    } else if (typeof cb === 'function') cb();
}

export const prepareGTMDataset = (dataProp) => {
    const dataset = {};

    if (dataProp) {
        dataset[dataProp] = true;
    }

    return dataset;
}
