import React from 'react';
import { Route, Switch } from "react-router-dom";

import PageNotFound from '../page-not-found/PageNotFound';

import routes from '../../routes.json';

import './products.scss';

const ProductsRouter = () => {
    const product = routes.find(({ path, routes }) => path === '/products' && routes);
    if (!product) {
        throw new Error('Cannot find route declaration for product routes');
    }

    return (
        <Switch>
            {product.routes.map(({ path, component, props }, index) => {
                if (!path) {
                    return <Route key={index} component={PageNotFound} />
                }

                const RouteComponent = require(`${component}.jsx`).default;
                return (
                    <Route
                        key={index}
                        path={path}
                        component={RouteComponent}
                        {...props}
                    />
                );
            })}
        </Switch>
    );
};

export default ProductsRouter;
