import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './covid.scss';

import controlCups from '../../assets/pdf/kens_portion_control_cups_POS.pdf';
import controlPouches from '../../assets/pdf/kens_pouches_POS.pdf';
import dippingCups from '../../assets/pdf/kens_dipping_cups_brochure.pdf';

class Covid extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <div className="hero-wrapper covid">
                    <h2>Our Response to Coronavirus (COVID-19)</h2>
                    <h1>We are fully operational across 4 plants</h1>
                </div>

                <div className="body-wrapper">
                    <h2>We are here to help</h2>
                    <div className="descr short">Across our operations, Ken’s Foods continues to monitor developments related to the coronavirus (COVID-19) outbreak. We have taken strong action to protect the safety of our associates and customers as we continue operations. As one of America’s largest dressing and sauce companies with four fully operational regional food production facilities, we have taken all possible measures to ensure business continuity and to continue to service our customers.
                    </div>
                    <div className="descr short">Questions? Contact Foodservice Customer Service at <a href="mailto:fssalessupport@kensfoods.com">fssalessupport@kensfoods.com</a></div>
                </div>

                <div className="hero-wrapper covid2">
                    <h2>Products Suitable for Takeout, Delivery, and Grab & Go</h2>
                    <div className="button-group">
                        <Link to="/cups" className="button">learn more about cups</Link>
                        <Link to="/pouches" className="button">learn more about pouches</Link>
                    </div>
                </div>

                <div className="body-wrapper column pampas covid">
                    <h2>Resources</h2>
                    <div className="descr short">
                        We have many solutions that will help you reduce labor costs and food contact, and support portable  meals such as ready to use dressings, portion control pouches, and cups. To-go diners want flavor, and Ken's gives it to them. In a wide variety of convenient cups and pouches that will have them coming back for more.
                    </div>
                    <div className="button-group">
                        <a href={controlCups} className="button">Portion Control Cups POS</a>
                        <a href={controlPouches} className="button">Portion Control Pouches POS</a>
                        <a href={dippingCups} className="button">Dipping Cups Brochure</a>
                    </div>
                </div>

                <div className="body-wrapper column covid">
                    <div className="descr short">If you are interested in our retail products, please <Link to="/contact">contact us here.</Link></div>
                    <h2>Protecting the Safety of our Products, Facilities, and Offices</h2>
                    <div className="descr short">
                        We are actively striving to abide by state, local, and federal guidelines and regulations regarding the health of our team members and the safety of our products, facilities and offices. We have been diligent about educating our team members on the virus and always have processes in place to avoid infection.
                    </div>
                    <div className="descr short">
                        As new information becomes available, we will address our policies and procedures. We will provide any updates on this page to keep you informed of any additional actions we need to take.
                    </div>
                    <div className="button-group">
                        <Link to="/contact" className="button">contact us</Link>
                        <Link to="/get-sample" className="button">request a sample</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Covid;
