import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _Get from 'lodash/get';
import _Clone from 'lodash/clone';
import Select, { components } from 'react-select';

class Filters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupedOptions: null
        };
    }

    componentDidUpdate() {
        const { groupedOptions } = this.state;
        const { flavors, dishes, courses } = this.props;
        const isFiltersFilled = flavors.length && dishes.length && courses.length;

        if (!groupedOptions && isFiltersFilled)
            this.fillState();
    }

    fillState() {
        const { flavors, dishes, courses } = this.props;
        this.setState({
            groupedOptions: [
                { label: 'Flavor category', options: flavors, type: 'flavor' },
                { label: 'Menu category', options: courses, type: 'courses' },
                { label: 'Dish category', options: dishes,  type: 'dishes' },
            ]
        });
    }

    handleMultiSelectChange(type, options) {
        const { selections } = this.props;
        if (selections) {
            const cloned = _Clone(selections);
            cloned[type] = options;
            this.props.onFilterChange(type, cloned);
        }
    }

    handleGroupLabelClick(e) {
        const { dataset: { type } } = e.currentTarget;

        if (type) {
            const subs = e.currentTarget.parentNode.nextElementSibling;
            const parent = e.currentTarget;

            if (subs) {
                if (parent.getAttribute('class') === 'active') {
                    subs.style.display = 'none';
                    parent.setAttribute('class', '');
                } else {
                    subs.style.display = 'block';
                    parent.setAttribute('class', 'active');
                }
            }
        }
    }

    handleGroupMultiSelectChange(option) {
        const { groupedOptions } = this.state;
        const multipleTypes = {};

        if (!!option.length)
            for (let opt of option) {
                const filtered = groupedOptions.filter((ent) =>
                    !!ent.options.filter((o) => o.id === opt.id).length ? ent.type : null);
                const type = filtered && filtered.length && filtered[0].type;

                if (multipleTypes[type]) multipleTypes[type].push(opt);
                else multipleTypes[type] = [opt];

                if (type)
                    this.props.onFilterChange(type, multipleTypes);
            }
        else
            this.props.onFilterClear();
    }

    renderMultiValue(isGrouped) {
        const [multipleTypes, singleTypes] = [[], {}];
        const { selections } = this.props;
        const { groupedOptions } = this.state;
        const processEntity = (type, item) => {
            if (item) {
                if (isGrouped) {
                    multipleTypes.push(item);
                } else {
                    if (singleTypes[type] && singleTypes[type].length) {
                        singleTypes[type].push(item);
                    } else {
                        singleTypes[type] = [item];
                    }
                }
            }
        }

        if (selections && groupedOptions) {
            for (let ent of Object.keys(selections)) {
                const selection = selections[ent];
                if (selection) {
                    if (typeof selection === 'object') {
                        if (selection.length) {
                            selection.forEach(e => groupedOptions.forEach(group => {
                                const item = group.options.find(opt => (opt.id === e));
                                processEntity(ent, item);
                            }));
                        } else {
                            Object.keys(selection).forEach(k => groupedOptions.forEach(group => {
                                const item = group.options.find((opt) => (opt.id === k));
                                processEntity(ent, item);
                            }));
                        }
                    } else {
                        groupedOptions.forEach((group) => {
                            const item = group.options.find((opt) => opt.id === selection);
                            processEntity(ent, item);
                        });
                    }
                }
            }
        }

        return isGrouped ? multipleTypes : singleTypes;
    }

    render() {
        const { groupedOptions } = this.state;
        const { flavors, dishes, courses, isMobile } = this.props;

        const CustomOptions = props => {
            return (
                <>
                    <components.Menu {...props}>
                        {props.children}
                    </components.Menu>
                </>
            );
        };

        let desktopFilters = null;
        let mobileFilters = null;

        if (!isMobile) {
            const multiValue = this.renderMultiValue();
            const generateValues = type =>
                _Get(multiValue, type, []).map((v,i) => <span key={`${type}-value-${i}`}>{v.name}</span>);

            desktopFilters = <div>
                <div className="select-wraper">
                    <div className="title">Flavor category</div>
                    <Select
                        isMulti
                        isClearable
                        placeholder=""
                        options={flavors}
                        isSearchable={false}
                        className="select-big"
                        classNamePrefix="select"
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name}
                        value={_Get(multiValue, 'flavor')}
                        onChange={opt => this.handleMultiSelectChange('flavor', opt)}
                    />
                    <div className="values">{generateValues('flavor')}</div>
                </div>
                <div className="select-wraper">
                    <div className="title">Menu category</div>
                    <Select
                        isMulti
                        isClearable
                        placeholder=""
                        options={courses}
                        isSearchable={false}
                        className="select-big"
                        classNamePrefix="select"
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name}
                        value={_Get(multiValue, 'courses')}
                        onChange={opt => this.handleMultiSelectChange('courses', opt)}
                    />
                    <div className="values">{generateValues('courses')}</div>
                </div>
                <div className="select-wraper">
                    <div className="title">Dish category</div>
                    <Select
                        isMulti
                        isClearable
                        placeholder=""
                        options={dishes}
                        isSearchable={false}
                        className="select-big"
                        classNamePrefix="select"
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name}
                        value={_Get(multiValue, 'dishes')}
                        onChange={opt => this.handleMultiSelectChange('dishes', opt)}
                    />
                    <div className="values">{generateValues('dishes')}</div>
                </div>
            </div>;
        }

        if (isMobile) {
            const multiValue = this.renderMultiValue(true);
            const formatGroupLabel = data => (
                <div
                  data-type={data.type}
                  onClick={this.handleGroupLabelClick.bind(this)}>
                  {data.label}
                </div>
            );

            mobileFilters = <div className="select-wraper mobile">
                <div className="title">Find a recipe</div>
                <Select
                    isMulti
                    isClearable
                    placeholder=""
                    value={multiValue}
                    isSearchable={false}
                    className="select-big"
                    classNamePrefix="select"
                    getOptionValue={i => i.id}
                    hideSelectedOptions={false}
                    getOptionLabel={i => i.name}
                    options={groupedOptions || []}
                    components={{ CustomOptions }}
                    formatGroupLabel={formatGroupLabel}
                    onChange={opt => this.handleGroupMultiSelectChange(opt)}
                />
                <div className="values">{
                    multiValue.map((v,i) => <span key={`multi-value-${i}`}>{v.name}</span>)
                }</div>
            </div>;
        }

        return (
            <div className="filters">
                {desktopFilters}
                {mobileFilters}
            </div>
        );
    }
}

Filters.defaultProps = {
    dishes: [], flavors: [],
    courses: [],
}

const filterList = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}));

Filters.propTypes = {
    dishes: filterList,
    flavors: filterList,
    courses: filterList,
    onFilterClear: PropTypes.func,
    onFilterChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile
});

export default withRouter(connect(mapStateToProps)(Filters));
