class ImageClient {

    getOrigin() {
        return `https://${process.env.REACT_APP_IMAGE_PROCESS_HOST}`;
    }

    fitIn(path, options = {}) {
        if (path && Object.keys(options).length) {
            const origin = this.getOrigin();
            const { width, height } = options;
            const pathname = path.replace(/^https:\/\/[\w-.]*\.com\//g, '');

            return `${origin}/fit-in/${width}x${height}/${pathname}`;
        }

        return path;
    }
}

export default new ImageClient();
