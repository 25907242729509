import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './pizza.scss';

import pizza from '../../assets/img/pizza-board.png';
import set1 from '../../assets/img/pizza-set1.png';
import set2 from '../../assets/img/pizza-set2.png';

import pizzaInsightsPDF from '../../assets/pdf/Kens_Pizza_TechnomicSellSheet_v5.pdf';
import pizzaRecipesPDF from '../../assets/pdf/Kens_Pizza_Foods_RecipeSellSheet_v5.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f551711b', displayOrder: 1 }, // boom boom sauce
    { _id: '5bd97315a842a126f55170e2', displayOrder: 2 }, // white garlic pizza sauce
    { _id: '5bd97315a842a126f55170e0', displayOrder: 3 }, // signature balsamic fig glaze
    { _id: '5bd97315a842a126f551713b', displayOrder: 4 }  // home style ranch dressing
];
const recipes = [
    { _id: '5dddc83a6a636e0012924f5b', displayOrder: 1 }, // chicken alfredo flatbread
    { _id: '5bf87be0411fc3391faed4cb', displayOrder: 2 }, // boom boom breakfast pizza
    { _id: '5bd97312a842a126f5516ec2', displayOrder: 3 }, // steakhouse bleu cheese flatbread
    { _id: '5bd97312a842a126f5516f45', displayOrder: 4 }, // southwest breakfast flatbread
    { _id: '5bd97312a842a126f5516efc', displayOrder: 5 }, // pear, prosciutto and arugula flatbread
    { _id: '5bd97312a842a126f5516eb7', displayOrder: 6 }  // mediterranean vegetable pizza
];

class Pizza extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Pizza menu insights</h1>
                    <div className="descr short">Learn which pizza slices customers are craving, discover new recipes and find the sauces you need to give your pizza menu an upgrade.
                    </div>
                </div>

                <div className="hero-wrapper pizza"></div>

                <div className="body-wrapper pizza">
                    <div className="feature-wrapper">
                        <img src={pizza} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">146%</div>
                                <div className="descr">growth of vegan options on pizza menus in the last four years</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">49%</div>
                                <div className="descr">of shoppers want more authentic pizza offerings</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">44%</div>
                                <div className="descr">of shoppers choose pizza operators who use high-quality/fresh toppings and ingredients</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper pizza">
                    <h2>Unique toppings and bold flavors are<br/> starting to emerge as customer favorites</h2>
                </div>

                <div className="body-wrapper pizza">
                    <div className="benefits-wrapper">
                        <div className="descr"><span>35%</span>of consumers would order steak on pizza</div>
                        <div className="descr"><span>34%</span>of consumers would order cheesesteak or philly pizza</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Steakhouse Pizza with Ken’s Chunky Bleu Cheese Dressing</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Boom Boom breakfast pizza with ken’s boom boom sauce</span>
                        </div>
                        <div className="descr"><span>38%</span>consumers 18-34 would order breakfast pizza if available</div>
                        <div className="descr"><span>12.7%</span>growth of eggs as an ingredient on pizza versus last year</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={pizzaInsightsPDF} data-gtm-download-more-insights-pizza-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={pizzaRecipesPDF} data-gtm-see-all-insights-pizza-button-link-a download>download pizza brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5ddc54ee02debe0012826cc5" }}} className="button" data-gtm-see-all-insights-pizza-button-link-a>see all pizza recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts
                            className="pizza"
                            settings={products}
                            title="Pizza sauces we know you'll love" />
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="pizza" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Pizza);
