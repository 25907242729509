import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';

const MetaTags = ({
    keywords = '',
    noIndexNoFollow = false,
    title = "Ken's Foodservice | Dressings &amp; Sauces | Official Site",
    description = "At Ken&#39;s, we have over 200 dressings and sauces which can provide you with unlimited versatility and inspiration for your foodservice kitchen. Learn more!",
    canonical = ''
} = {}) => (
    <Helmet>
        <title itemprop="name">{title}</title>
        <meta property="og:title" name="twitter:title" content={title} />
        <meta property="og:type" content="website" />
        <meta name="description" content={description} />
        <meta itemprop="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}
        {noIndexNoFollow && <meta name="robots" content="noindex, nofollow" />}
        {!isEmpty(keywords) && <meta name="keywords" content={keywords} />}
    </Helmet>
)

export default memo(MetaTags);
