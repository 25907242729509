import _sortBy from 'lodash/sortBy';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class SourceDataRecipes extends PureComponent {

    render() {
        const response = [];
        const { searchResults, settings } = this.props;
        const entity = searchResults.find((sr) => sr['recipes']);
        const recipesEntity = entity && entity['recipes'];

        if (recipesEntity) {
            for (const item of recipesEntity) {
                const settingEnt = settings.find(({ _id }) => _id === item.id);

                if (settingEnt) {
                    response.push({
                        displayOrder: settingEnt.displayOrder,
                        content: (
                            <Link to={`/recipes/${item.slug}`} className="block recipe" key={item.id}>
                                <div className="block-img">
                                    <img src={item.details.thumbnail} alt={item.details.title} />
                                </div>
                                <div className="block-txt">
                                    <div className="block-title"><p>{item.label}</p></div>
                                </div>
                            </Link>
                        )
                    });
                }
            }
        }

        return _sortBy(response, 'displayOrder')
            .map(resp => resp.content);
    }
}

const mapStateToProps = state => ({
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps)(SourceDataRecipes);
