export const actionTypes = {
    PLUS_ONE_SELECTED: 'PLUS_ONE_SELECTED',

    FETCH_PLUS_ONES_START: 'FETCH_PLUS_ONES_START',
    FETCH_PLUS_ONES_SUCCESS: 'FETCH_PLUS_ONES_SUCCESS',
    FETCH_PLUS_ONES_FAILED: 'FETCH_PLUS_ONES_FAILED',

    FETCH_PLUS_ONES_PRODUCTS_START: 'FETCH_PLUS_ONES_PRODUCTS_START',
    FETCH_PLUS_ONES_PRODUCTS_SUCCESS: 'FETCH_PLUS_ONES_PRODUCTS_SUCCESS',
    FETCH_PLUS_ONES_PRODUCTS_FAILED: 'FETCH_PLUS_ONES_PRODUCTS_FAILED',

    UPDATE_PLUS_ONE_FILTERS: 'UPDATE_PLUS_ONE_FILTERS',
};
