import { useReducer, useCallback } from 'react';

const reducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return { opened: true, context: action.payload };
        case 'close':
            return { opened: false, context: null };
        default:
            return state;
    }
};

export const useDialog = () => {
    const [state, dispatch] = useReducer(reducer, {
        opened: false,
        context: null,
    });
    const open = useCallback((context) => {
        dispatch({
            type: 'open',
            payload: typeof context === 'object' && context.nativeEvent ? null : context,
        });
    }, []);
    const close = useCallback(() => dispatch({ type: 'close' }), []);
    return { ...state, open, close, toggle: state.opened ? close : open };
};
