import { actionTypes as types } from './constants';

export const fetchPlusOnes = (filters, paging, resetResults) => ({
    type: types.FETCH_PLUS_ONES_START,
    filters,
    paging,
    resetResults
});

export const fetchProductsFilter = () => ({
    type: types.FETCH_PLUS_ONES_PRODUCTS_START,
});

export const updateFilters = (filters) => ({
    type: types.UPDATE_PLUS_ONE_FILTERS,
    payload: filters
});

export const setSelectedPlusOne = (plusOne) => ({
    type: types.PLUS_ONE_SELECTED,
    plusOne
});
