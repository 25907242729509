import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _IndexOf from 'lodash/indexOf';
import Select from 'react-select';

import FeedbackWrapper from '../../components/FeedbackWrapper';
import { fetchAllProducts } from '../products/actions';
import { submitFeedback, completeFeedback } from './actions';
import { triggerGAEvent } from '../../helpers/googleTagManager';

import './portion-control.scss';

import cup1 from '../../assets/img/products/ke0777a5_kens_ranch_cup_thumbnail_1.png';
import cup2 from '../../assets/img/products/ke0827a5_caesar_cup_thumbnail.png';
import cup3 from '../../assets/img/products/ke0851a5_cocktail_sauce_cup_thumbnail.png';
import cup4 from '../../assets/img/products/ke0617a5_italian_herb_vinaigrette_cup_thumbnail.png';
import cup5 from '../../assets/img/products/ke2092a5_kens_balsamic_vinaigrette_cup_thumbnail.png';
import cup6 from '../../assets/img/products/ke0634a5_kens_honey_mustard_cup_thumbnail.png';
import cup7 from '../../assets/img/products/ke2509a5_jalapeno_ranch_cup_thumbnaill.png';
import cup8 from '../../assets/img/products/ke0665a5_kens_bleu_cheese_cup_thumbnail.png';
import cup9 from '../../assets/img/products/ke0666a5_tartar_sauce_cup_thumbnail.png';
import cup10 from '../../assets/img/products/ke0708a5_literanch_cup_thumbnail.png';
import pouch1 from '../../assets/img/products/ke0033b3.png';
import pouch2 from '../../assets/img/products/ke0572b3png_1540985062224.png';
import pouch3 from '../../assets/img/products/ke0636b3_lite_balsamic_with_olive_oil_vinaigrette_pouch_thumbnail.png';
import pouch4 from '../../assets/img/products/ke0788b3.png';
import pouch5 from '../../assets/img/products/ke0041b3.png';
import pouch6 from '../../assets/img/products/ke1057b3.png';
import pouch7 from '../../assets/img/products/ke0827b3_caesar_dressing_pouch_thumbnail.png';
import pouch8 from '../../assets/img/products/ke0813b3_blue_cheese_dressing_pouch_thumbnail.png';
import pouch9 from '../../assets/img/products/ke0816b3_thousand_island_dressing_pouch_thumbnail.png';
import pouch10 from '../../assets/img/products/ke0858b3_golden_italian_dressing_pouch_thumbnail.png';
import recipe1 from '../../assets/img/portion-recipe1.jpg';
import recipe2 from '../../assets/img/portion-recipe2.jpg';
import recipe3 from '../../assets/img/portion-recipe3.jpg';
import fact1 from '../../assets/img/portion-fact1.png';
import fact2 from '../../assets/img/portion-fact2.png';

const Modal = lazy(() => import('../../components/modal/Modal'));
const ReCaptcha = lazy(() => import('../../components/re-captcha/ReCaptcha'));

const locations = [
    { value: 'one', name: '1' },
    { value: 'twoTen', name: '2-10' },
    { value: '11more', name: '11+' },
]

const cups = [
    {img: cup1, name: "ranch", link: "/products/ke0777a5-ranch-dressing"},
    {img: cup2, name: "caesar", link: "/products/ke0827a5-caesar-dressing"},
    {img: cup3, name: "cocktail sauce", link: "/products/ke0851a5-cocktail-sauce"},
    {img: cup4, name: "italian herb vinaigrette", link: "/products/ke0617a5-italian-herb-vinaigrette-dressing"},
    {img: cup5, name: "balsamic vinaigrette", link: "/products/ke2092a5-balsamic-vinaigrette-dressing"},
    {img: cup6, name: "honey mustard", link: "/products/ke0634a5-honey-mustard-dressing"},
    {img: cup7, name: "jalapeño ranch", link: "/products/ke2509a5-jalapeño-ranch-dressing"},
    {img: cup8, name: "bleu cheese", link: "/products/ke0665a5-bleu-cheese-dressing"},
    {img: cup9, name: "tartar sauce", link: "/products/ke0666a5-tartar-sauce"},
    {img: cup10, name: "lite ranch", link: "/products/ke0708a5-lite-ranch-dressing"}
];

const pouches = [
    {img: pouch1, name: "country french", link: "/products/ke0033b3-country-french-with-orange-blossom-honey-dressing"},
    {img: pouch2, name: "honey mustard", link: "/products/ke0572b3-honey-mustard-dressing"},
    {img: pouch3, name: "balsamic with olive oil", link: "/products/ke0636b3-light-balsamic-with-olive-oil-vinaigrette"},
    {img: pouch4, name: "greek", link: "/products/ke0788b3-greek-dressing"},
    {img: pouch5, name: "ranch", link: "/products/ke0789b3-ranch-dressing"},
    {img: pouch6, name: "balsamic vinaigrette", link: "/products/ke1057b3-balsamic-vinaigrette-dressing"},
    {img: pouch7, name: "caesar", link: "/products/ke0827b3-caesar-dressing"},
    {img: pouch8, name: "blue cheese", link: "/products/ke0813b3-blue-cheese-dressing"},
    {img: pouch9, name: "thousand island", link: "/products/ke0816b3-thousand-island-dressing"},
    {img: pouch10, name: "golden italian", link: "/products/ke0858b3-golden-italian-dressing"}
];

class PortionControl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: null,
            isProductProcessed: false,
            isGaEventTriggered: false,
            zip: '',
            city: '',
            state: '',
            fname: '',
            lname: '',
            email: '',
            phone: '',
            company: '',
            address: '',
            website: '',
            locations: '',
            captchaId: '',
            captchaValidation: '',
        }

        this.handleInput = this.handleInput.bind(this)
        this.onCaptchaChange = this.onCaptchaChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.recaptchaRef = React.createRef()
    }

    componentDidMount() {
        const { allProducts } = this.props
        window.scrollTo(0, 0)

        if (!allProducts.length) this.props.fetchAllProducts()

        this.maybeUpdateProductSamples()

        this.maybeFireGAEvent()
    }

    componentDidUpdate() {
        const { errors } = this.props
        const { errors: stateErrors } = this.state

        if (!stateErrors && errors && !!errors.length) this.setState({ errors })

        this.maybeUpdateProductSamples()

        this.maybeFireGAEvent()
    }

    maybeFireGAEvent() {
        const {
            location: { state },
        } = this.props
        const { isGaEventTriggered } = this.state
        const shouldTrigger = state && state.gaFrom && !isGaEventTriggered

        if (shouldTrigger) {
            triggerGAEvent({
                label: state.gaFrom,
                action: 'Button Click',
                category: 'Sample Requests',
            })
            this.setState({ isGaEventTriggered: true })
        }
    }

    maybeUpdateProductSamples() {
        const {
            location: { state },
            allProducts,
        } = this.props
        const { isProductProcessed } = this.state
        const shouldUpdateSamples =
            state &&
            (state.code || state.group) &&
            !isProductProcessed &&
            allProducts.length

        if (shouldUpdateSamples) {
            let filteredProduct = allProducts.filter(p => p.code === state.code)

            if (!filteredProduct.length)
                filteredProduct = allProducts.filter(
                    p => p.websiteGroup === state.group
                )

            this.setState({
                isProductProcessed: true,
                sample: filteredProduct,
            })
        }
    }

    handleSubmit(e) {
        const {
            zip,
            city,
            state,
            fname,
            lname,
            email,
            phone,
            company,
            address,
            captchaId,
            website,
            locations,
        } = this.state
        const {
            location: { state: propsState },
        } = this.props
        const shouldTrigger = propsState && propsState.gaLabel

        let dropdowns = [
            'locations',
        ]

        e.preventDefault()

        if (!captchaId) {
            return this.setState({
                captchaValidation: 'Please solve the captcha above.',
            })
        }

        let errors = []
        let validate = []

        dropdowns.forEach(ent => {
            !this.state[ent] &&
                errors.push({ param: ent, value: !this.state[ent] })
            validate.push(!!this.state[ent])
        })

        if (_IndexOf(validate, false) >= 0) {
            this.setState({ errors })
        } else {
            let feedback = {
                fname,
                lname,
                email,
                phone,
                company,
                address,
                zip,
                city,
                state,
                captchaId,
                website,
                locations: locations.name,
            }

            this.props.submitFeedback(feedback)
        }

        if (shouldTrigger) {
            triggerGAEvent({
                label: propsState.gaLabel,
                action: 'Button Click',
                category: 'Sample Requests',
            })
        }
    }

    handleInput(e) {
        const updates = {}
        updates[e.target.name] = e.target.value

        this.setState(updates)
    }

    handleSelectChange(type, opt) {
        const updates = {}
        const { errors } = this.state
        const filtered = errors && errors.filter(err => err['param'] === type)

        updates[type] = opt

        if (filtered && !!filtered.length)
            updates['errors'] = errors.filter(err => err['param'] !== type)

        this.setState(updates)
    }

    handleClose() {
        this.resetForm()
        this.props.completeFeedback()
        this.props.history.push('/')
    }

    resetForm() {
        this.setState(
            {
                zip: '',
                city: '',
                state: '',
                fname: '',
                lname: '',
                email: '',
                phone: '',
                company: '',
                address: '',
                website: '',
                locations: '',
                captchaId: '',
                captchaValidation: '',
            },
            () => {
                this.recaptchaRef.current.reset()
            }
        )
    }

    onCaptchaChange(value) {
        this.setState({
            captchaId: value,
            captchaValidation: '',
        })
    }

    getRequestForm() {
        const { errors } = this.state
        const { sending } = this.props
        const errorMsg = <p className="error">Please, select an option</p>
        const isError = name => {
            const filtered =
                errors && errors.filter(err => err['param'] === name)
            return filtered && !!filtered.length
        }

        return (
            <form className="request-form" onSubmit={this.handleSubmit}>
                <div className="two-columns">
                    <div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="fname"
                                placeholder="First name*"
                                required
                                onChange={this.handleInput}
                                value={this.state.fname}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                required
                                onChange={this.handleInput}
                                value={this.state.email}
                                placeholder="Email*"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="address"
                                placeholder="Address*"
                                required
                                onChange={this.handleInput}
                                value={this.state.address}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="city"
                                placeholder="City*"
                                required
                                onChange={this.handleInput}
                                value={this.state.city}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="zip"
                                placeholder="Zip / Postal Code*"
                                required
                                onChange={this.handleInput}
                                value={this.state.zip}
                            />
                        </div>
                        <div
                            className={`form-group ${isError(
                                'locations'
                            ) && 'is-invalid'}`}
                        >
                            <Select
                                required
                                isClearable
                                name="locations"
                                className="select"
                                classNamePrefix="select"
                                placeholder="Number of locations*"
                                options={locations}
                                getOptionLabel={i => i.name}
                                getOptionValue={i => i.value}
                                onChange={opt =>
                                    this.handleSelectChange(
                                        'locations',
                                        opt
                                    )
                                }
                                value={this.state.locations}
                            />
                            {errorMsg}
                        </div>
                    </div>

                    <div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="lname"
                                placeholder="Last name*"
                                required
                                onChange={this.handleInput}
                                value={this.state.lname}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone number*"
                                required
                                onChange={this.handleInput}
                                value={this.state.phone}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="company"
                                placeholder="Company name*"
                                required
                                onChange={this.handleInput}
                                value={this.state.company}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="state"
                                placeholder="State / Province / Region*"
                                required
                                onChange={this.handleInput}
                                value={this.state.state}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="website"
                                placeholder="Company website*"
                                required
                                onChange={this.handleInput}
                                value={this.state.website}
                            />
                        </div>
                        <Suspense fallback={null}>
                            <ReCaptcha
                                recaptchaRef={this.recaptchaRef}
                                onChange={this.onCaptchaChange}
                            />
                        </Suspense>
                        {!!this.state.captchaValidation && (
                            <p style={{ color: '#900' }}>
                                {this.state.captchaValidation}
                            </p>
                        )}

                        <div className="submit">
                            {sending && (
                                <button disabled>Sending, please wait...</button>
                            )}
                            {!sending && (
                                <button className="inverse" type="submit">Request a sample</button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    getCups() {
        return (
            cups.map((item, idx) => {
                return (
                    <div key={idx} className="flavor-item">
                        <Link to={item.link}>
                            <img src={item.img} alt="" />
                            <span>{item.name}</span>
                        </Link>
                    </div>
                )
            })
        );
    }

    getPouches() {
        return (
            pouches.map((item, idx) => {
                return (
                    <div key={idx} className="flavor-item">
                        <Link to={item.link}>
                            <img src={item.img} alt="" />
                            <span>{item.name}</span>
                        </Link>
                    </div>
                )
            })
        );
    }

    render() {
        return (
            <div>
                <div className="body-wrapper column">
                    <h1>Convenience Tastes So Good</h1>
                    <div className="descr short">Ken&rsquo;s has the flavors you know and love — and even some you haven&rsquo;t discovered yet. And they&rsquo;re portioned in portable dipping cups and pouches, which means your customers will always have exceptional flavor in the palm of their hand. Restaurant-quality, on-the-go convenience.</div>
                    <Link to={{ pathname: '/get-sample' }} className="button">request a sample</Link>
                </div>

                <div className="hero-wrapper portion-control">
                </div>

                <div className="body-wrapper column portion-control">
                    <h2>Get a Taste</h2>
                    <div className="descr">Explore Ken&rsquo;s perfectly portioned flavors.</div>
                    <div className="products-list">
                        {this.getCups()}
                    </div>
                    <div className="products-list">
                        {this.getPouches()}
                    </div>
                </div>

                <div className="body-wrapper column pampas">
                    <div className="taste-wrapper">
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={recipe1} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={recipe2} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={recipe3} alt="" />
                            </div>
                        </div>
                        <Link to={{ pathname: '/recipes' }} className="button">view recipes</Link>
                    </div>
                    
                </div>

                <div className="body-wrapper column portion-control">
                    <div className="facts-wrapper">
                        <div className="fact-block">
                            <div className="fact-content">
                                <span>70%</span>
                                <p>of millennials like to dip their pizza (and more) in sauces.</p>
                            </div>
                            <img src={fact1} alt="" />
                        </div>
                        <div className="fact-block">
                            <div className="fact-content">
                                <span>63%</span>
                                <p>of consumers say dips and condiments are important for adding flavor to foods.</p>
                            </div>
                            <img src={fact2} alt="" />
                        </div>
                    </div>
                    
                </div>

                <div className="body-wrapper column pampas portion-control">
                    <h2>Request a sample</h2>
                    {this.getRequestForm()}
                    <Suspense fallback={null}>
                        <Modal
                            visible={this.props.showSuccessModal}
                            onClose={() => this.handleClose()}
                        >
                            <h2>Thank you for considering Ken's!</h2>
                            <p className="descr">
                                A Ken's representative will contact you soon about
                                your request.
                            </p>
                        </Modal>
                    </Suspense>
                    <Suspense fallback={null}>
                        <Modal
                            visible={this.props.somethingWentWrong}
                            onClose={() => this.props.completeFeedback()}
                        >
                            <h2>Oops! Something went wrong.</h2>
                        </Modal>
                    </Suspense>
                </div>

                <FeedbackWrapper page="portion-control" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    errors: state.sample.errors,
    sending: state.sample.sending,
    allProducts: state.products.all,
    showSuccessModal: state.sample.showSuccessModal,
    somethingWentWrong: state.sample.somethingWentWrong,
})

export default connect(
    mapStateToProps,
    { fetchAllProducts, submitFeedback, completeFeedback }
)(PortionControl)