import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _Omit from 'lodash/omit';

import { search, clearSearch } from '../home/actions';

import './products.scss';

import packageCups from '../../assets/img/packages/cups.png';
import packageTubs from '../../assets/img/packages/tubs.png';
import packagePouches from '../../assets/img/packages/pouches.png';
import packageGallons from '../../assets/img/packages/gallons.png';
import packageDispenser from '../../assets/img/packages/dispenser.png';
import packageBarBottles from '../../assets/img/packages/bar-bottles.png';

import trend1 from '../../assets/img/products-essentials.png';
import trend2 from '../../assets/img/products-metropolitan.png';
import trend3 from '../../assets/img/products-signature.png';
import trend4 from '../../assets/img/products-mayonnaise.png';

const Slider = lazy(() => import('react-slick'));
const Search = lazy(() => import('../../components/search/Search'));

const blocks = [
    {front: "We have a ton of products", back: "202 unique dressings, sauces and marinades to improve any menu.", link: "/products", linkname: "see all"},
    {front: "In every size and shape package", back: "Gallons, cups, pouches, bar bottles, dispenser packs, pails, tubs and more.", link: "/products", linkname: "see all"},
    {front: "On-trend, in-demand flavors", back: "So customers keep coming back for more.", link: "/plus-one", linkname: "learn more"},
    {front: "An R&D team that can't be beat", back: "Triple the size of our nearest competitor.", link: "/about-us", linkname: "learn more"},
    {front: "#1 Family-owned dressing and sauce company", back: "Because we’re customer-focused and flavor-driven.", link: "/about-us", linkname: "learn more"}
];


class ProductsPage extends PureComponent {

    componentDidMount() {
        const { searchResults } = this.props;

        window.scrollTo(0, 0);

        if (Object.keys(searchResults).length)
            this.props.clearSearch();
    }

    handleSearchChange(searchTerm) {
        searchTerm && this.props.search(searchTerm);
    }

    getBlocks() {
        return (
            blocks.map((item, idx) => {
                return (
                    <div className="block flip short" key={idx}>
                        <div className="flip-inner">
                            <div className="front">
                                <div className="block-txt">
                                    <div className="block-title">{item.front}</div>
                                </div>
                            </div>
                            <div className="back">
                              <span>{item.back}</span>
                              <Link to={item.link} className="button">{item.linkname}</Link>
                            </div>
                        </div>
                    </div>
                )
            })
        );
    }

    getFlavors() {
        const { flavors } = this.props;

        return (
            flavors.map((item, idx) => {
                return (
                    <div className="flavor-item" key={`flavor-${idx}`}>
                        <span>{item.name}</span>
                        {
                          // Replace with flavor.image when [KR-50] will be resolved
                        }
                        <img src={item.image} alt="" />
                        {
                          // Replace with flavor.image when [KR-50] will be resolved
                        }
                        <div className="block-info">
                            <Link to={{ pathname: '/products', state: { flavor: item.id }}} className="button">see all</Link>
                        </div>
                    </div>
                )
            })
        );
    }

    getPackages() {
        const packages = {
            'gallons': { link: '/products', package: '5b6c4eaeb7400b01f9a16e00', img: packageGallons },
            'cups': { link: '/cups', img: packageCups },
            'pouches': { link: '/pouches', img: packagePouches },
            'bar bottles': { link: '/bar-bottles', img: packageBarBottles },
            'pails & tubs': { link: '/products', package: '5b6c4ea8b7400b01f9a16b69', img: packageTubs },
            'dispenser': { img: packageDispenser },
        };

        const images = Object.keys(_Omit(packages, 'pails')).map((key, idx) => {
            const item = packages[key];
            return item.link ? (
                <Link to={{ pathname: item.link, state: item.package ? { package: item.package } : {}}} key={`package-img-${idx}`}>
                    <img src={item.img} alt={`package-img-${idx}`} data-type={key} />
                </Link>
            ) : <img src={item.img} key={`package-img-${idx}`} alt={`package-img-${idx}`} />;
        });

        const links = Object.keys(_Omit(packages, 'dispenser')).map((key, idx) => {
            const item = packages[key];
            return (
                <Link to={{ pathname: item.link, state: item.package ? { package: item.package } : {}}} key={`package-link-${idx}`}>
                    {key}
                </Link>
            );
        });

        return (
            <>
                <div className="packages">{images}</div>
                <h2>Good outside-the-box-bottle-cup-tub-jug-and-pouch thinking.</h2>
                <div className="descr">Every client has different needs. So we created the most comprehensive line of packaging in foodservice.<br /> What do you need? Ken’s has it. In {links.reduce((p, c) => [p, ', ', c])} and more.</div>
            </>
        );
    }

    render() {
      const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
      };

        return (
            <div>
                <div className="hero-wrapper products">
                    <h1>Welcome to Flavor Central</h1>
                    <div className="descr">Everything you need to know about Ken’s products.</div>
                    <Suspense fallback={<p className="loading">Loading search...</p>}>
                        <Search
                            byType="products"
                            className="search-wrapper"
                            results={this.props.searchResults}
                            loading={this.props.loadingResults}
                            placeholder={'What can we get you today?'}
                            onChange={(e) => { this.handleSearchChange(e.target.value) }}
                        />
                    </Suspense>
                    <Link to={{ pathname: '/products' }} className="button">
                        See all products
                    </Link>
                    <Link to="/recipes/Boston-cluckn-sandwich" className="recipe-link">Boston Cluck’n Sandwich</Link>
                </div>

                <div className="body-wrapper">
                    {this.getBlocks()}
                </div>

                <div className="body-wrapper products flavor-section pampas">
                    <div className="flavor-list">
                        {this.getFlavors()}
                    </div>
                    <h2>The First Name in Flavor</h2>
                    <h3>The most flavor, and the most flavors</h3>
                    <div className="descr short">
                        Offering unparalleled breadth and depth of dressings and sauces. No one even comes close. 11 kinds of Caesar, 14 kinds of Ranch, 19 kinds of Italian and on and on. Each one packed with flavor, on trend and ready to go.
                    </div>
                </div>

                <div className="body-wrapper products secondary">
                    {this.getPackages()}
                </div>

                <div className="body-wrapper pampas">
                    <h2>On-trend product lines</h2>
                    <Suspense fallback={<p className="loading">Loading...</p>}>
                        <Slider {...settings}>
                            <div>
                              <img src={trend1} alt="" />
                              <h3>Ken’s Essentials</h3>
                              <div className="descr"><Link to="/essentials">Ken’s Essentials</Link> are clean label dressings with simple ingredients - exactly what customers want today.</div>
                            </div>
                            <div>
                              <img src={trend2} alt="" />
                              <h3>Ken’s Metropolitan Select</h3>
                              <div className="descr"><Link to="/metropolitan">Metropolitan Select</Link> dressings and sauces take your menu to the next level.</div>
                            </div>
                            <div>
                              <img src={trend3} alt="" />
                              <h3>Ken’s Signature Sauces</h3>
                              <div className="descr"><Link to="/signature">Signature Sauces</Link> are perfect for dipping, spreading, glazing and grilling.</div>
                            </div>
                            <div>
                              <img src={trend4} alt="" />
                              <h3>Cage-Free Mayonnaise</h3>
                              <div className="descr"><Link to={{ pathname: '/products', state: { flavor: '5bd97314a842a126f551703c'}}}>Ken’s Cage-Free Mayonnaise</Link> is a premium flavorful mayo that meets customers’ ethical preferences today.</div>
                            </div>
                        </Slider>
                    </Suspense>
                </div>

                <div className="body-wrapper column">
                    <Link to="/products" className="button">see all products</Link>
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button">contact us</Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    flavors: state.lookup.flavors,
    searchResults: state.home.searchResults,
    loadingResults: state.home.loadingResults
});

export default connect(mapStateToProps, { search, clearSearch })(ProductsPage);
