import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { getSiteKey } from './actions';

class ReCaptcha extends PureComponent {

    componentDidMount() {
        const { sitekey } = this.props;
        if (!sitekey) this.props.getSiteKey();
    }

    render() {
        const { recaptchaRef, sitekey, onChange } = this.props;
        return sitekey ? (
            <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} onChange={onChange} />
        ) : null;
    }
}

const mapStateToProps = state => ({
    sitekey: state.captcha.sitekey,
});

export default connect(mapStateToProps, { getSiteKey })(ReCaptcha);
