import { actionTypes as types } from './constants';

export const submitFeedback = (payload) => ({
    type: types.SUBMIT_FEEDBACK_START,
    payload
});

export const completeFeedback = () => ({
    type: types.SUBMIT_FEEDBACK_COMPLETED
})
