import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSearchResults } from '../search-result/actions';

import './dill-pickle.scss';

import dillPickleSellSheet from '../../assets/pdf/kens_dillpickleaioli_pos.pdf';
import img1 from '../../assets/img/offers/dill-pickle/dillpickle-img1.jpg';
import img2 from '../../assets/img/offers/dill-pickle/dillpickle-img2.jpg';
import img3 from '../../assets/img/offers/dill-pickle/dillpickle-img3.jpg';
import lemon from '../../assets/img/offers/dill-pickle/lemon.jpg';
import parsley from '../../assets/img/offers/dill-pickle/parsley.jpg';
import dill from '../../assets/img/offers/dill-pickle/dill.jpg';

// import greenGoddessSellSheet from '../../assets/pdf/kens_green_goddess_pos.pdf';

const SourceDataProductDetails = lazy(() => import('./components/SourceDataProductDetails'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));

const productId = [ '6392bfba886b8c00127b8af9' ]; // Ken's Signature Dill Pickle Aioli (KE3607-2) - Production
// const productId = [ '6392bfb82436b90011a30937' ]; // Ken's Signature Dill Pickle Aioli (KE3607-2) - Staging


const recipes = [
    { _id: '6360c4638f62710012166a1b', displayOrder: 1 }, // Grilled Swordfish Kebabs with Dill Pickle Aioli
    { _id: '6351fb7afe440500118960d2', displayOrder: 2 }, // Pickle Bacon Cheese Burger
    { _id: '63601e18fe44050011897c61', displayOrder: 3 }, // Zucchini Fritters with Dill Pickle Aioli
    { _id: '63617d83fe44050011898021', displayOrder: 4 }, // Bacon Cheeseburger Flatbread with Dill Pickle Aioli
    { _id: '6351f9188f62710012164d43', displayOrder: 5 }, // Fried Chicken & Pickle Wrap
    { _id: '63618682fe4405001189805e', displayOrder: 6 } // Cracked Peppercorn Crusted Chicken Sandwich With Dill Pickle Aioli
];

class DillPickle extends PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            keys: [ 'products', 'recipes' ],
            ids: [...recipes.map(r => r._id), ...productId ]
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    filterResults(key) {
        const { searchResults } = this.props;
        const entity = searchResults.find((sr) => sr[key]);
        return entity && (key === 'products' ? entity[key][0] : entity[key]);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);
        const product = isDataLoaded && this.filterResults('products');

        return (
            <>
                <div className="hero-wrapper dill-pickle">
                    <h1>Flavor Like This Is A Big Dill</h1>
                </div>

                <div className="body-wrapper dill-pickle">
                    <div className="product-intro">
                        {product && (
                            <img src={product.details.websiteThumbnail} alt={product.details.name} />
                        )}
                        <div>
                            <h2>Ken&rsquo;s Signature Dill Pickle Aioli</h2>
                            <h4>NO Artificial Flavors | NO High-Fructose Corn Syrup | NO Synthetic Colors | NO Added MSG</h4>
                            <p>Ken&rsquo;s Signature Dill Pickle Aioli is a unique and delicious sauce that combines the tangy taste of chopped dill pickles, pickle juice and dill weed with the creamy texture of aioli. This versatile sauce will elevate your sandwiches, burgers and fries! This, creating a one-of-a-kind flavor you won&rsquo;t be able to resist.</p>
                            <div className="button-group">
                                <a className="button" href={dillPickleSellSheet}>download sell sheet</a>
                                {product && (
                                    <>
                                        <Link to={`/products/${product.slug}`} className="button">see more product info</Link>
                                        <a className="button" href={product.details.files.pdf}>view specs</a>
                                    </>
                                )}
                                <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Dill Pickle Landing page' }}}>request a sample</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper dill-pickle">
                    <h2>Ken&rsquo;s Dill Pickle Is Making Moves</h2>
                    <div className="descr">Ken&rsquo;s Signature Dill Pickle Aioli is in good company as other flavored aiolis are starting to pick up menu penetration.<br/> Those flavors with a kick are paving the way for Ken&rsquo;s Dill Pickle to follow.</div>
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={img1} alt="" />
                                <span>80%</span>
                            </div>
                            <div className="descr">love or like Ken&rsquo;s Dill Pickle among those who have tried it.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={img2} alt="" />
                                <span>9%</span>
                            </div>
                            <div className="descr">projected growth of Ken&rsquo;s Dill Pickle on menu penetration is expected over the next four years.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={img3} alt="" />
                                <span>67%</span>
                            </div>
                            <div className="descr">love or like aioli among those who have tried it.</div>
                        </div>
                    </div>
                    {/* <div className="remark">Sources: Technomic Consumer Report 2019</div> */}
                </div>

                <div className="body-wrapper pampas dill-pickle">
                    <h2>Ken&rsquo;s Signature Dill Pickle Aioli Recipes</h2>
                    <div className="descr">Show your customers just how far Ken&rsquo;s Signature Dill Pickle Aioli can go with these dishes.</div>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                </div>

                <div className="body-wrapper column dill-pickle">
                    <h2>Product Information</h2>
                    {isDataLoaded ? <Suspense fallback={null}>
                        <SourceDataProductDetails settings={{
                            size: '2/1 Gallon',
                            weight: '17.47 lbs',
                            storage: 'Shelf Stable'
                        }} />
                        </Suspense> : <p>Loading...</p>}
                </div>

                <div className="body-wrapper column dill-pickle">
                    <h2>Make it your own</h2>
                    <div className="descr short">Flavors are meant to be explored. Try one of these unique combinations, or discover your own and help your menu stand out from the crowd.</div>
                    <div className="make-it-wrapper">
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={lemon} alt="" /></div>
                            <p><strong>Simple Dill Tartar Sauce</strong></p>
                            <p>8 fl oz Ken&rsquo;s Signature Dill Pickle Aioli (KE3607-2)</p>
                            <p>1 fl oz capers</p>
                            <p>1 fl oz lemon juice</p>
                            <p>1 tsp Worcestershire sauce</p>
                            <p>1/2 tsp Dijon mustard</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={parsley} alt="" /></div>
                            <p><strong>Herbed Dill Pickle Aioli</strong></p>
                            <p>16 fl oz Ken&rsquo;s Signature Dill Pickle Aioli (KE3607-2)</p>
                            <p>2 tbsp Italian parsley</p>
                            <p>2 tbsp chives</p>
                            <p>2 tbsp tarragon</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={dill} alt="" /></div>
                            <p><strong>Dill Pickle Ranch</strong></p>
                            <p>8 fl oz Ken&rsquo;s Signature Dill Pickle Aioli (KE3607-2)</p>
                            <p>8 fl oz Ken&rsquo;s Home Style Ranch Dressing (KE0777)</p>
                            <p>1 tbsp dill relish</p>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper secondary dill-pickle">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button" data-gtm-green-goddess-contact-us-link>contact us</Link>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(DillPickle);
