import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import find from 'lodash/find'
import Select from 'react-select'
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import classNames from 'classnames'

import MetaTags from '../../components/meta'
import Modal from '../../components/modal/Modal'
import { startCase, prepareMarketingClaims } from '../../helpers/normalizer'
import Checkbox from '../../components/checkbox/Checkbox'
import { triggerGAEvent } from '../../helpers/googleTagManager'

import './products.scss'

import {
    sendEmailWithFiles,
    resetRelatedRecipes,
    fetchRelatedRecipes,
    clearEmailWithFilesData,
} from '../recipes/actions'

import {
    resetDetails,
    setSelectedProduct,
    fetchProductDetails,
} from './actions'

import noArtifFlav from '../../assets/img/badges/badge-no-artif-flavors.svg'
import noArtifPreserv from '../../assets/img/badges/badge-no-artif-preservs.svg'
import noSynthColors from '../../assets/img/badges/badge-no-synt-colors.svg'
import noHfcs from '../../assets/img/badges/badge-no-hfcs.svg'
import noMsg from '../../assets/img/badges/badge-no-added-msg.svg'
import fatFree from '../../assets/img/badges/badge-fat-free.svg'
import lite from '../../assets/img/badges/badge-lite.svg'
import noEdta from '../../assets/img/badges/badge-no-edta.svg'
import eggsImg from '../../assets/img/allergens/eggs.png'
import sesameImg from '../../assets/img/allergens/sesame.png'
import fishImg from '../../assets/img/allergens/fish.png'
import milkImg from '../../assets/img/allergens/milk.png'
import peanutsImg from '../../assets/img/allergens/peanuts.png'
import soyImg from '../../assets/img/allergens/soy.png'
import treenutsImg from '../../assets/img/allergens/treenuts.png'
import wheatImg from '../../assets/img/allergens/wheat.png'
import crustaceansImg from '../../assets/img/allergens/crustaceans.png'

const Slider = lazy(() => import('react-slick'))
const ProductPdf = lazy(() => import('./components/ProductPdf'))

const keepOrderBy = [
    { type: 'gallon' },
    { type: 'bar bottle' },
    { type: 'cup', size: 3 },
    { type: 'cup', size: 2 },
    { type: 'cup', size: 1.75 },
    { type: 'cup', size: 1.5 },
    { type: 'cup', size: 1.25 },
    { type: 'cup', size: 1 },
    { type: 'cup', size: 0.75 },
    { type: 'pouch' },
    { type: 'tub' },
    { type: 'bag-in-box' },
    { type: 'pillow pack' },
    { type: 'dispenser pack' },
]
class ProductSingle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            display: '',
            attachmentsError: false,
            isRecipesByTypesFetched: false,
        }

        this.props.fetchDetails({
            slug: get(this.props, 'match.params.slug'),
        })

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.resetRelatedRecipes()
    }

    componentDidUpdate(prevProps) {
        const { isRecipesByTypesFetched } = this.state
        const {
            productDetails,
            selected,
            location: { state },
            match: { params },
        } = this.props

        if (!prevProps.productDetails.length && productDetails.length) {
            if (state && state.productCode) {
                this.props.setSelectedProduct(state.productCode)
            } else {
                let code = productDetails[0].productCode

                if (params.slug) {
                    const filtered = productDetails.filter(
                        p => p.slug === params.slug
                    )

                    if (filtered && filtered.length)
                        code = filtered[0].productCode
                }

                this.props.setSelectedProduct(code)
            }

            if (isRecipesByTypesFetched) this.clearRelatedData()
        }

        if (selected && selected.id && !isRecipesByTypesFetched) {
            const { flavors } = selected

            if (selected.productCode === 'KE5052' || selected.productCode === 'KE5052ZY' || selected.productCode === 'KE5052B3' || selected.productCode === 'KE5060B3'
            )
            this.openModal('new-modal');

            this.setState({ isRecipesByTypesFetched: true })
            this.props.fetchRelatedRecipes({
                flavors: flavors.map(f => ({ id: f })),
                selected: selected.id,
                products: [{ id: selected.id, code: selected.productCode }],
            })
        }
    }

    componentWillUnmount() {
        this.props.resetDetails()
    }

    clearRelatedData() {
        this.props.resetRelatedRecipes()
        this.setState({ isRecipesByTypesFetched: false })
    }

    closeModal() {
        this.setState({ display: '' })
        this.props.clearEmailWithFilesData()
    }

    openModal(modalId) {
        this.setState({ display: 'flex', modalId })
    }

    format(value) {
        return value || 0
    }

    round(value, exp) {
        if (typeof exp === 'undefined' || +exp === 0) return Math.round(value)

        value = +value
        exp = +exp

        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
            return NaN

        // Shift
        value = value.toString().split('e')
        value = Math.round(
            +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
        )

        // Shift back
        value = value.toString().split('e')
        return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
    }

    getProductsByType() {
        return keepOrderBy
            .map((ent, i) =>
                find(
                    this.props.productDetails || [],
                    ent.size
                        ? {
                              caseInfo: {
                                  packageType: ent.type,
                                  individualUnitVal: ent.size,
                              },
                          }
                        : { caseInfo: { packageType: ent.type } }
                )
            )
            .filter(Boolean)
    }

    handleCheckbox(e) {
        this.setState({
            attachmentsError: false,
            [e.target.name]: e.target.checked,
        })
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleClick(selected) {
        const { history } = this.props
        this.props.setSelectedProduct(selected.productCode)

        history.push(`/products/${selected.slug}`)
    }

    handleGAClick(label, cb) {
        triggerGAEvent({
            cb,
            label,
            action: 'Button Click',
            category: 'Product Info',
        })
    }

    renderRelatedRecipes() {
        const results = []
        const { flavors, selected, relatedRecipes } = this.props
        const flavorSourceIds = flavors.map(f => f.id)
        const renderChilds = (sourceItem, idx) => (
            <Link
                className="block recipe gray"
                key={`${idx}-related-recipe-${sourceItem.id}`}
                to={{
                    pathname: `/recipes/${sourceItem.slug}`,
                    state: { recipeID: sourceItem.id },
                }}
            >
                <div className="block-img">
                    <img
                        src={sourceItem.details.thumbnail}
                        alt={`${idx}-recipe-${sourceItem.id}`}
                    />
                </div>
                <div className="block-txt">
                    <div className="block-title">
                        <p>{startCase(sourceItem.label)}</p>
                    </div>
                </div>
            </Link>
        )

        for (const sourceItemId of Object.keys(relatedRecipes)) {
            const sourceItems = relatedRecipes[sourceItemId]
            const isFlavorSource = flavorSourceIds.includes(sourceItemId)
            const entity = isFlavorSource
                ? flavors.find(f => f.id === sourceItemId)
                : selected

            if (entity) {
                const { id, productCode, name } = entity
                const label = String(name).toLowerCase()

                results.push(
                    <div
                        className="related-wrapper"
                        key={`${id}-related-items`}
                    >
                        <h3>Our best {label} recipes</h3>
                        <Link
                            className="button"
                            to={`/search-result?${productCode ||
                                label}#recipes`}
                        >
                            See more recipes with this{' '}
                            {productCode ? 'product' : 'flavor'}
                        </Link>
                        <div className="recipe-list">
                            {sourceItems.map(renderChilds)}
                        </div>
                    </div>
                )
            }
        }

        return results
    }

    renderMarketingAllergens() {
        const {
            selected: {
                claims: { marketing },
            },
        } = this.props
        const marketingImgs = {
            fatFree: fatFree,
            lite: lite,
            msg: noMsg,
            hfcs: noHfcs,
            edta: noEdta,
            artificialFlavors: noArtifFlav,
            syntheticColors: noSynthColors,
            artificialPreservatives: noArtifPreserv,
        }

        return Object.keys(marketingImgs).map((ent, i) =>
            marketing[ent] === (ent.match(/fatFree|lite/) ? 'Yes' : 'No') ? (
                <img
                    src={marketingImgs[ent]}
                    alt={`No${ent}`}
                    key={`marketing-${i}`}
                />
            ) : null
        )
    }

    renderVitamins() {
        const vitamins = ['D', 'A', 'C']
        const {
            selected: { nutrition },
        } = this.props

        return vitamins.map((ent, i) =>
            nutrition[`vitamin${ent}Val`] !== null ? (
                <li key={`vitamin-${i}`}>
                    Vitamin {ent} {this.format(nutrition[`vitamin${ent}Val`])}
                    mcg
                    <span className="pull-right">
                        {this.format(nutrition[`vitamin${ent}Percent`])}%
                    </span>
                </li>
            ) : null
        )
    }

    renderAllergens() {
        const results = []
        const {
            selected: {
                claims: { allergens },
                claims: { sensitivities },
            },
        } = this.props
        const allergensImgs = {
            soy: soyImg,
            crustacean: crustaceansImg,
            eggs: eggsImg,
            fish: fishImg,
            milk: milkImg,
            wheat: wheatImg,
            peanuts: peanutsImg,
            treenuts: treenutsImg,
            sesame: sesameImg,
        }
        
        Object.keys(allergens).forEach(
            (ent, i) =>
                allergens[ent] === 'Contains' &&
                results.push(
                    <div key={`allergen-${i}`}>
                        <img src={allergensImgs[ent]} alt={ent} />
                        <label>{ent}</label>
                    </div>
                )
        )

        return results.length ? (
            <div className="block-info">
                <h4>Contains:</h4>
                <div className="ingredients-icons">
                    {results}
                    {sensitivities.sesame === 'Contains' ? (
                        <div>
                            <img src={allergensImgs.sesame} alt='sesame' />
                            <label>sesame</label>
                        </div>
                    ): null
                    }
                </div>
            </div>
        ) : null
    }

    renderProductImages(isInModal) {
        const {
            selected: { images },
        } = this.props
        const labels = {
            each: 'Each',
            case: 'Case',
            souflet: 'Soufflé',
            innerCase: 'Inner Case',
        }

        return Object.keys(labels).map((prop, idx) => {
            if (isInModal) {
                return (
                    <div className="form-group" key={`product-image-${idx}`}>
                        {images[prop] ? (
                            <>
                                <img src={images[prop]} alt={labels[prop]} />
                                <span>{labels[prop]}</span>
                                <Checkbox
                                    name={prop}
                                    checked={this.state[prop]}
                                    onChange={this.handleCheckbox}
                                />
                            </>
                        ) : (
                            <p>No image data</p>
                        )}
                    </div>
                )
            } else {
                return (
                    <div key={`product-image-${idx}`}>
                        {images[prop] ? (
                            <img src={images[prop]} alt={labels[prop]} />
                        ) : (
                            <p>No image data</p>
                        )}
                        <p>{labels[prop]}</p>
                    </div>
                )
            }
        })
    }

    formatProductSize(product) {
        const {
            caseInfo: {
                packageType,
                individualUnitVal,
                individualUnitPercent: appender,
            },
        } = product
        return packageType === 'cup'
            ? `${packageType} (${individualUnitVal} ${
                  appender === 'ONZ' ? 'oz' : appender
              })`
            : packageType
    }

    renderSelectedProduct() {
        if (!this.props.selected) {
            return null
        }

        const { selected: item } = this.props

        const {
            claims: { marketing },
            nutrition,
        } = item
        const productsByType = this.getProductsByType()
        const calculateServings = () => {
            const {
                nutrition: { formatTypeCode },
                caseInfo: { averageServings, quantityInnerPack },
            } = item

            if (formatTypeCode) {
                if (
                    averageServings &&
                    quantityInnerPack &&
                    +quantityInnerPack
                ) {
                    return averageServings / Number(quantityInnerPack)
                } else {
                    return 1
                }
            } else {
                return averageServings || 1
            }
        }

        return (
            <div className="product-desktop-wrapper">
                <div className="two-columns">
                    <div className="aside">
                        {productsByType.map((product, i) => (
                            <div
                                key={`main-product-image-${i}-${product.productCode}`}
                                className={classNames({
                                    'product-img': true,
                                    hide:
                                        product.productCode !==
                                        item.productCode,
                                    [`package-${product.caseInfo.packageType}`]: true,
                                })}
                            >
                                <img src={product.images.website} alt="" />
                            </div>
                        ))}
                        <div className="nutrition-wrapper">
                            <h2>Nutrition Facts</h2>
                            <ul>
                                <li className="lg">
                                    <span>
                                        {calculateServings()} servings per
                                        container
                                    </span>
                                    <br />
                                    <span>
                                        <strong>Serving size</strong>
                                    </span>{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {nutrition.serviceSizeHouseholding}{' '}
                                            (
                                            {this.format(
                                                nutrition.serviceSizeValue
                                            )}
                                            g)
                                        </strong>
                                    </span>
                                </li>
                                <li className="no-border">
                                    <strong>Amount per serving</strong>
                                </li>
                                <li className="med">
                                    <span className="med-text">
                                        <strong>Calories</strong>
                                    </span>
                                    <span className="pull-right big-text">
                                        <strong>
                                            {this.format(nutrition.caloriesVal)}
                                        </strong>
                                    </span>
                                </li>
                                <li className="text-right">
                                    <strong>% Daily Value*</strong>
                                </li>
                                <li>
                                    <strong>Total Fat</strong>{' '}
                                    {this.format(nutrition.totalFatVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.totalFatPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Saturated Fat{' '}
                                    {this.format(nutrition.saturatedFatVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.saturatedFatPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    <em>Trans</em> Fat{' '}
                                    {this.format(nutrition.transfattyAcids)}g
                                </li>
                                <li>
                                    <strong>Cholesterol</strong>{' '}
                                    {this.format(nutrition.cholesterolVal)}mg{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.cholesterolPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li>
                                    <strong>Sodium</strong>{' '}
                                    {this.format(nutrition.sodiumVal)}mg{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.sodiumPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li>
                                    <strong>Total Carbohydrate</strong>{' '}
                                    {this.format(nutrition.carbohydratesVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.carbohydratesPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Dietary Fiber{' '}
                                    {this.format(nutrition.totalDietaryVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.totalDietaryPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Total Sugars{' '}
                                    {this.format(nutrition.totalSugarVal)}g
                                </li>
                                <li className="indent2x">
                                    Includes{' '}
                                    {this.format(nutrition.addedSugarVal)}g
                                    Added Sugars{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.addedSugarPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="lg">
                                    <strong>Protein</strong>{' '}
                                    {this.format(nutrition.proteinVal)}g
                                </li>
                                {this.renderVitamins()}
                                <li>
                                    Calcium {this.format(nutrition.calciumVal)}
                                    mg{' '}
                                    <span className="pull-right">
                                        {this.format(nutrition.calciumPercent)}%
                                    </span>
                                </li>
                                <li>
                                    Iron {this.format(nutrition.ironVal)}mg{' '}
                                    <span className="pull-right">
                                        {this.format(nutrition.ironPercent)}%
                                    </span>
                                </li>
                                <li className="med">
                                    Potassium{' '}
                                    {this.format(nutrition.potassiumVal)}mg{' '}
                                    <span className="pull-right">
                                        {this.format(
                                            nutrition.potassiumPercent
                                        )}
                                        %
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="ingredients-wrapper">
                            <h3>Ingredients</h3>
                            <small>{item.ingredients}</small>
                            {this.renderAllergens()}
                        </div>
                    </div>
                    {item.productCode === 'KE5052' || item.productCode === 'KE5052ZY' || item.productCode === 'KE5052B3' || item.productCode === 'KE5060B3' ? (
                        <div className="product-intro">
                            <div className="two-columns new">
                                <div>
                                    <h2>{item.name}</h2>
                                    <ul className="tabs-list">
                                        {productsByType.map((product, i) => (
                                            <li
                                                onClick={() => this.handleClick(product)}
                                                key={`product-type-${i}-${product.productCode}`}
                                                className={classNames({
                                                    active:
                                                        product.productCode ===
                                                        item.productCode,
                                                    [`package-${product.caseInfo.packageType}`]: true,
                                                })}
                                            >
                                                <img
                                                    src={product.images.websiteThumbnail}
                                                    alt=""
                                                />
                                                <span>
                                                    {this.formatProductSize(product)}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                    <p className="product-descr">{item.marketingMessage}</p>
                                    <div className="block-info">
                                        {prepareMarketingClaims(marketing)}
                                        <div className="allergens">
                                            {this.renderMarketingAllergens()}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="banner-new">
                                        <h3>Important Update on Your Favorite Dressing:</h3>
                                        <p>{item.name} has a new product code and GTIN.</p>
                                        {item.productCode === 'KE5060B3' ? (
                                        <p>
                                            <strong>We&rsquo;re Making Ken&rsquo;s Even Better!</strong> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement adds EDTA (a preservative), ensuring consistent quality while maintaining the rich, tangy flavor you love.
                                        </p>
                                        ) : (
                                        <p>
                                            <strong>We&rsquo;re Making Ken&rsquo;s Even Better!</strong> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement removes wheat and soy allergens, ensuring a cleaner ingredient list while maintaining the rich, tangy flavor you love.
                                        </p>
                                        )}
                                        <p><strong>Please refer to the nutrition and ingredient statement on the product packaging for accurate information.</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="button-group">
                                <button
                                    className="button"
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-view-specs-link-a
                                >
                                    view specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View images',
                                            this.openModal('imgs-modal')
                                        )
                                    }}
                                    data-gtm-view-images-link-a
                                >
                                    view images
                                </button>
                                <button 
                                    className="button" 
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Print specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-print-specs-link-a
                                >
                                    print specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Email specs',
                                            this.openModal('email-modal')
                                        )
                                    }}
                                    data-gtm-email-specs-images-link-a
                                >
                                    email specs/images
                                </button>
                                <Link
                                    className="button"
                                    to={{
                                        pathname: '/get-sample',
                                        state: {
                                            code: item.productCode,
                                            group: item.websiteGroup,
                                            slug: item.slug,
                                            gaFrom:
                                                'Request a sample - Individual Product',
                                            gaLabel:
                                                'Sample Request Submit – Product',
                                        },
                                    }}
                                    data-gtm-request-a-sample-product-link-a
                                >
                                    request a sample
                                </Link>
                            </div>
                            <h3>Case information</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>product code</th>
                                        <th>package type</th>
                                        <th>unit quantity</th>
                                    </tr>
                                    <tr>
                                        <td>{item.productCode}</td>
                                        <td>{item.caseInfo.packageType}</td>
                                        <td>{item.caseInfo.unitQuantity}</td>
                                    </tr>
                                    <tr>
                                        <th>case gtin</th>
                                        <th>inner upc</th>
                                        <th>case dimension lxwxh (in)</th>
                                    </tr>
                                    <tr>
                                        <td>{item.caseInfo.caseGtin}</td>
                                        <td>{item.caseInfo.innerUpc}</td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.caseLength,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseWidth,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseHeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>case gross weight (lb)</th>
                                        <th>case net weight (lb)</th>
                                        <th>full pallet weight (lb)</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.grossWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.netWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.fullWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            case volume (ft<sup>3</sup>)
                                        </th>
                                        <th>
                                            full pallet volume (ft<sup>3</sup>)
                                        </th>
                                        <th>tixhi</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.volume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.palletVolume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td className="lowercase">
                                            {item.caseInfo.tixHl}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>primary packaging</th>
                                        <th>secondary packaging</th>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <p>{item.caseInfo.primaryPackaging}</p>
                                        </td>
                                        <td width="50%">
                                            <p>
                                                {item.caseInfo.secondaryPackaging}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Storage, transport and usage</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>shelf life (days)</th>
                                        <th>storage and transport</th>
                                    </tr>
                                    <tr>
                                        <td>{item.storage.shelfLife}</td>
                                        <td>
                                            <p>{item.storage.storage}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="packages">
                                {item.images.case && (
                                    <img src={item.images.case} alt="Case" />
                                )}
                                {item.images.innerCase && (
                                    <img
                                        src={item.images.innerCase}
                                        alt="Inner Case"
                                    />
                                )}
                                {item.images.souflet && (
                                    <img src={item.images.souflet} alt="Souflet" />
                                )}
                            </div>
                            <div className="product-disclaimer">
                                <h3>Please note</h3>
                                <small>The information shown here may vary from the information on the product currently in distribution. Keep in mind that ingredients, formulas, and labeling regulations may change, so nutrition information may also change. For the most accurate information for a particular product, please refer to the nutrition and ingredient statement on the product package.</small>
                            </div>
                        </div>
                    ) : (
                        <div className="product-intro">
                            <h2>{item.name}</h2>
                            <ul className="tabs-list">
                                {productsByType.map((product, i) => (
                                    <li
                                        onClick={() => this.handleClick(product)}
                                        key={`product-type-${i}-${product.productCode}`}
                                        className={classNames({
                                            active:
                                                product.productCode ===
                                                item.productCode,
                                            [`package-${product.caseInfo.packageType}`]: true,
                                        })}
                                    >
                                        <img
                                            src={product.images.websiteThumbnail}
                                            alt=""
                                        />
                                        <span>
                                            {this.formatProductSize(product)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <p className="product-descr">{item.marketingMessage}</p>
                            <div className="block-info">
                                {prepareMarketingClaims(marketing)}
                                <div className="allergens">
                                    {this.renderMarketingAllergens()}
                                </div>
                            </div>
                            <div className="button-group">
                                <button
                                    className="button"
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-view-specs-link-a
                                >
                                    view specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View images',
                                            this.openModal('imgs-modal')
                                        )
                                    }}
                                    data-gtm-view-images-link-a
                                >
                                    view images
                                </button>
                                <button 
                                    className="button" 
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Print specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-print-specs-link-a
                                >
                                    print specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Email specs',
                                            this.openModal('email-modal')
                                        )
                                    }}
                                    data-gtm-email-specs-images-link-a
                                >
                                    email specs/images
                                </button>
                                <Link
                                    className="button"
                                    to={{
                                        pathname: '/get-sample',
                                        state: {
                                            code: item.productCode,
                                            group: item.websiteGroup,
                                            slug: item.slug,
                                            gaFrom:
                                                'Request a sample - Individual Product',
                                            gaLabel:
                                                'Sample Request Submit – Product',
                                        },
                                    }}
                                    data-gtm-request-a-sample-product-link-a
                                >
                                    request a sample
                                </Link>
                            </div>
                            <h3>Case information</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>product code</th>
                                        <th>package type</th>
                                        <th>unit quantity</th>
                                    </tr>
                                    <tr>
                                        <td>{item.productCode}</td>
                                        <td>{item.caseInfo.packageType}</td>
                                        <td>{item.caseInfo.unitQuantity}</td>
                                    </tr>
                                    <tr>
                                        <th>case gtin</th>
                                        <th>inner upc</th>
                                        <th>case dimension lxwxh (in)</th>
                                    </tr>
                                    <tr>
                                        <td>{item.caseInfo.caseGtin}</td>
                                        <td>{item.caseInfo.innerUpc}</td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.caseLength,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseWidth,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseHeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>case gross weight (lb)</th>
                                        <th>case net weight (lb)</th>
                                        <th>full pallet weight (lb)</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.grossWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.netWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.fullWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            case volume (ft<sup>3</sup>)
                                        </th>
                                        <th>
                                            full pallet volume (ft<sup>3</sup>)
                                        </th>
                                        <th>tixhi</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.volume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.palletVolume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td className="lowercase">
                                            {item.caseInfo.tixHl}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>primary packaging</th>
                                        <th>secondary packaging</th>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <p>{item.caseInfo.primaryPackaging}</p>
                                        </td>
                                        <td width="50%">
                                            <p>
                                                {item.caseInfo.secondaryPackaging}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Storage, transport and usage</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>shelf life (days)</th>
                                        <th>storage and transport</th>
                                    </tr>
                                    <tr>
                                        <td>{item.storage.shelfLife}</td>
                                        <td>
                                            <p>{item.storage.storage}</p>
                                        </td>
                                    </tr>
                                    {/*<tr>
                                        <th>guaranteed shelf life on arrival (days)</th>
                                    </tr>
                                    <tr>
                                        <td>item.storage.shelfLifeArrival</td>
                                    </tr>*/}
                                </tbody>
                            </table>
                            <div className="packages">
                                {item.images.case && (
                                    <img src={item.images.case} alt="Case" />
                                )}
                                {item.images.innerCase && (
                                    <img
                                        src={item.images.innerCase}
                                        alt="Inner Case"
                                    />
                                )}
                                {item.images.souflet && (
                                    <img src={item.images.souflet} alt="Souflet" />
                                )}
                            </div>
                            <div className="product-disclaimer">
                                <h3>Please note</h3>
                                <small>The information shown here may vary from the information on the product currently in distribution. Keep in mind that ingredients, formulas, and labeling regulations may change, so nutrition information may also change. For the most accurate information for a particular product, please refer to the nutrition and ingredient statement on the product package.</small>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderSelectedProductMobile() {
        if (!this.props.selected) {
            return null
        }

        const { selected: item } = this.props
        const productsByType = this.getProductsByType()
        const {
            claims: { marketing },
            nutrition,
        } = item
        const productTypeOpts = productsByType.map((product, i) => ({
            details: product,
            label: this.formatProductSize(product),
        }))

        configureAnchors({ offset: -75 })

        return (
            <div className="product-mobile-wrapper">
                <div className="product-nav">
                    <a href="#case" className="button">
                        case information
                    </a>
                    <a href="#ingredients" className="button">
                        ingredients
                    </a>
                    <a href="#nutritional" className="button">
                        nutritional
                    </a>
                    <a href="#general" className="button">
                        general
                    </a>
                </div>
                <div>
                    {item.productCode === 'KE5052' || item.productCode === 'KE5052ZY' || item.productCode === 'KE5052B3' || item.productCode === 'KE5060B3' ? 
                        <div className="banner-new">
                            <h3>Important Update on Your Favorite Dressing:</h3>
                            <p>{item.name} has a new product code and GTIN.</p>
                            {item.productCode === 'KE5060B3' ? (
                                <p>
                                    <strong>We&rsquo;re Making Ken&rsquo;s Even Better!</strong> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement adds EDTA (a preservative), ensuring consistent quality while maintaining the rich, tangy flavor you love.
                                </p>
                                ) : (
                                <p>
                                    <strong>We&rsquo;re Making Ken&rsquo;s Even Better!</strong> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement removes wheat and soy allergens, ensuring a cleaner ingredient list while maintaining the rich, tangy flavor you love.
                                </p>
                            )}
                            <p><strong>Please refer to the nutrition and ingredient statement on the product packaging for accurate information.</strong></p>
                        </div>
                    : null
                    }
                    <ScrollableAnchor id={'general'}>
                        <div>
                            <h2>{item.name}</h2>
                            <div className="two-columns">
                                {productsByType.map((product, i) => (
                                    <div
                                        key={`main-product-image-${i}-${product.productCode}`}
                                        className={classNames({
                                            'product-img': true,
                                            hide:
                                                product.productCode !==
                                                item.productCode,
                                            [`package-${product.caseInfo.packageType}`]: true,
                                        })}
                                    >
                                        <img
                                            src={product.images.website}
                                            alt=""
                                        />
                                    </div>
                                ))}
                                <div className="product-intro">
                                    <div className="block-info">
                                        {prepareMarketingClaims(marketing)}
                                        <div className="allergens">
                                            {this.renderMarketingAllergens()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <Select
                                    className="select"
                                    isClearable={false}
                                    isSearchable={false}
                                    classNamePrefix="select"
                                    options={productTypeOpts}
                                    placeholder={this.formatProductSize(item)}
                                    onChange={e =>
                                        this.props.setSelectedProduct(
                                            e.details.productCode
                                        )
                                    }
                                />
                            </div>
                            <p className="product-descr">
                                {item.marketingMessage}
                            </p>
                            <div className="button-group">
                                <button
                                    className="button"
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-view-specs-link-a
                                >
                                    view specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'View images',
                                            this.openModal('imgs-modal')
                                        )
                                    }}
                                    data-gtm-view-images-link-a
                                >
                                    view images
                                </button>
                                <button 
                                    className="button" 
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Print specs',
                                            window.open(item.files.pdf)
                                        )
                                    }}
                                    data-gtm-print-specs-link-a
                                >
                                    print specs
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleGAClick(
                                            'Email specs',
                                            this.openModal('email-modal')
                                        )
                                    }}
                                    data-gtm-email-specs-images-link-a
                                >
                                    email specs/images
                                </button>
                                <Link
                                    className="button"
                                    to={{
                                        pathname: '/get-sample',
                                        state: {
                                            code: item.productCode,
                                            group: item.websiteGroup,
                                            slug: item.slug,
                                            gaFrom:
                                                'Request a sample - Individual Product',
                                            gaLabel:
                                                'Sample Request Submit – Product',
                                        },
                                    }}
                                    data-gtm-request-a-sample-product-link-a
                                >
                                    request a sample
                                </Link>
                            </div>
                        </div>
                    </ScrollableAnchor>

                    <ScrollableAnchor id={'nutritional'}>
                        <div className="nutrition-wrapper">
                            <h2>Nutrition Facts</h2>
                            <ul>
                                <li className="lg">
                                    <span>servings per container</span>
                                    <br />
                                    <span>
                                        <strong>Serving size</strong>
                                    </span>{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {nutrition.serviceSizeHouseholding}{' '}
                                            (
                                            {this.format(
                                                nutrition.serviceSizeValue
                                            )}
                                            g)
                                        </strong>
                                    </span>
                                </li>
                                <li className="no-border">
                                    <strong>Amount per serving</strong>
                                </li>
                                <li className="med">
                                    <span className="med-text">
                                        <strong>Calories</strong>
                                    </span>
                                    <span className="pull-right big-text">
                                        <strong>
                                            {this.format(nutrition.caloriesVal)}
                                        </strong>
                                    </span>
                                </li>
                                <li className="text-right">
                                    <strong>% Daily Value*</strong>
                                </li>
                                <li>
                                    <strong>Total Fat</strong>{' '}
                                    {this.format(nutrition.totalFatVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.totalFatPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Saturated Fat{' '}
                                    {this.format(nutrition.saturatedFatVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.saturatedFatPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    <em>Trans</em> Fat{' '}
                                    {this.format(nutrition.transfattyAcids)}g
                                </li>
                                <li>
                                    <strong>Cholesterol</strong>{' '}
                                    {this.format(nutrition.cholesterolVal)}mg{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.cholesterolPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li>
                                    <strong>Sodium</strong>{' '}
                                    {this.format(nutrition.sodiumVal)}mg{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.sodiumPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li>
                                    <strong>Total Carbohydrate</strong>{' '}
                                    {this.format(nutrition.carbohydratesVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.carbohydratesPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Dietary Fiber{' '}
                                    {this.format(nutrition.totalDietaryVal)}g{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.totalDietaryPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="indent">
                                    Total Sugars{' '}
                                    {this.format(nutrition.totalSugarVal)}g
                                </li>
                                <li className="indent2x">
                                    Includes{' '}
                                    {this.format(nutrition.addedSugarVal)}g
                                    Added Sugars{' '}
                                    <span className="pull-right">
                                        <strong>
                                            {this.format(
                                                nutrition.addedSugarPercent
                                            )}
                                            %
                                        </strong>
                                    </span>
                                </li>
                                <li className="lg">
                                    <strong>Protein</strong>{' '}
                                    {this.format(nutrition.proteinVal)}g
                                </li>
                                {this.renderVitamins()}
                                <li>
                                    Calcium {this.format(nutrition.calciumVal)}
                                    mg{' '}
                                    <span className="pull-right">
                                        {this.format(nutrition.calciumPercent)}%
                                    </span>
                                </li>
                                <li>
                                    Iron {this.format(nutrition.ironVal)}mg{' '}
                                    <span className="pull-right">
                                        {this.format(nutrition.ironPercent)}%
                                    </span>
                                </li>
                                <li className="med">
                                    Potassium{' '}
                                    {this.format(nutrition.potassiumVal)}mg{' '}
                                    <span className="pull-right">
                                        {this.format(
                                            nutrition.potassiumPercent
                                        )}
                                        %
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </ScrollableAnchor>

                    <ScrollableAnchor id={'ingredients'}>
                        <div>
                            <h3>Ingredients</h3>
                            <small>{item.ingredients}</small>
                            {this.renderAllergens()}
                        </div>
                    </ScrollableAnchor>

                    <ScrollableAnchor id={'case'}>
                        <div>
                            <h3>Product information</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>product code</th>
                                        <th>package type</th>
                                    </tr>
                                    <tr>
                                        <td>{item.productCode}</td>
                                        <td>{item.caseInfo.packageType}</td>
                                    </tr>
                                    <tr>
                                        <th>unit quantity</th>
                                        <th>case gtin</th>
                                    </tr>
                                    <tr>
                                        <td>{item.caseInfo.unitQuantity}</td>
                                        <td>{item.caseInfo.caseGtin}</td>
                                    </tr>
                                    <tr>
                                        <th>inner upc</th>
                                        <th>case dimension lxwxh (in)</th>
                                    </tr>
                                    <tr>
                                        <td>{item.caseInfo.innerUpc}</td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.caseLength,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseWidth,
                                                3
                                            ).toFixed(3)}{' '}
                                            x{' '}
                                            {this.round(
                                                item.caseInfo.caseHeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>case gross weight (lb)</th>
                                        <th>case net weight (lb)</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.grossWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.netWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>full pallet weight (lb)</th>
                                        <th>
                                            case volume (ft<sup>3</sup>)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.fullWeight,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td>
                                            {this.round(
                                                item.caseInfo.volume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            full pallet volume (ft<sup>3</sup>)
                                        </th>
                                        <th>tixhi</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.round(
                                                item.caseInfo.palletVolume,
                                                3
                                            ).toFixed(3)}
                                        </td>
                                        <td className="lowercase">
                                            {item.caseInfo.tixHl}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>primary packaging</th>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <p>
                                                {item.caseInfo.primaryPackaging}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>secondary packaging</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>
                                                {
                                                    item.caseInfo
                                                        .secondaryPackaging
                                                }
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>shelf life (days)</th>
                                        <th>
                                            guaranteed shelf life on arrival
                                            (days)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            {item.storage.shelfLife}
                                        </td>
                                        <td width="50%">
                                            {/* item.storage.shelfLifeArrival */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan="2">
                                            storage and transport
                                        </th>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <p>{item.storage.storage}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="packages">
                                {item.images.case && (
                                    <img src={item.images.case} alt="Case" />
                                )}
                                {item.images.innerCase && (
                                    <img
                                        src={item.images.innerCase}
                                        alt="Inner Case"
                                    />
                                )}
                                {item.images.souflet && (
                                    <img
                                        src={item.images.souflet}
                                        alt="Souflet"
                                    />
                                )}
                            </div>
                        </div>
                    </ScrollableAnchor>

                    <div className="product-disclaimer">
                        <h3>Please note</h3>
                        <small>The information shown here may vary from the information on the product currently in distribution. Keep in mind that ingredients, formulas, and labeling regulations may change, so nutrition information may also change. For the most accurate information for a particular product, please refer to the nutrition and ingredient statement on the product package.</small>
                    </div>
                </div>
            </div>
        )
    }

    handleProductSelection(productCode) {
        this.props.setSelectedProduct(productCode)
    }

    handleSubmit(e) {
        const { email } = this.state
        const props = ['pdf', 'each', 'case', 'innerCase', 'souflet']
        const {
            selected: {
                files: { pdf: pdfFilePath },
                images,
            },
        } = this.props
        const attachments = []
        e.preventDefault()

        props.forEach(prop => {
            if (this.state[prop])
                attachments.push({
                    href: prop === 'pdf' ? pdfFilePath : images[prop],
                })
        })

        if (attachments.length) {
            this.props.sendEmailWithFiles({
                email,
                attachments,
                type: 'product',
            })
        } else {
            this.setState({ attachmentsError: true })
        }

        triggerGAEvent({
            label: 'Email submit',
            action: 'Button Click',
            category: 'Product Info',
        })
    }

    render() {
        const {
            loading,
            selected,
            emailSending,
            relatedRecipes,
            relatedRecipesLoading,
            emailSomethingWentWrong,
            emailShowSuccessMessage,
        } = this.props
        const { email, modalId, display, attachmentsError } = this.state
        const shouldShowDetails = selected && !loading
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        }

        let modal = null

        if (modalId === 'new-modal') {
            modal = (
                <Modal visible={display} onClose={this.closeModal}>
                    <h3>Important Update on Your Favorite Dressing:</h3>
                    <p className="descr">{selected.name} has a new product code and GTIN.</p>
                    {selected.productCode === 'KE5060B3' ? (
                        <p className="descr"><span className="strong">We&rsquo;re Making Ken&rsquo;s Even Better!</span> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement adds EDTA (a preservative), ensuring consistent quality while maintaining the rich, tangy flavor you love.</p>
                    ) : (
                        <p className="descr"><span className="strong">We&rsquo;re Making Ken&rsquo;s Even Better!</span> Our commitment to delivering the best possible products means consistently improving our recipes. This enhancement removes wheat and soy allergens, ensuring a cleaner ingredient list while maintaining the rich, tangy flavor you love.</p>
                    )}
                    <p className="descr"><strong>Please refer to the nutrition and ingredient statement on the product packaging for accurate information.</strong></p>
                </Modal>
            )
        }

        if (modalId === 'imgs-modal') {
            modal = (
                <Modal visible={display} onClose={this.closeModal}>
                    <Suspense fallback={<p>Loading...</p>}>
                        <Slider {...settings}>
                            {this.renderProductImages()}
                        </Slider>
                    </Suspense>
                </Modal>
            )
        }

        if (modalId === 'email-modal') {
            if (emailShowSuccessMessage) {
                modal = (
                    <Modal visible={display} onClose={this.closeModal}>
                        <h3>Thanks so much, your email is on the way.</h3>
                    </Modal>
                )
            }

            if (emailSomethingWentWrong) {
                modal = (
                    <Modal visible={display} onClose={this.closeModal}>
                        <h3>Oops! Something went wrong.</h3>
                    </Modal>
                )
            }

            if (!emailShowSuccessMessage && !emailSomethingWentWrong) {
                modal = (
                    <Modal visible={display} onClose={this.closeModal}>
                        <h3>
                            Product information for
                            <br /> {selected.name}
                        </h3>
                        <p className="descr">
                            Select product specs and images you would like to
                            email
                        </p>
                        <div className="modal-content">
                            <form
                                onSubmit={this.handleSubmit}
                                data-gtm-email-specs-button-link-a
                            >
                                <div className="download-fields">
                                    <div className="form-group">
                                        <Suspense
                                            fallback={<p>Loading the PDF...</p>}
                                        >
                                            <ProductPdf />
                                        </Suspense>
                                        <Checkbox
                                            name="pdf"
                                            checked={this.state.pdf}
                                            onChange={this.handleCheckbox}
                                        />
                                    </div>
                                    {this.renderProductImages(true)}
                                </div>
                                {attachmentsError && (
                                    <p className="attachment-error">
                                        Please select an items from the list
                                        above
                                    </p>
                                )}
                                <div className="form-group one-row">
                                    <input
                                        required
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleInputChange}
                                        placeholder="Email address*"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                    <div className="submit">
                                        {emailSending && (
                                            <button disabled>
                                                Sending, please wait...
                                            </button>
                                        )}
                                        {!emailSending && (
                                            <button type="submit">
                                                Send Product Info
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                )
            }
        }

        return (
            <>
                {!shouldShowDetails && (
                    <div className="body-wrapper">
                        <p>Loading ...</p>
                    </div>
                )}
                {shouldShowDetails && (
                    <>
                        <MetaTags
                            title={get(selected, ['meta', 'title'])}
                            keywords={get(selected, ['meta', 'keyword'])}
                            description={get(selected, ['meta', 'description'])}
                        />
                        <div className="body-wrapper products single">
                            {this.renderSelectedProduct()}
                            {this.renderSelectedProductMobile()}
                            {modal}
                        </div>

                        {relatedRecipesLoading && (
                            <div className="loader">Loading...</div>
                        )}
                        {!relatedRecipesLoading && (
                            <div className="body-wrapper products">
                                {Object.keys(relatedRecipes).length
                                    ? this.renderRelatedRecipes()
                                    : null}
                            </div>
                        )}
                    </>
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    selected: get(state, 'products.selected'),
    flavors: get(state, 'lookup.flavors', []),
    emailSending: get(state, 'recipes.emailSending'),
    productDetails: get(state, 'products.details', []),
    loading: get(state, 'products.paging.loading', false),
    relatedRecipes: get(state, 'recipes.relatedRecipes'),
    relatedRecipesLoading: get(state, 'recipes.relatedRecipesLoading'),
    emailShowSuccessMessage: get(state, 'recipes.emailShowSuccessMessage'),
    emailSomethingWentWrong: get(state, 'recipes.emailSomethingWentWrong'),
})

export default connect(
    mapStateToProps,
    {
        resetDetails,
        setSelectedProduct,
        sendEmailWithFiles,
        resetRelatedRecipes,
        clearEmailWithFilesData,
        fetchRelatedRecipes,
        fetchDetails: fetchProductDetails,
    }
)(ProductSingle)
