import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import _Get from 'lodash/get';
import _SortBy from 'lodash/sortBy';
import InfiniteScroll from 'react-infinite-scroller';
import Classnames from 'classnames';

import './plus-one.scss';

import { fetchPlusOnes, fetchProductsFilter, updateFilters, setSelectedPlusOne } from './actions';

const Filters = lazy(() => import('./components/Filters'));
const ModalPlusOne = lazy(() => import('../../components/ModalPlusOne'));
const ModalEmailIt = lazy(() => import('../../components/ModalEmailIt'));
const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

class PlusOne extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            displayPlusOneModal: false,
            displayEmailItModal: false,
        };

        if (!this.props.products.length){
            this.props.fetchProductsFilter();
        }

        this.openPlusOneModal = this.openPlusOneModal.bind(this);
        this.closePlusOneModal = this.closePlusOneModal.bind(this);
        this.toggleEmailItModal = this.toggleEmailItModal.bind(this);
    }

    componentDidMount() {
        const { location: { state } } = this.props;
        const defaultValues = { flavor: null, product: null };

        window.scrollTo(0, 0);

        this.props.updateFilters(state || defaultValues);
    }

    componentDidUpdate(prevProps) {
        const { location: { state }, filters, paging } = this.props;
        const hasPropsChanged = filters.flavor !== prevProps.filters.flavor || filters.product !== prevProps.filters.product;
        const hasStateChanged = state && (state.flavor !== filters.flavor || state.product !== filters.product);

        if (hasStateChanged)
          this.props.updateFilters(state);

        if (hasPropsChanged) {
            this.props.fetchPlusOnes(filters, { ...paging, page: 1 }, true);
        }
    }

    closePlusOneModal() {
        this.setState({ displayPlusOneModal: false });
    }

    openPlusOneModal() {
        this.setState({ displayPlusOneModal: true });
    }

    toggleEmailItModal() {
        this.setState({
            displayPlusOneModal: !this.state.displayPlusOneModal,
            displayEmailItModal: !this.state.displayEmailItModal
        });
    }

    handleEmailItClose = () => {
        this.toggleEmailItModal();
    }

    handlePlusOneSelection(plusOne) {
        this.props.setSelectedPlusOne(plusOne);
        this.openPlusOneModal();
    }

    handleInfiniteLoad() {
        const { paging: { hasNext, loading, page }, filters } = this.props;
        if (hasNext && !loading) {
            const newPage = Object.assign({}, this.props.paging, { page: page + 1 });
            this.props.fetchPlusOnes(filters, newPage, false);
        }
    }

    onFilterChange(filterBy, options) {
        let newFilter = {};

        newFilter[filterBy] = null;

        if (options) {
            if (options[filterBy] && options[filterBy].length) {
                newFilter = { flavor: null, product: null };
                Object.keys(options).forEach(key => {
                    if (options[key]) {
                        if (Array.isArray(options[key])) {
                            newFilter[key] = options[key].map(o =>
                                (key === 'product' ? o._id : o.id) || o);
                        } else {
                            newFilter[key] = [options[key]];
                        }
                    }
                });
            }
        }

        this.props.updateFilters(Object.assign({}, this.props.filters, newFilter));
        this.props.history.push('/plus-one', Object.assign({}, this.props.filters, newFilter));
    }

    onFilterClear() {
        const filters = { flavor: null, product: null };
        this.props.updateFilters(filters);
        this.props.history.push('/plus-one', filters);
    }

    renderTitle(title = '') {
        const maxRowLength = 11

        if (!title || title.length <= maxRowLength) {
            return <div>{title}</div>
        }

        const titleArr = title.split(' ')

        const titleRows = []

        let currentIndex = 0

        for (const word of titleArr) {
            if (!titleRows.length) {
                titleRows.push(word)
            } else {
                const currentRow = titleRows[currentIndex]

                const currentRowRestLength =
                    maxRowLength - currentRow.length

                // 1 is for empty space
                if (currentRowRestLength >= word.length + 1) {
                    // add to current row
                    titleRows[currentIndex] = `${currentRow} ${word}`
                } else {
                    // add to new row
                    titleRows.push(word)

                    currentIndex++
                }
            }
        }

        const hasExtraLentgth = titleRows.some(
            (r) => r.length > maxRowLength
        )

        return (
            <div
                className={Classnames({
                    'small-font-size': hasExtraLentgth,
                })}
            >
                {titleRows.map((r) => {
                    return <div>{r}</div>
                })}
            </div>
        )
    }

    renderPlusOnes() {
        const { plusOnes, noResults, paging: { loading } } = this.props;

        if (!loading && noResults)
            return <p>Sorry, no results were found</p>;

        return (
            plusOnes.map((item, idx) => {
                return (
                    <div onClick={() => this.handlePlusOneSelection(item)} className="block short plus-one-block" key={`plus-one-${idx}`} data-gtm-plus-one-name>
                        <div className="block-txt">
                            <div className="block-title plus-one-block-title">{this.renderTitle(item.title)}</div>
                        </div>
                    </div>
                )
            })
        );
    }

    render() {
        const {
            flavors, products,
            filters, selected: plusOne,
        } = this.props;
        const { displayPlusOneModal, displayEmailItModal } = this.state;

        return (
            <div>
                <div className="hero-wrapper plusone">
                    <h1>Put your own spin on it</h1>
                    <div className="descr">Give your favorite Ken’s dressings a little something-something using one or two ingredients already in your kitchen. Oh, this is gonna be good.</div>
                    <Suspense fallback={<p className="loading">Loading...</p>}>
                        <Filters
                            selections={filters}
                            flavors={_SortBy(flavors, 'name')}
                            products={_SortBy(products, 'name')}
                            onFilterClear={() => this.onFilterClear()}
                            onFilterChange={(f, o) => this.onFilterChange(f, o)}
                        />
                    </Suspense>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    className="body-wrapper plusone"
                    loadMore={() => this.handleInfiniteLoad()}
                    hasMore={this.props.paging.hasNext}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    {this.renderPlusOnes()}
                </InfiniteScroll>

                {plusOne && (
                    <Suspense fallback={null}>
                        <ModalPlusOne
                            visible={displayPlusOneModal}
                            onEmailItClick={this.toggleEmailItModal}
                            onClose={this.closePlusOneModal}
                        />
                    </Suspense>
                )}

                {plusOne && (
                    <Suspense fallback={null}>
                        <ModalEmailIt
                            visible={displayEmailItModal}
                            type="plus one"
                            from="Plus One Pop Up"
                            pdfFilePath={plusOne.pdfFilePath}
                            onClose={this.handleEmailItClose}
                        />
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="plus-one" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    flavors: _Get(state, 'lookup.flavors'),
    selected: _Get(state, 'plusOnes.selected'),
    paging: _Get(state, 'plusOnes.paging', {}),
    plusOnes: _Get(state, 'plusOnes.list', {}),
    products: _Get(state, 'plusOnes.products.list', []),
    filters: _Get(state, 'plusOnes.filters', {}),
    noResults: _Get(state, 'plusOnes.noResults', {}),
    productLines: _Get(state, 'lookup.productLines')
});

export default connect(mapStateToProps, {
    fetchPlusOnes, fetchProductsFilter, updateFilters,
    setSelectedPlusOne,
})(PlusOne);
