import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSearchResults } from '../search-result/actions';

import './avocado-ranch.scss';

import avocadoRanchSellSheet from '../../assets/pdf/kens_avocadoranch_pos.pdf';
import img1 from '../../assets/img/offers/avocado-ranch/avocadoranch-img1.jpg';
import img2 from '../../assets/img/offers/avocado-ranch/avocadoranch-img2.jpg';
import img3 from '../../assets/img/offers/avocado-ranch/avocadoranch-img3.jpg';
import garlic from '../../assets/img/offers/avocado-ranch/garlic.jpg';
import lemon from '../../assets/img/offers/avocado-ranch/lemon.jpg';
import guacamole from '../../assets/img/offers/avocado-ranch/guacamole.jpg';

// import greenGoddessSellSheet from '../../assets/pdf/kens_green_goddess_pos.pdf';

const SourceDataProductDetails = lazy(() => import('./components/SourceDataProductDetails'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));

const productId = [ '6386e235ae082400127bde58' ]; // Avocado Ranch Dressing (KE3605-2) - Production
// const productId = [ '6386e235e764f80011b788d1' ]; // Avocado Ranch Dressing (KE3605-2) - Staging

const recipes = [
    { _id: '6361709d8f62710012166d17', displayOrder: 1 }, // Seared Ahi Tuna & Avocado Salad
    { _id: '6360c6238f62710012166a1c', displayOrder: 2 }, // Buffalo Chicken Tacos with Avocado Ranch
    { _id: '63618b0c8f62710012166da9', displayOrder: 3 }, // Avocado SW Chicken Salad
    { _id: '636191558f62710012166dda', displayOrder: 4 }, // Avocado Ranch Chicken Salad Torta
    { _id: '6360c7aefe44050011897d4d', displayOrder: 5 }, // Chicken, Bacon And Tomato Lettuce Wraps With Avocado Ranch
    { _id: '636187b8fe44050011898068', displayOrder: 6 } // Smokey Avocado, Bacon & Tomato Grilled Cheese Sandwich
];

class AvocadoRanch extends PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            keys: [ 'products', 'recipes' ],
            ids: [...recipes.map(r => r._id), ...productId ]
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    filterResults(key) {
        const { searchResults } = this.props;
        const entity = searchResults.find((sr) => sr[key]);
        return entity && (key === 'products' ? entity[key][0] : entity[key]);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);
        const product = isDataLoaded && this.filterResults('products');

        return (
            <>
                <div className="hero-wrapper avocado-ranch">
                    <h1>Avocado Ranch is Booming</h1>
                </div>

                <div className="body-wrapper avocado-ranch">
                    <div className="product-intro">
                        {product && (
                            <img src={product.details.websiteThumbnail} alt={product.details.name} />
                        )}
                        <div>
                            <h2>Ken&rsquo;s Avocado Ranch</h2>
                            <h4>NO Artificial Flavors | NO High-Fructose Corn Syrup | NO Synthetic Colors | NO Added MSG</h4>
                            <p>Ken&rsquo;s Avocado Ranch Dressing adds a touch of Southern flair to any dish it&rsquo;s paired with. Made with a blend of creamy avocados, Southwest spices, zesty lime juice and classic ranch dressing, it provides a burst of flavor in every bite. This versatile dressing can be used to enhance the taste of seafood, tacos, sandwiches, salads and wraps.</p>
                            <div className="button-group">
                                <a className="button" href={avocadoRanchSellSheet}>download sell sheet</a>
                                {product && (
                                    <>
                                        <Link to={`/products/${product.slug}`} className="button">see more product info</Link>
                                        <a className="button" href={product.details.files.pdf}>view specs</a>
                                    </>
                                )}
                                <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Avocado Ranch Landing page' }}}>request a sample</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper avocado-ranch">
                    <h2>A Steadily Growing Flavor</h2>
                    <div className="descr">Avocado Ranch blends one of America&rsquo;s favorite dressings with avocado. The rich and creamy flavor profiles of both avocado and ranch work well together as a dipping sauce or added ingredient to many dishes. While most prepared as a salad dressing or spread on a sandwich, it can also be served as a dip for meat and dim sum.</div>
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={img1} alt="" />
                                <span>33%</span>
                            </div>
                            <div className="descr">growth on U.S. menus over the past four years and projected 22% growth by 2026.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={img2} alt="" />
                                <span>73%</span>
                            </div>
                            <div className="descr">of consumers who have tried Avocado Ranch love or like it.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={img3} alt="" />
                                <span>+111%</span>
                            </div>
                            <div className="descr">growth on menus in the last 10 years.</div>
                        </div>
                    </div>
                    {/* <div className="remark">Sources: Technomic Consumer Report 2019</div> */}
                </div>

                <div className="body-wrapper pampas avocado-ranch">
                    <h2>Avocado Ranch Recipes</h2>
                    <div className="descr">Let Avocado Ranch inspire you to bring some Southern flair to your menu.</div>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                </div>

                <div className="body-wrapper column avocado-ranch">
                    <h2>Product Information</h2>
                    {isDataLoaded ? <Suspense fallback={null}>
                        <SourceDataProductDetails settings={{
                            size: '2/1 Gallon',
                            weight: '17.31 lbs',
                            storage: 'Refrigerated'
                        }} />
                        </Suspense> : <p>Loading...</p>}
                </div>

                <div className="body-wrapper column avocado-ranch">
                    <h2>Make it your own</h2>
                    <div className="descr short">Try one of these unique flavor combinations, or make your own and explore all the places this versatile dressing can take you.</div>
                    <div className="make-it-wrapper">
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={garlic} alt="" /></div>
                            <p><strong>Avocado Garlic Ranch Aioli</strong></p>
                            <p>8 fl oz Ken&rsquo;s Avocado Ranch Dressing (KE3605-2)</p>
                            <p>8 fl oz Ken&rsquo;s Extra Heavy Mayonnaise (KE0898)</p>
                            <p>2 tsp garlic, pureed</p>
                            <p>1 tsp cilantro, chopped</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={lemon} alt="" /></div>
                            <p><strong>Avocado Parmesan Ranch</strong></p>
                            <p>16 fl oz Ken&rsquo;s Avocado Ranch Dressing (KE3605-2)</p>
                            <p>1 cup parmesan, grated</p>
                            <p>1 fl oz lemon juice</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={guacamole} alt="" /></div>
                            <p><strong>Spicy Guacamole Ranch</strong></p>
                            <p>12 fl oz Ken&rsquo;s Avocado Ranch Dressing (KE3605-2)</p>
                            <p>4 fl oz guacamole</p>
                            <p>1 fl oz Ken&rsquo;s Lime Vinaigrette Dressing & Marinade (KE2018)</p>
                            <p>1 tsp cilantro, chopped</p>
                            <p>1 tsp jalapeno, minced</p>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper secondary avocado-ranch">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We&rsquo;d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button" data-gtm-green-goddess-contact-us-link>contact us</Link>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(AvocadoRanch);
