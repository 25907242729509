import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import FeedbackWrapper from '../../components/FeedbackWrapper';
import SourceDataRecipes from '../../components/sourceDataItems/Recipes';
import SourceDataProducts from '../../components/sourceDataItems/Products';

import './seafood.scss';

import seafood from '../../assets/img/seafood-board.png';
import set1 from '../../assets/img/seafood-set1.png';
import set2 from '../../assets/img/seafood-set2.png';
import product1 from '../../assets/img/products/ke0666.png';
import product2 from '../../assets/img/products/ke0851.png';

import seafoodInsightsPDF from '../../assets/pdf/Kens_Seafood_Technomics.pdf';
import seafoodBrochurePDF from '../../assets/pdf/Kens_Seafood_RecipePOS.pdf';

const products = [
    { _id: '5bd97315a842a126f551711b', displayOrder: 1 }, // Boom Boom Sauce
    { _id: '5bd97315a842a126f551706a', displayOrder: 2 }, // Tartar Sauce
    { _id: '5bd97315a842a126f551709c', displayOrder: 3 }, // Signature Remoulade Sauce
    { _id: '5bd97315a842a126f551711f', displayOrder: 4 }  // Lemon Vinaigrette
];
const recipes = [
    { _id: '5bf87155285ac8343f6c80ef', displayOrder: 1 }, // Boom Boom Shrimp And Calamari
    { _id: '5bd97312a842a126f5516e74', displayOrder: 2 }, // Grilled Sea Scallops With Lime Vinaigrette
    { _id: '5bd97312a842a126f5516f39', displayOrder: 3 }, // New Orleans Oyster Po’boy Sliders
    { _id: '5bd97312a842a126f5516ee4', displayOrder: 4 }, // Buttermilk Fried Calamari - Inactive
    { _id: '5bd97312a842a126f5516eea', displayOrder: 5 }, // Frito Mix
    { _id: '5c636601a7b08a0011d1c1ae', displayOrder: 6 },  // Lobster Salad Stuffed Potato Skins With Garlic Aioli
    { _id: '5bd97312a842a126f5516f04', displayOrder: 7 },  // Bacon Wrapped Scallops
    { _id: '5bd97312a842a126f5516e88', displayOrder: 8 }  // Take 5 Fish And Chips
];

class Seafood extends Component {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Seafood menu insights</h1>
                    <div className="descr short">Learn what customers are craving, find new recipes and discover sauces that will take your seafood dishes to the next level.</div>
                </div>

                <div className="hero-wrapper seafood"></div>

                <div className="body-wrapper seafood">
                    <div className="feature-wrapper">
                        <img src={seafood} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">48%</div>
                                <div className="descr">of consumers would order an entree salad with seafood</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">25%</div>
                                <div className="descr">YOY growth of aioli across calamari appetizers</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">18%</div>
                                <div className="descr">of fried seafood dishes feature tartar sauce</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper seafood">
                    <h2>Leading sauces paired with fried seafood</h2>
                </div>

                <div className="body-wrapper seafood">
                    <div className="benefits-wrapper">
                        <div className="descr">Tartar Sauce on<span>18%</span>of Menus</div>
                        <img src={product1} alt="" />
                        <div className="descr">Aioli on<span>7%</span>of Menus</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Buttermilk fried calamari with ken’s signature garlic aioli</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Boom boom shrimp and calamari with ken’s boom boom sauce</span>
                        </div>
                        <div className="descr">Chili Sauce on<span>3%</span>of Menus</div>
                        <img src={product2} alt="" />
                        <div className="descr">Cocktail Sauce on<span>7%</span>of Menus</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={seafoodInsightsPDF} data-gtm-download-more-insights-seafood-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <SourceDataRecipes settings={recipes} /> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={seafoodBrochurePDF} data-gtm-download-brochure-insights-seafood-button-link-a download>download seafood brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5bd97314a842a126f5517050" }}} className="button" data-gtm-see-all-insights-seafood-button-link-a>see all seafood recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <SourceDataProducts
                        className="seafood"
                        settings={products}
                        title="Seafood sauces we know you'll love" />
                )}

                <FeedbackWrapper page="seafood" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Seafood);
