import _clone from 'lodash/clone';
import {
    IS_MOBILE
} from '../actions/types';

const initialState = {
    isMobile: false
};

export default (state = initialState, action) => {

    const newState = _clone(state);

    switch (action.type) {
        case IS_MOBILE:
            newState.isMobile = action.payload;
            break;
        default:
            break;
    }

    return newState;
}