import { actionTypes as types } from './constants';

export const submitRegistration = (payload = {}) => ({
    type: types.SUBMIT_PIZZAEXPO_REGISTRATION_START,
    payload,
});

export const completeRegistration = () => ({
    type: types.SUBMIT_PIZZAEXPO_REGISTRATION_SUCCESS,
});

export const failedRegistration = (payload = {}) => ({
    type: types.SUBMIT_PIZZAEXPO_REGISTRATION_FAILED,
    payload,
});

export const clearRegistration = () => ({
    type: types.CLEAR_PIZZAEXPO_REGISTRATION,
});
