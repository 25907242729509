import React, { PureComponent, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import './cups.scss';

import girl from '../../assets/img/cups-girl.jpg';
import dip from '../../assets/img/cups-dipping.jpg';
import taste1 from '../../assets/img/cups-img1.jpg';
import taste2 from '../../assets/img/cups-img2.jpg';
import taste3 from '../../assets/img/cups-img3.jpg';
import taste4 from '../../assets/img/cups-img4.jpg';

import cup1 from '../../assets/img/products/ke2509a5_jalapeno_ranch_cup_thumbnaill.png';
import cup2 from '../../assets/img/products/ke0666a5_tartar_sauce_cup_thumbnail.png';
import cup3 from '../../assets/img/products/ke0636a5_lite_balsamic_olive_oil_vinaigrette_cup_thumbnail.png';
import cup4 from '../../assets/img/products/ke0665a5_kens_bleu_cheese_cup_thumbnail.png';
import cup5 from '../../assets/img/products/ke0740a1_sweet_and_sour_sauce_cup_thumbnail.png';
import cup6 from '../../assets/img/products/ke0777a5_kens_ranch_cup_thumbnail_1.png';
import cup7 from '../../assets/img/products/ke0634a5_kens_honey_mustard_cup_thumbnail.png';

import dip1 from '../../assets/img/packages/dip-cup1.jpg';
import dip2 from '../../assets/img/packages/dip-cup2.jpg';
import dip3 from '../../assets/img/packages/dip-cup3.jpg';
import dip4 from '../../assets/img/packages/dip-cup4.jpg';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

const cupsSlider = [
    {img: cup1, name: "jalapeno ranch", link: "/products/ke2509a5-jalapeño-ranch-dressing"},
    {img: cup2, name: "tartar sauce", link: "/products/ke0666a5-tartar-sauce"},
    {img: cup3, name: "lite balsamic with olive vinaigrette", link: "/products/ke0636a5-lite-balsamic-with-olive-oil-vinaigrette-dressing"},
    {img: cup4, name: "bleu cheese", link: "/products/ke0665a5-bleu-cheese-dressing"},
    {img: cup5, name: "sweet & sour sauce", link: "/products/ke0740a1-sweet-and-sour-sauce"},
    {img: cup6, name: "ranch", link: "/products/ke0777a5-ranch-dressing"},
    {img: cup7, name: "honey mustard", link: "/products/ke0634a5-honey-mustard-dressing"}
];

class Cups extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getCups() {
      return (

          cupsSlider.map((item, idx) => {
              return (
                  <div key={idx}>
                      <Link to={item.link} className="flavor-item">
                          <img src={item.img} alt="" />
                          <span>{item.name}</span>
                      </Link>
                  </div>
              )
          })
      );
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 7,
            slidesToScroll: 7,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <div className="hero-wrapper cups">
                    <h1>The skinny on dipping</h1>
                </div>

                <div className="body-wrapper">
                    <div className="descr short">A chicken tender is a chicken tender is a chicken tender. But dip a chicken tender into jalapeño ranch or honey dijon and you create a unique flavor experience. Uniqueness is the key. Both millennial and Gen Z customers want variety and choice, and dipping sauces fit this demand perfectly.
                    </div>
                </div>

                <div className="body-wrapper cups column secondary">
                    <Slider {...settings}>
                        {this.getCups()}
                    </Slider>
                    <h2>Ken’s cups are built for dipping</h2>
                    <div className="descr short">
                        We have a delicious variety of dipping options. From classics like Ranch to cult favorites like Ken’s Boom Boom Sauce and beyond. And they come in a range of convenient sizes – 1 ounce, 1.5 ounces, 2 ounces and 3 ounces.
                    </div>
                    <Link
                        className="button"
                        to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b66' }}}
                    >See all cups</Link>
                </div>

                <div className="body-wrapper cups">
                    <div className="dipping-wrapper">
                        <div className="dip-item">
                            <img src={dip1} alt="" />
                            <span>Why dip</span>
                        </div>
                        <div className="dip-item">
                            <img src={dip2} alt="" />
                            <span>Dipping<br/> sauces make<br/> traditional<br/> foods seem<br/> new again.</span>
                        </div>
                        <div className="dip-item">
                            <img src={dip3} alt="" />
                            <span>Chefs<br/> don’t have to<br/> come up with<br/> whole new<br/> preparations.</span>
                        </div>
                        <div className="dip-item">
                            <img src={dip4} alt="" />
                            <span>Diners<br/> can try new<br/> flavors without<br/> making a big<br/> commitment.</span>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper pampas">
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={girl} alt="" />
                                <span>70%</span>
                            </div>
                            <div className="descr">of millennials like to dip their pizza (and more) in sauces.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={dip} alt="" />
                                <span>63%</span>
                            </div>
                            <div className="descr">of consumers say dips and condiments are important for adding flavor to foods.</div>
                        </div>
                    </div>
                    <div className="remark">Source: Technomic 2019 Consumer Trend Report</div>
                </div>

                <div className="body-wrapper cups column">
                    <div className="taste-wrapper">
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste1} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste2} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste3} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={taste4} alt="" />
                            </div>
                        </div>
                    </div>
                    <h2>Get in on it. Get a taste.</h2>
                    <div className="descr short">Ken’s cups: More flavor, more varieties, more ways to enjoy.</div>
                    <Link className="button" to="/get-sample">request a sample</Link>
                </div>

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="cups" />
                </Suspense>
            </div>
        );
    }
}

export default Cups;
