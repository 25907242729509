import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Modal from './modal/Modal'
import { triggerGAEvent } from '../helpers/googleTagManager'

import {
    sendEmailWithFiles,
    clearEmailWithFilesData,
} from '../containers/recipes/actions'

class ModalEmailIt extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.emailShowSuccessMessage !==
                this.props.emailShowSuccessMessage &&
            this.props.emailShowSuccessMessage === true
        ) {
            this.setCloseTimer()
        }
    }

    componentWillUnmount() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer)
        }
    }

    setCloseTimer = () => {
        if (this.closeTimer) return
        this.closeTimer = setTimeout(() => {
            this.handleEmailItClose()
            this.closeTimer = null
        }, 4000)
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleEmailItClose = () => {
        this.setState({ email: '' })
        this.props.onClose()
        this.props.clearEmailWithFilesData()
    }

    handleEmailItSubmit = (e) => {
        const { email } = this.state
        const { pdfFilePath, type, from } = this.props
        const attachments = [{ href: pdfFilePath }]
        e.preventDefault()
        this.props.sendEmailWithFiles({
            email,
            attachments,
            type: type.toLowerCase(),
        })
        triggerGAEvent({
            label: 'Email Submit',
            action: 'Button click',
            category: from,
        })
    }

    render() {
        const {
            visible,
            emailSending,
            emailShowSuccessMessage,
            emailSomethingWentWrong,
        } = this.props

        const { email } = this.state

        return (
            <Modal visible={visible} onClose={this.handleEmailItClose}>
                {emailShowSuccessMessage && <h2>Email sent successfully!</h2>}
                {emailSomethingWentWrong && (
                    <h2>Oops! Something went wrong.</h2>
                )}
                {!emailShowSuccessMessage && !emailSomethingWentWrong && (
                    <>
                        <h2>Please provide an email address</h2>
                        <form onSubmit={this.handleEmailItSubmit}>
                            <div className="form-group one-row">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleInputChange}
                                    placeholder="Email address*"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                />
                                <div className="submit">
                                    {emailSending && (
                                        <button disabled>
                                            Sending, please wait...
                                        </button>
                                    )}
                                    {!emailSending && (
                                        <button type="submit">
                                            Send recipe
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    emailSending: state.recipes.emailSending,
    emailShowSuccessMessage: state.recipes.emailShowSuccessMessage,
    emailSomethingWentWrong: state.recipes.emailSomethingWentWrong,
})

export default connect(mapStateToProps, {
    sendEmailWithFiles,
    clearEmailWithFilesData,
})(ModalEmailIt)
