import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import mainReducer from './mainReducer';
import errorReducer from './errorReducer';
import lookupReducer from './lookupReducer';
import productsReducer from '../containers/products/reducer'
import homeReducer from '../containers/home/reducer';
import recipesReducer from '../containers/recipes/reducer';
import plusOneReducer from '../containers/plus-one/reducer';
import contactReducer from '../containers/contact/reducer';
import sampleReducer from '../containers/get-sample/reducer';
import reCaptchaReducer from '../components/re-captcha/reducer';
import searchResultReducer from '../containers/search-result/reducer';
import pizzaExpoReducer from '../containers/pizzaexpo/reducer';

export default (history) =>  combineReducers({
    router: connectRouter(history),
    errors: errorReducer,
    main: mainReducer,
    lookup: lookupReducer,
    products: productsReducer,
    home: homeReducer,
    recipes: recipesReducer,
    sample: sampleReducer,
    plusOnes: plusOneReducer,
    contact: contactReducer,
    captcha: reCaptchaReducer,
    searchResults: searchResultReducer,
    pizzaExpo: pizzaExpoReducer,
});
