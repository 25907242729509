import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';

class PrintPdf extends PureComponent {

    constructor(props) {
        super(props);

        this.state = { numPages: null };

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    render() {
        const item = this.props.selected;
        const { numPages } = this.state;

        return (
            <Document
                file={item.pdfFilePath}
                onLoadSuccess={this.onDocumentLoadSuccess}
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1} scale={1.3}
                        />
                    ),
                )}
            </Document>
        );
    }
}

const mapStateToProps = state => ({
    selected: state.recipes.selected,
    recipeDetails: state.recipes.details
});

export default connect(mapStateToProps)(PrintPdf);
