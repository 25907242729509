import { put, call, takeLatest } from 'redux-saga/effects';
import Qs from 'qs';

import ApiClient from '../../helpers/api'
import { actionTypes as types } from './constants';

const fetchRecipes = function* (action) {
    try {
        const query = Qs.stringify(Object.assign({}, action.filters, action.paging, { sort: action.sort }));
        const apiData = yield call([ApiClient, ApiClient.get], `/api/recipes?${query}`);

        yield put({ type: types.FETCH_RECIPES_SUCCESS, payload: apiData, sort: action.sort });
    } catch (err) {
        console.error('[fetchRecipes] error ->', err);
        yield put({ type: types.FETCH_RECIPES_FAILED, message: err.message });
    }
}

const fetchRecipeDetails = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], `/api/recipes/details/?slug=${action.payload.slug}`);
        yield put({ type: types.FETCH_RECIPE_DETAILS_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[fetchRecipeDetails] error -> ', err);
        yield put({ type: types.FETCH_RECIPE_DETAILS_FAILED, message: err.message });
    }
}

const postFiles = function* (action) {
    try {
        const apiResponse = yield call([ApiClient, ApiClient.post], '/api/recipes/send-files', action.payload);
        yield put({ type: types.SEND_EMAIL_WITH_FILES_SUCCESS, payload: apiResponse });
    }
    catch (err) {
        console.error('[postFiles] ->', JSON.stringify(err));
        yield put({ type: types.SEND_EMAIL_WITH_FILES_FAILED, error: err });
    }
};

const fetchRelatedRecipes = function* (action) {
    try {
        const { payload: { flavors, products, selected } } = action;
        const query = Qs.stringify(Object.assign({}, { flavors, products, selected }));
        const apiData = yield call([ApiClient, ApiClient.get], `/api/recipes/related?${query}`);

        yield put({ type: types.FETCH_RELATED_RECIPES_BY_TYPES_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[fetchRelatedRecipes] error: ', err);
        yield put({ type: types.FETCH_RELATED_RECIPES_BY_TYPES_FAILED, message: err.message });
    }
}

export default [
    takeLatest(types.FETCH_RECIPES_START, fetchRecipes),
    takeLatest(types.SEND_EMAIL_WITH_FILES_START, postFiles),
    takeLatest(types.FETCH_RECIPE_DETAILS_START, fetchRecipeDetails),
    takeLatest(types.FETCH_RELATED_RECIPES_BY_TYPES_START, fetchRelatedRecipes),
];
