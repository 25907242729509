import { takeLatest, put, call } from 'redux-saga/effects'
import Qs from 'qs'

import { actionTypes as types } from './constants'
import { ADD_ERROR } from '../../actions/types'

import ApiClient from '../../helpers/api'

const fetchProducts = function* (action) {
    try {
        const query = Qs.stringify(
            Object.assign({}, action.filters, action.paging)
        )
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/products?${query}`
        )

        yield put({ type: types.FETCH_PRODUCTS_SUCCESS, payload: apiData })
    } catch (err) {
        console.error('[fetchProducts] error: ', err)
        yield put({ type: ADD_ERROR, message: err.message })
    }
}

const fetchAllProducts = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/products/all`
        )
        yield put({ type: types.FETCH_ALL_PRODUCTS_SUCCESS, payload: apiData })
    } catch (err) {
        console.error('[fetchAllProduct] error: ', err)
        yield put({ type: ADD_ERROR, message: err.message })
    }
}

const fetchProductDetails = function* (action) {
    try {
        const search = action.payload.slug
            ? `slug=${action.payload.slug}`
            : `id=${action.payload.id}`
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/products/details/?${search}`
        )
        yield put({
            type: types.FETCH_PRODUCT_DETAIL_SUCCESS,
            payload: apiData,
        })
    } catch (err) {
        console.error('[fetchProductDetails] error: ', err)
        yield put({ type: ADD_ERROR, message: err.message })
    }
}

export default [
    takeLatest(types.FETCH_PRODUCTS_START, fetchProducts),
    takeLatest(types.FETCH_PRODUCT_DETAIL_START, fetchProductDetails),
    takeLatest(types.FETCH_ALL_PRODUCTS_START, fetchAllProducts),
]
