import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './metropolitan.scss';

import packages from '../../assets/img/products-metropolitan.png';
import flavor1 from '../../assets/img/metropolitan-img1.jpg';
import flavor2 from '../../assets/img/metropolitan-img2.jpg';
import flavor3 from '../../assets/img/metropolitan-img3.jpg';
import product1 from '../../assets/img/products/ke1051.png';
import product2 from '../../assets/img/products/ke1055.png';
import product3 from '../../assets/img/products/ke0774.png';

class Metropolitan extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <div className="hero-wrapper metropolitan">
                    <h1>Ken’s Metropolitan Select</h1>
                    <div className="descr">A curated line of fine dressings and sauces for high-end customers with discerning tastes.</div>
                </div>

                <div className="body-wrapper column">
                    <div className="descr">Metropolitan Select are not your average, everyday dressings and sauces.<br/> One taste and you can tell. There’s something different about them.</div>
                    <img className="packages" src={packages} alt="" />
                    <h2 className="short">A little more cheffy</h2>
                    <div className="descr short">Metropolitan Select. A curated line of fine dressings and sauces for customers with discerning tastes, no matter what kind of dining establishment they frequent. From sandwich shops to white tablecloth restaurants.</div>
                    <Link to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517055'}}} className="button">see all metropolitan selects</Link>
                </div>

                <div className="hero-wrapper metropolitan2">
                    <h2 className="no-shadow">Flavor you can see</h2>
                    <div className="descr no-shadow">Crafted with high quality ingredients that appeal to the eye and palate.</div>
                </div>

                <div className="body-wrapper column">
                    <h2>Flavor, amplified</h2>
                    <div className="taste-wrapper">
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={flavor1} alt="" />
                            </div>
                            <div className="taste-content">
                                <p><Link to="/products/kens-metropolitan-select-creamy-buttermilk-ranch">Ken’s Metropolitan Select Creamy Buttermilk Ranch</Link> has a unique flavor profile made with onion, garlic, black pepper and dill, with a mixture of buttermilk and fresh sour cream as a base to give it extra body and complexity.</p>
                                <img src={product1} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={flavor2} alt="" />
                            </div>
                            <div className="taste-content">
                                <p>Our <Link to="/products/kens-metropolitan-select-blue-ribbon-texas-barbecue-sauce">Blue Ribbon Texas Style Barbecue Sauce</Link> isn’t shy when it comes to flavor. Crafted with real sugar and no high-fructose corn syrup, this distinct, full-bodied sauce delivers a combination of sweet and spice with a signature tomato flavor.</p>
                                <img src={product2} alt="" />
                            </div>
                        </div>
                        <div className="taste-block">
                            <div className="taste-img">
                                <img src={flavor3} alt="" />
                            </div>
                            <div className="taste-content">
                                <p><Link to="/products/kens-metropolitan-select-roman-caesar-dressing">Ken’s Roman Caesar</Link> delivers a strong, yet perfectly balanced flavor. It is made with a hearty blend of anchovy, garlic, cheese and pepper alongside a hint of vinegar. This full-bodied and tangy dressing is truly a “Caesar lover’s Caesar.”</p>
                                <img src={product3} alt="" />
                            </div>
                        </div>
                    </div>
                    <Link to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517055'}}} className="button">see all metropolitan selects</Link>
                </div>

                <div className="body-wrapper">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button">contact us</Link>
                    </div>
                </div>
            </div>
        );
    }
}


export default Metropolitan;
