import { takeLatest, put, call } from 'redux-saga/effects';
import Qs from 'qs';

import { actionTypes as types } from './constants';
import { ADD_ERROR } from '../../actions/types'

import ApiClient from '../../helpers/api';

const fetchPlusOnes = function* (action) {
    try {
        const query = Qs.stringify(Object.assign({}, action.filters, action.paging));
        const apiData = yield call([ApiClient, ApiClient.get], `/api/plusones?${query}`);

        yield put({ type: types.FETCH_PLUS_ONES_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[fetchPlusOnes] error ->', err);
        yield put({ type: ADD_ERROR, message: err.message });
    }
};

const fetchProductsFilter = function* () {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], "/api/plusones/products-filter");

        yield put({ type: types.FETCH_PLUS_ONES_PRODUCTS_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[fetchProductsFilter] error ->', err);
        yield put({ type: ADD_ERROR, message: err.message });
    }
};

export default [
    takeLatest(types.FETCH_PLUS_ONES_START, fetchPlusOnes),
    takeLatest(types.FETCH_PLUS_ONES_PRODUCTS_START, fetchProductsFilter),
];
