import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    products: {
        list: [],
        loading: false
    },
    list: [],
    selected: null,
    noResults: false,
    filters: {
        flavor: null,
        product: null,
    },
    paging: {
        page: 0,
        limit: 30,
        hasNext: true,
        loading: false,
    },
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_PLUS_ONES_START:
            updatedState.paging.loading = true;
            if (action.resetResults) {
                updatedState.list = [];
            }
            break;
        case types.FETCH_PLUS_ONES_SUCCESS:
            updatedState.list = updatedState.list.concat(action.payload.data.plusOnes);
            updatedState.paging = action.payload.data.paging;
            updatedState.noResults = !updatedState.list.length;
            updatedState.paging.loading = false;
            break;
        case types.FETCH_PLUS_ONES_FAILED:
            updatedState.paging.loading = false;
            break;
        case types.FETCH_PLUS_ONES_PRODUCTS_START:
            updatedState.products.loading = true;
            break;
        case types.FETCH_PLUS_ONES_PRODUCTS_SUCCESS:
            updatedState.products.list = action.payload.data || [];
            updatedState.products.loading = false;
            break;
        case types.FETCH_PLUS_ONES_PRODUCTS_FAILED:
            updatedState.products.loading = false;
            break;
        case types.UPDATE_PLUS_ONE_FILTERS:
            updatedState.filters = action.payload;
            break;
        case types.PLUS_ONE_SELECTED:
            updatedState.selected = action.plusOne;
            break;
        default:
            break;
    }

    return updatedState;
}
