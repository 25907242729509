export const events = {
    custom: 'CustomEvent',
}

export const submitSampleRequestFormEvent = {
    event: events.custom,
    eventCategory: 'Request Sample Form',
    eventAction: 'Submit',
    eventLabel: '',
    eventValue: '',
}

export const submitSampleRequestConsumerEvent = {
    event: events.custom,
    eventCategory: 'Request Sample Form Consumer',
    eventAction: 'Submit',
    eventLabel: '',
    eventValue: '',
}

export const submitContactUsFormEvent = {
    event: events.custom,
    eventCategory: 'Contact Us',
    eventAction: 'Submit',
    eventLabel: 'Form Submitted',
    eventValue: '',
}

export const clickProductLinkEvent = {
    event: events.custom,
    eventCategory: 'Product Link',
    eventAction: 'Click',
    eventLabel: '',
    eventValue: '',
    // setup from callback
    lastProductLinkClicked: '',
}
