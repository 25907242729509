import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

const cookiesName = `_${process.env.REACT_APP_COOKIES_PREFIX}_accept_cookies`

const CookiesNotificationBanner = () => {
    const [cookies, setCookie] = useCookies()

    const [visible, setVisible] = useState(!cookies[cookiesName])

    const onSubmitHandler = () => {
        setCookie(cookiesName, '1', { path: '/', maxAge: 3.154e7 }) // 1 year in seconds
        setVisible(false)
    }

    return visible ? (
        <div className="disclaimer">
            <span>
                We use cookies to analyze website traffic and optimize your
                website experience. By accepting our use of cookies, your data
                will be aggregated with all other user data.
            </span>
            <button onClick={onSubmitHandler}>accept</button>
        </div>
    ) : null
}

export default CookiesNotificationBanner
