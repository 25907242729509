import { actionTypes as types } from './constants';

export const fetchProducts = (filters, paging, resetResults) => ({
    type: types.FETCH_PRODUCTS_START,
    filters,
    paging,
    resetResults
});

export const fetchProductDetails = ({ slug, id }) => ({
    type: types.FETCH_PRODUCT_DETAIL_START,
    payload: { slug, id }
});

export const fetchAllProducts = () => ({
  type: types.FETCH_ALL_PRODUCTS_START
});

export const updateFilters = (filters) => ({
    type: types.UPDATE_PRODUCT_FILTERS,
    payload: filters
});

export const setSelectedProduct = (productCode) => ({
    type: types.PRODUCT_DETAIL_SELECTED,
    productCode
});

export const resetDetails = () => ({
    type: types.RESET_PRODUCT_DETAILS
});
