import React from 'react';
import { nfd as NFD } from 'unorm';
import kosherUIcon from '../assets/img/kosher-icon.png';
import glutenIcon from '../assets/img/gluten-free-icon.png';

export const stringNormalizer = (str) => {
    if (str && typeof str === 'string') {
        // for IE11/Edge String.prototype.normalize is `undefined` for that reason we need `unorm` package
        return NFD(decodeURIComponent(str)).replace(/[\u0300-\u036f]/g, '').replace(/\u2019/g, "'");
    }

    return str;
};

export const startCase = (str) => {
    if (str && typeof str === 'string') {
        return str.trim().split(' ').map(
            (w) => w && (w[0].toUpperCase() + w.slice(1))
        ).join(' ');
    }
}

export const prepareMarketingClaims = ({ gluten = "", kosher = "", kosherDairy = "" } = {}) => {
    const kosherAppender = (kosher === 'Yes' && kosherDairy === 'Yes') ? (
        <>Dairy<img src={kosherUIcon} alt="Kosher" /><span>D</span></>
    ) : (
        <img src={kosherUIcon} alt="Kosher" />
    );

    return (
        <div className="marketing-claims">
            <div className="kosher">
                <span>Kosher {kosherAppender}</span>
                <span>{kosher || 'No'}</span>
            </div>
            <div>
                <span>Gluten Free <img src={glutenIcon} alt="Gluten" /></span>
                <span>{gluten}</span>
            </div>
        </div>
    );
}
