import { actionTypes as types } from './constants';

export const submitFeedback = (message, company, fname, email, phone, city, state, captcha) => ({
    type: types.SUBMIT_CONTACT_US_START,
    payload: {
        message,
        company,
        fname,
        email,
        phone,
        city,
        state,
        captcha
    }
});

export const completeFeedback = () => ({
    type: types.SUBMIT_CONTACT_US_COMPLETED
})
