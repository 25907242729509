import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSearchResults } from '../search-result/actions';

import './green-goddess.scss';

import logo from '../../assets/img/kens-logo.svg';
import woman from '../../assets/img/offers/green-goddess/greengoddess-img1.jpg';
import cafe from '../../assets/img/offers/green-goddess/greengoddess-img2.jpg';
import kitchen from '../../assets/img/offers/green-goddess/greengoddess-img3.jpg';
import salad from '../../assets/img/offers/green-goddess/greengoddess-dish1.png';
import wedge from '../../assets/img/offers/green-goddess/greengoddess-dish2.png';
import pasta from '../../assets/img/offers/green-goddess/greengoddess-dish3.png';
import cucumber from '../../assets/img/offers/green-goddess/cucumber.jpg';
import avocado from '../../assets/img/offers/green-goddess/avocado.jpg';
import garlic from '../../assets/img/offers/green-goddess/garlic.jpg';

import greenGoddessSellSheet from '../../assets/pdf/kens_green_goddess_pos.pdf';

const SourceDataProductDetails = lazy(() => import('./components/SourceDataProductDetails'));

const productId = [ '5dc646a6877c420011694b25' ]; // Ken's Essentials Green Goddess Dressing (KE3425-2)

class GreenGoddess extends PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: productId,
            keys: [ 'products']
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    filterResults(key) {
        const { searchResults } = this.props;
        const entity = searchResults.find((sr) => sr[key]);
        return entity && (key === 'products' ? entity[key][0] : entity[key]);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);
        const product = isDataLoaded && this.filterResults('products');

        return (
            <>
                <div className="hero-wrapper green-goddess">
                    <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                    <h1>Going green never tasted so good.</h1>
                </div>

                <div className="body-wrapper green-goddess">
                    <div className="product-intro">
                        {product && (
                            <img src={product.details.websiteThumbnail} alt={product.details.name} />
                        )}
                        <div>
                            <h2>Ken's Essentials Green Goddess Dressing</h2>
                            <h4>NO Artificial Flavors | NO High-Fructose Corn Syrup | NO Artificial Preservatives</h4>
                            <p>With its creamy buttermilk base and a blend of tarragon, basil and parsley, Ken’s Essentials Green Goddess Dressing delivers cool, refreshing flavor. Well-balanced and bright, it’s an ideal choice for adding depth to dips, freshening up salads or making your favorite ingredients sing.</p>
                            <div className="button-group">
                                <a className="button" href={greenGoddessSellSheet}>download sell sheet</a>
                                {product && (
                                    <>
                                        <Link to={`/products/${product.slug}`} className="button">see more product info</Link>
                                        <a className="button" href={product.details.files.pdf}>view specs</a>
                                    </>
                                )}
                                <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Green Goddess Landing page' }}}>request a sample</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper pampas green-goddess">
                    <h2>Green Goddess is on the rise</h2>
                    <div className="descr">More and more, innovative restaurants are finding ways to incorporate Green Goddess Dressing into their menus, taking advantage of its delicious combination of mayonnaise, tarragon vinegar, anchovies, parsley, chives, tarragon, scallions and garlic. Currently, the dressing is trending at Top 500 chains.</div>
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={woman} alt="" />
                                <span>8.7%</span>
                            </div>
                            <div className="descr">increase in the number of operators featuring Green Goddess Dressing in the last year, according to Technomic menu data.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={cafe} alt="" />
                                <span>33.3%</span>
                            </div>
                            <div className="descr">year-over-year growth shows popularity of Green Goddess Dressing is booming.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={kitchen} alt="" />
                                <span>#13</span>
                            </div>
                            <div className="descr">on the Top 50 Menu Trends to watch.</div>
                        </div>
                    </div>
                    <div className="remark">Sources: Technomic Consumer Report 2019</div>
                </div>

                <div className="body-wrapper green-goddess">
                    <h2>Green Goddess Dressing Recipes</h2>
                    <div className="descr">Have customers seeing green with these bold and flavorful menu ideas.</div>
                    <div className="recipe-wrapper">
                        <Link to="/recipes/green-goddess-potato-salad" className="recipe-item">
                            <span>Green Goddess Potato Salad</span>
                            <img src={salad} alt="Green Goddess Potato Salad" />
                        </Link>
                        <Link to="/recipes/grilled-iceberg-wedges-with-green-goddess-dressing" className="recipe-item">
                            <span>Grilled Iceberg Wedge with Green Goddess Dressing</span>
                            <img src={wedge} alt="Grilled Iceberg Wedge with Green Goddess Dressing" />
                        </Link>
                        <Link to="/recipes/green-goddess-shrimp-pasta-salad" className="recipe-item">
                            <span>Green Goddess Shrimp Pasta Salad</span>
                            <img src={pasta} alt="Green Goddess Shrimp Pasta Salad" />
                        </Link>
                    </div>
                </div>

                <div className="body-wrapper pampas column green-goddess">
                    <h2>Product Information</h2>
                    {isDataLoaded ? <Suspense fallback={null}>
                        <SourceDataProductDetails settings={{
                            size: '2/1 Gallon',
                            weight: '17.34 lbs',
                            storage: 'Refrigerated'
                        }} />
                        </Suspense> : <p>Loading...</p>}
                </div>

                <div className="body-wrapper column green-goddess">
                    <h2>Make it your own</h2>
                    <div className="descr short">Put your own spin on things with these unique Green Goddess recipes, or create one of your own. They're super versatile and always delicious.</div>
                    <div className="make-it-wrapper">
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={cucumber} alt="" /></div>
                            <p><strong>Creamy Cucumber Dill Green Goddess</strong></p>
                            <p>2 cups Ken’s Essentials Green Goddess Dressing</p>
                            <p>1 cup seedless cucumber, finely chopped</p>
                            <p>2 tbsp fresh dill, chopped</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={avocado} alt="" /></div>
                            <p><strong>Avocado Parmesan Green Goddess</strong></p>
                            <p>2 cups Ken’s Essentials Green Goddess Dressing</p>
                            <p>1/3 cup avocado, mashed</p>
                            <p>1/4 cup Parmesan, grated</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs"><img src={garlic} alt="" /></div>
                            <p><strong>Roasted Garlic Green Goddess</strong></p>
                            <p>2 cups Ken’s Essentials Green Goddess Dressing</p>
                            <p>1/4 cup roasted garlic, mashed</p>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper secondary green-goddess">
                    <div className="feedback-wrapper">
                        <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                        <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button" data-gtm-green-goddess-contact-us-link>contact us</Link>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(GreenGoddess);
