import _sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';
import { setSelected as setSelectedPlusOne } from '../search-result/actions';

import './fall.scss';

import logo from '../../assets/img/kens-logo.svg';
import berries from '../../assets/img/offers/fall-flavors/berries.png';
import plate from '../../assets/img/offers/fall-flavors/plate-with-salad.png';
import apple from '../../assets/img/offers/fall-flavors/apple-slices.png';

import lime from '../../assets/img/offers/fall-flavors/cilantro-and-lime-vinaigrette.png';
import pistachio from '../../assets/img/offers/fall-flavors/pistachio-gremolata.png';
import maple from '../../assets/img/offers/fall-flavors/lemon-maple-vinaigrette.png';
import lemon from '../../assets/img/offers/fall-flavors/lemon-caper-aioli.png';

const ModalPlusOne = lazy(() => import('../../components/ModalPlusOne'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f551711d', displayOrder: 1 }, // Apple Cider Vinaigrette
    { _id: '5bd97315a842a126f5517097', displayOrder: 2 }, // Blueberry & Pomegranate Vinaigrette
    { _id: '5bd97315a842a126f551709d', displayOrder: 3 }, // Ken's Signature Garlic Aioli
    { _id: '5bd97315a842a126f551711f', displayOrder: 4 }, // Lemon Vinaigrette
    { _id: '5bd97315a842a126f5517098', displayOrder: 5 }, // Lime Vinaigrette Dressing & Marinade
    { _id: '5bd97315a842a126f551709e', displayOrder: 6 }  // Sherry Shallot Vinaigrette
];
const recipes = [
    { _id: '5bd97312a842a126f5516e8d', displayOrder: 1 }, // Charred Corn Tomato And Avocado Salad
    { _id: '5bd97312a842a126f5516ecb', displayOrder: 2 }, // Balsamic Roasted Acorn Squash
    { _id: '5bd97312a842a126f5516ecc', displayOrder: 3 }, // Roasted beet and citrus salad with ricotta and lemon
    { _id: '5bd97312a842a126f5516ecd', displayOrder: 4 }, // Shaved cauliflower salad
    { _id: '5bd97312a842a126f5516f09', displayOrder: 5 }, // 15 shades of green salad
    { _id: '5bd97312a842a126f5516f0a', displayOrder: 6 }, // Roasted Pear And Spinach Salad
    { _id: '5bd97312a842a126f5516f0b', displayOrder: 7 }, // Tri-Color Potato Salad With Kens Sherry Shallot Vinaigrette
    { _id: '5bd97312a842a126f5516f4b', displayOrder: 8 }  // Fall harvest salad
];
const plusOnes = [
    { _id: '5bd97313a842a126f5516f86', displayOrder: 1, img: lime },      // Cilantro and lime vinaigrette
    { _id: '5bd97313a842a126f5516f87', displayOrder: 2, img: pistachio }, // Pistachio gremolata
    { _id: '5bd97313a842a126f5516ff4', displayOrder: 3, img: maple },     // Lemon maple vinaigrette
    { _id: '5bd97313a842a126f551702c', displayOrder: 4, img: lemon }      // Lemon-caper aioli
]

class Fall extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayPlusOneModal: false
        };

        this.openPlusOneModal = this.openPlusOneModal.bind(this);
        this.closePlusOneModal = this.closePlusOneModal.bind(this);

        this.props.fetchSearchResults({
            ids: [
                ...products.map(p => p._id),
                ...recipes.map(r => r._id),
                ...plusOnes.map(po => po._id)
            ]
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    closePlusOneModal() {
        this.setState({ displayPlusOneModal: false });
    }

    openPlusOneModal() {
        this.setState({ displayPlusOneModal: true });
    }

    handlePlusOneSelection(plusOneId) {
        this.props.setSelectedPlusOne(plusOneId);
        this.openPlusOneModal();
    }

    _parseIngredients(ingredients) {
        const { uoms } = this.props;

        if (ingredients && Array.isArray(ingredients)) {
            return ingredients.map((item, idx) => {
                const weight = item.weight && uoms.find((uom) => uom.id === item.weight);
                return <li key={`plus-one-ingredient-${idx}`}>{item.amount} {weight ? weight.name : item.weight} {item.ingredient}{item.notes && `, ${item.notes}`}</li>;
            });
        }

        return null;
    }

    getFlavors() {
        const response = [];
        const { searchResults } = this.props;
        const entity = searchResults.find((sr) => sr['plusones']);
        const plusOnesEntity = entity && entity['plusones'];

        if (plusOnesEntity) {
            for (const item of plusOnesEntity) {
                const entity = plusOnes.find(po => po._id === item.id);
                response.push({
                    displayOrder: entity.displayOrder,
                    content: (
                        <div onClick={() => this.handlePlusOneSelection(item.id)} className='feature-block' key={item.id}>
                            <span>{item.label}</span>
                            <ul>
                                {this._parseIngredients(item.details.ingredients)}
                            </ul>
                            <img src={entity.img} alt={item.label} />
                        </div>
                    )
                })
            }
        }

        return _sortBy(response, 'displayOrder')
            .map(resp => resp.content);
    }

    render() {
        const { displayPlusOneModal } = this.state;
        const { uoms, loading, searchResults, plusOneSelected } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="hero-wrapper fall">
                    <div>
                        <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                        <h1>Fall in love with fall salads</h1>
                        <div className="descr">Celebrate the season with the flavors that make it great.</div>
                    </div>
                </div>

                <div className="body-wrapper fall">
                    <h2>Stay fresh for fall</h2>
                    <div className="descr short">Consumers expect to find their favorite fall ingredients on seasonal menus.</div>
                    <div className="benefits-wrapper">
                        <div>
                            <div className="descr"><span>65%</span>of consumers say seasonal ingredients improve flavor.</div>
                            <img src={berries} alt="" />
                        </div>
                        <div>
                            <div className="descr"><span>38%</span>younger consumers are willing to pay more for salads described as seasonal.</div>
                            <img src={plate} alt="" />
                        </div>
                        <div>
                            <div className="descr"><span>68%</span>of consumers are more likely to purchase salads with seasonal influences.</div>
                            <img src={apple} alt="" />
                        </div>
                    </div>
                    <div className="remark">Source: Technomic, 2018 Soup & Salad, 2017 Flavor Consumer Trend Reports</div>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Featured fall salad recipes</h2>
                    <div className="descr short">Dig in to fall salads, harvest flavors, and more.</div>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                </div>

                <div className="body-wrapper fall">
                    <h2>Flavor Foundations</h2>
                    <div className="feature-wrapper">
                        {(isDataLoaded && uoms) ? this.getFlavors() : <p>Loading...</p>}
                    </div>
                </div>

                {plusOneSelected && (
                    <Suspense fallback={<p className="loading">Loading...</p>}>
                        <ModalPlusOne
                            hideButtonGroup
                            visible={displayPlusOneModal}
                            onClose={this.closePlusOneModal}
                        />
                    </Suspense>
                )}

                {isDataLoaded && <Suspense fallback={null}>
                    <SourceDataProducts settings={products} />
                </Suspense>}

                <div className="body-wrapper column primary">
                    <h2>Interested? Try it yourself.</h2>
                    <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit - Fall Flavors' }}}>request a sample</Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    uoms: state.lookup.uoms,
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list,
    plusOneSelected: state.searchResults.selected
});

export default connect(mapStateToProps, {
    fetchSearchResults,
    setSelectedPlusOne
})(Fall);
