import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './chicken-sandwiches.scss';

import chickenSandwich from '../../assets/img/sandwich-chicken-board.png';
import set1 from '../../assets/img/sandwich-chicken-set1.png';
import set2 from '../../assets/img/sandwich-chicken-set2.png';
import product1 from '../../assets/img/products/ke3191_2.png';
import product2 from '../../assets/img/products/ke1050.png';

import chickenSandwichInsightsPDF from '../../assets/pdf/Kens_ChickenSandwich_Technomics_v4.pdf';
import chickenSandwichRecipesPDF from '../../assets/pdf/Kens_ChickenSandwiches_Recipes_v4.pdf';
import chickenSandwichFlavorPDF from '../../assets/pdf/KENS_PlusOnes_July_ChickenSandwich_v4.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f55170dc', displayOrder: 1 }, // Thai Satay Sauce
    { _id: '5bd97315a842a126f551709a', displayOrder: 2 }, // Jalapeño Ranch Dressing
    { _id: '5bd97315a842a126f55170e3', displayOrder: 3 }, // Green Chili Aioli
    { _id: '5bd97315a842a126f551711a', displayOrder: 4 }  // Basil Pesto Aioli
];
const recipes = [
    { _id: '5e4323e0e40908001182784c', displayOrder: 1 }, // Maui fried chicken dunk
    { _id: '5dc48e12f2af750012ffcf6c', displayOrder: 2 }, // Chicken po’ boy
    { _id: '5dc49112e84f080012bc270c', displayOrder: 3 }, // Pride of the south chicken biscuit
    { _id: '5dc492c9f2af750012ffcf71', displayOrder: 4 }, // Jalisco pollo torta
    { _id: '5dc493bfe84f080012bc270f', displayOrder: 5 }, // Chiki chiki boom boom
    { _id: '5dc49b96f2af750012ffcf78', displayOrder: 6 }, // Fried chicken parmesan sub
    { _id: '5e594a21b4ae1d0010aa1b6d', displayOrder: 7 }  // Son of a gun
];

class ChickenSandwiches extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Chicken sandwich menu insights</h1>
                    <div className="descr short">Learn what kind of chicken sandwiches are on the rise, discover new recipes and find sauces that will keep customers coming back for more.
                    </div>
                </div>

                <div className="hero-wrapper chickensandwich"></div>

                <div className="body-wrapper chickensandwich">
                    <div className="feature-wrapper">
                        <img src={chickenSandwich} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">#1</div>
                                <div className="descr">Chicken sandwich is #1 on the Trends in New Entrées List</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">66%</div>
                                <div className="descr">of foodservice operators menu sandwiches</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">55%</div>
                                <div className="descr">of foodservice operators offer one or more chicken sandwiches</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper chickensandwich">
                    <h2>Popular sauces keep chicken sandwiches on the rise</h2>
                </div>

                <div className="body-wrapper chickensandwich">
                    <div className="benefits-wrapper">
                        <div className="descr">Garlic Aioli<span>20%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Remoulade Sauce<span>34%</span>Growth</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Chicken Po’ boy with ken’s signature remoulade sauce</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Buttermilk brined hot chicken sandwich with ken’s signature chipotle mayonnaise sauce</span>
                        </div>
                        <div className="descr">Chipotle Mayonnaise<span>10.5%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Blue Cheese<span>50%</span>Growth</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={chickenSandwichInsightsPDF} data-gtm-download-more-insights-chickenSandwich-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={chickenSandwichRecipesPDF} data-gtm-see-all-insights-chickensandwich-button-link-a download>download chicken sandwich brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { courses: "5ddc63536a636e0012924f3c" }}} className="button" data-gtm-see-all-insights-chickensandwich-button-link-a>see all sandwich recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts settings={products} className="chickensandwich" title="Chicken sandwich sauces we know you'll love">
                            <a className="button" href={chickenSandwichFlavorPDF}>Make it your own ideas</a>
                        </SourceDataProducts>
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="chicken-sandwiches" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(ChickenSandwiches);
