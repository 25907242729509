import React, { PureComponent } from 'react';
import { Document, Page } from 'react-pdf';

class Pdf extends PureComponent {

    render() {
        const { path } = this.props;

        return (
            <Document file={path}>
                <Page pageNumber={1} scale={1.5} />
            </Document>
        );
    }
}

export default Pdf;
