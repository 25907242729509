import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    all: [],
    list: [],
    details: [],
    selected: null,
    noResults: false,
    filters: {
        flavor: null,
        package: null,
        product: null,
        attribute: null,
        productLines: null,
    },
    paging: {
        page: 0,
        limit: 30,
        hasNext: true,
        loading: false,
    },
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_PRODUCTS_START:
        case types.FETCH_PRODUCT_DETAIL_START:
            updatedState.paging.loading = true;
            if (action.resetResults) {
                updatedState.list = [];
            }
            break;
        case types.FETCH_PRODUCTS_SUCCESS:
            updatedState.list = updatedState.list.concat(action.payload.data.products);
            updatedState.paging = action.payload.data.paging;
            updatedState.noResults = !updatedState.list.length;
            updatedState.paging.loading = false;
            break;
        case types.FETCH_PRODUCT_DETAIL_SUCCESS:
            updatedState.details = action.payload.data || [];
            updatedState.paging.loading = false;
            break;
        case types.FETCH_PRODUCTS_FAILED:
        case types.FETCH_ALL_PRODUCTS_FAILED:
            updatedState.paging.loading = false;
            break;
        case types.UPDATE_PRODUCT_FILTERS:
            updatedState.filters = action.payload;
            break;
        case types.PRODUCT_DETAIL_SELECTED:
            const match = updatedState.details.find((d => {
                return d.productCode === action.productCode;
            }));
            updatedState.selected = match;
            break;
        case types.RESET_PRODUCT_DETAILS:
            updatedState.details = [];
            updatedState.selected = null;
            break;
        case types.FETCH_ALL_PRODUCTS_SUCCESS:
            updatedState.all = action.payload.data || [];
            updatedState.paging.loading = false;
            break;
        default:
            break;
    }

    return updatedState;
}
