import { takeLatest, put, call } from 'redux-saga/effects';
import GTM, { submitContactUsFormEvent } from '../../helpers/gtm';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const submitFeedback = function* (action) {
    try {

        const apiResponse = yield ApiClient.post('/api/contactus', action.payload)

        yield put({ type: types.SUBMIT_CONTACT_US_SUCCESS, payload: apiResponse });
        yield call([GTM, GTM.pushEvent], submitContactUsFormEvent)
    }
    catch (err) {
        yield put({ type: types.SUBMIT_CONTACT_US_FAILED, error: err });
    }
};

export default [
    takeLatest(types.SUBMIT_CONTACT_US_START, submitFeedback)
];
