export const actionTypes = {
    FETCH_PRODUCTS_START: 'FETCH_PRODUCTS_START',
    FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAILED: 'FETCH_PRODUCTS_FAILED',

    UPDATE_PRODUCT_FILTERS: 'UPDATE_PRODUCT_FILTERS',

    FETCH_PRODUCT_DETAIL_START: 'FETCH_PRODUCT_DETAIL_START',
    FETCH_PRODUCT_DETAIL_SUCCESS: 'FETCH_PRODUCT_DETAIL_SUCCESS',
    FETCH_PRODUCT_DETAIL_FAILED: 'FETCH_PRODUCT_DETAIL_FAILED',
    PRODUCT_DETAIL_SELECTED: 'PRODUCT_DETAIL_SELECTED',

    FETCH_ALL_PRODUCTS_START: 'FETCH_ALL_PRODUCTS_START',
    FETCH_ALL_PRODUCTS_SUCCESS: 'FETCH_ALL_PRODUCTS_SUCCESS',
    FETCH_ALL_PRODUCTS_FAILED: 'FETCH_ALL_PRODUCTS_FAILED',

    RESET_PRODUCT_DETAILS: 'RESET_PRODUCT_DETAILS',

    FETCH_RELATED_RECIPES_BY_PRODUCT_START: 'FETCH_RELATED_RECIPES_BY_PRODUCT_START',
    FETCH_RELATED_RECIPES_BY_PRODUCT_SUCCESS: 'FETCH_RELATED_RECIPES_BY_PRODUCT_SUCCESS',
    FETCH_RELATED_RECIPES_BY_PRODUCT_FAILED: 'FETCH_RELATED_RECIPES_BY_PRODUCT_FAILED',
};
