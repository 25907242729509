import {
    LOAD_LOOKUP_DATA
} from './types';

export function loadLookupData() {

    return {
        type: LOAD_LOOKUP_DATA
    }
}
