import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _Get from 'lodash/get';
import _Clone from 'lodash/clone';
import Select, { components } from 'react-select';

class Filters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            isSearching: {
                flavor: false,
                product: false
            },
            groupedOptions: null
        };
    }

    componentDidUpdate() {
        const { groupedOptions } = this.state;
        const { flavors, products } = this.props;
        const isFiltersFilled = flavors.length && products.length;
        const productIsChanged = groupedOptions && products && products.length && products !== groupedOptions[1].options;

        if (!groupedOptions && isFiltersFilled)
            this.fillState();

        if (productIsChanged)
            this.fillState();
    }

    fillState() {
        const { flavors, products } = this.props;
        this.setState({
            groupedOptions: [
                { label: 'Flavor', options: flavors, type: 'flavor' },
                { label: 'Product', options: products,  type: 'product' },
            ]
        });
    }

    handleMultiSelectChange(type, options) {
        const { selections } = this.props;
        if (selections) {
            const cloned = _Clone(selections);
            cloned[type] = options;
            this.props.onFilterChange(type, cloned);
        }
    }

    handleGroupMultiSelectChange(option) {
        const { groupedOptions } = this.state;
        const multipleTypes = {};

        if (!!option.length)
            for (let opt of option) {
                const filtered = groupedOptions.filter((ent) =>
                    !!ent.options.filter((o) => o.id === opt.id).length ? ent.type : null);
                const type = filtered && filtered.length && filtered[0].type;

                if (multipleTypes[type]) multipleTypes[type].push(opt);
                else multipleTypes[type] = [opt];

                if (type)
                    this.props.onFilterChange(type, multipleTypes);
            }
        else
            this.props.onFilterClear();
    }

    renderMultiValue(isGrouped) {
        const [multipleTypes, singleTypes] = [[], {}];
        const { selections } = this.props;
        const { groupedOptions } = this.state;
        const processEntity = (type, item) => {
            if (item) {
                if (isGrouped) {
                    multipleTypes.push(item);
                } else {
                    if (singleTypes[type] && singleTypes[type].length) {
                        singleTypes[type].push(item);
                    } else {
                        singleTypes[type] = [item];
                    }
                }
            }
        }

        if (selections && groupedOptions) {
            for (let ent of Object.keys(selections)) {
                const selection = selections[ent];
                if (selection) {
                    if (typeof selection === 'object') {
                        if (selection.length) {
                            selection.forEach(e => groupedOptions.forEach(group => {
                                const item = group.options.find(opt => (opt.id === e || opt._id === e));
                                processEntity(ent, item);
                            }));
                        } else {
                            Object.keys(selection).forEach(k => groupedOptions.forEach(group => {
                                const item = group.options.find((opt) => (opt.id === k || opt._id === k));
                                processEntity(ent, item);
                            }));
                        }
                    } else {
                        groupedOptions.forEach((group) => {
                            const item = group.options.find((opt) => opt.id === selection || opt._id === selection);
                            processEntity(ent, item);
                        });
                    }
                }
            }
        }

        return isGrouped ? multipleTypes : singleTypes;
    }

    handleSearchChange(type, value) {
        this.setState(prevState => ({
            isSearching: { ...prevState.isSearching, [type]: !!value }
        }));
    }

    render() {
        let desktopFilters = null;
        let mobileFilters = null;

        const { isSearching, groupedOptions } = this.state;
        const CustomOptions = props => {
            return (
                <>
                    <components.Menu {...props}>
                        {props.children}
                    </components.Menu>
                </>
            );
        };
        const { flavors, products, isMobile } = this.props;

        if (!isMobile) {
            const multiValue = this.renderMultiValue();
            const getAdditionalCls = (type) => isSearching[type] ? 'hidden' : '';
            const generateValues = type =>
                _Get(multiValue, type, []).map((v,i) => <span key={`${type}-value-${i}`}>{v.name}</span>);

            desktopFilters = <div>
                <div className="select-wraper">
                    <div className="title">Flavor</div>
                    <Select
                        isMulti
                        isClearable
                        placeholder=""
                        options={flavors}
                        isSearchable={false}
                        className="select-big"
                        classNamePrefix="select"
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name}
                        value={_Get(multiValue, 'flavor')}
                        onChange={opt => this.handleMultiSelectChange('flavor', opt)}
                    />
                    <div className="values">{generateValues('flavor')}</div>
                </div>
                or
                <div className="select-wraper">
                    <div className="title">
                        <span className={getAdditionalCls('product')}>Product</span>
                    </div>
                    <Select
                        isMulti
                        isClearable
                        isSearchable
                        placeholder=""
                        className="select-big"
                        classNamePrefix="select"
                        onBlurResetsInput={false}
                        getOptionValue={i => i.id}
                        onSelectResetsInput={false}
                        value={_Get(multiValue, 'product')}
                        onChange={opt => this.handleMultiSelectChange('product', opt)}
                        onInputChange={value => this.handleSearchChange('product', value)}
                        getOptionLabel={i => `${i.name || ''} ${i.code ? `[${i.code}]` : ''}`}
                        options={products.length ? products : [{ name: 'Loading...', value: 0 }]}
                    />
                    <div className="values">{generateValues('product')}</div>
                </div>
            </div>;
        }

        if (isMobile) {
            const multiValue = this.renderMultiValue(true);
            mobileFilters = <div className="select-wraper mobile">
                <div className="title">Find your own recipe</div>
                <Select
                    isMulti
                    isClearable
                    placeholder=""
                    value={multiValue}
                    isSearchable={false}
                    className="select-big"
                    classNamePrefix="select"
                    getOptionValue={i => i.id}
                    hideSelectedOptions={false}
                    options={groupedOptions || []}
                    components={{ CustomOptions }}
                    onChange={opt => this.handleGroupMultiSelectChange(opt)}
                    getOptionLabel={i => `${i.name || ''} ${i.code ? `[${i.code}]` : ''}`}
                />
                <div className="values">{
                    multiValue.map((v,i) => <span key={`multi-value-${i}`}>{v.name}</span>)
                }</div>
            </div>;
        }

        return (
            <div className="filters">
                {desktopFilters}
                {mobileFilters}
            </div>
        );
    }
}

Filters.defaultProps = {
    flavors: [],
    products: [],
}

const filterList = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}));

Filters.propTypes = {
    flavors: filterList,
    products: filterList,
    onFilterChange: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile
});

export default withRouter(connect(mapStateToProps)(Filters));
