import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './alert.scss';

class Alert extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showAlert: true
        };

        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidMount() {
        const sessionCheck = typeof sessionStorage === 'object' &&
            sessionStorage.getItem('hideCovidAlert');

        if (sessionCheck) {
            this.setState({ showAlert: false });
        }
    }

    closeAlert() {

        if (typeof sessionStorage === 'object') {
            sessionStorage.setItem('hideCovidAlert', true);
        }

        this.setState({ showAlert: false });
    }

    render() {

        return (

          this.state.showAlert &&
            <div className="alert-body">
                <span>With the growing impact of the coronavirus, customers remain our priority.</span>
                <Link to="/coronavirus" className="button">Important Updates</Link>
                <button className="alert-close" onClick={this.closeAlert} />
            </div>
        );
    }
}

export default Alert;
