import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// function gtag() {
//     if (window.dataLayer) {
//         console.log('pushing gtag: ', arguments);
//         window.dataLayer.push(arguments);
//     }
// }

// history.listen((loc) => {
//     console.log('send pageview: ', loc.pathname + loc.search);
//     gtag('config', 'GA_MEASUREMENT_ID', {'page_path': `${loc.pathname}${loc.search}`});
// })

export default history;