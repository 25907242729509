import { actionTypes as types } from './constants';

export const fetchSearchResults = (payload) => ({
    type: types.FETCH_SEARCH_RESULTS_START,
    payload
});

export const setSelected = (itemID) => ({
    type: types.ON_SELECTED,
    itemID
});
