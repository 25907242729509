import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { prepareGTMDataset } from '../helpers/googleTagManager';

class FeedbackWrapper extends PureComponent {

    render() {

        const dataset = `data-gtm-${this.props.page}-contact-us-link`;

        return (
            <div className="body-wrapper">
                <div className="feedback-wrapper">
                    <div><h3>Any other questions?</h3> We’d love to hear from you.</div>
                    <Link to={{ pathname: '/contact', state: { gaFrom: 'Contact Us Button Click' }}} className="button" {...prepareGTMDataset(dataset)}>contact us</Link>
                </div>
            </div>
        );
    }
}

export default FeedbackWrapper;
