import { takeLatest, put } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const submitRegistrationSaga = function* (action) {
    try {
        yield ApiClient.post('/api/pizzaexpo', action.payload);
        yield put({ type: types.SUBMIT_PIZZAEXPO_REGISTRATION_SUCCESS });
    } catch (error) {
        yield put({
            type: types.SUBMIT_PIZZAEXPO_REGISTRATION_FAILED,
            error: error.message || 'Oops, somehing went wrong!',
        });
    }
};

export default [
    takeLatest(types.SUBMIT_PIZZAEXPO_REGISTRATION_START, submitRegistrationSaga)
];
