import _clone from 'lodash/clone';
import _uniqBy from 'lodash/uniqBy';
import _orderBy from 'lodash/orderBy';
import _sample from 'lodash/sample';
import { actionTypes as types } from './constants';
import { getListSortOrder, updateListByOrder } from './helpers';

const initialState = {
    sortParameter: _sample(['timeCreated', '-timeCreated', 'timeModified', '-timeModified', 'timeArchived', '-timeArchived', 'timePromoted', '-timePromoted', '_id', '-_id']),
    sortOrder: {
        sortParameter: '',
        order: []
    },
    list: [],
    details: [],
    selected: null,
    dressingInfo: [],
    noResults: false,
    relatedRecipes: {
        byFlavor: [],
        byProduct: [],
    },
    filters: {
        flavor: null,
        dishes: null,
        courses: null,
        ingredients: null,
    },
    paging: {
        page: 0,
        limit: 30,
        hasNext: true,
        loading: false,
    },
    searchResults: null,
    emailSending: false,
    relatedRecipesLoading: false,
    emailShowSuccessMessage: false,
    emailSomethingWentWrong: false,
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.UPDATE_RECIPES_FILTERS:
            updatedState.filters = action.payload;
            break;
        case types.FETCH_RECIPES_START:
            updatedState.paging.loading = true;
            if (action.resetResults) {
                updatedState.list = [];
            }
            break;
        case types.FETCH_RECIPES_SUCCESS:
            updatedState.sortOrder = getListSortOrder({
                sortOrder: updatedState.sortOrder,
                sortParameter: action.sort,
                data: action.payload.data.recipes
            });
            updatedState.list = updateListByOrder({
                list: updatedState.list,
                data:  action.payload.data.recipes,
                sortOrder: updatedState.sortOrder,
            });
            updatedState.noResults = !updatedState.list.length;
            updatedState.paging = action.payload.data.paging;
            updatedState.paging.loading = false;
            break;
        case types.FETCH_RECIPES_FAILED:
            updatedState.paging.loading = false;
            break;
        case types.FETCH_RECIPE_DETAILS_START:
            updatedState.details = [];
            updatedState.paging.loading = true;
            break;
        case types.FETCH_RECIPE_DETAILS_SUCCESS:
            updatedState.details = action.payload.data || [];
            updatedState.paging.loading = false;
            break;
        case types.FETCH_RECIPE_DETAILS_FAILED:
            updatedState.paging.loading = false;
            break;
        case types.RECIPE_DETAILS_SELECTED:
            const match = updatedState.details.find((d => {
                return d.id === action.recipeID;
            }));

            updatedState.selected = match;

            if (match) {
                const dsi = [];
                const { subRecipes, related: { products, plusOnes } } = match;
                const fillDressingInfo = ({
                    id, url, code, isKFS, isKogi, title, image
                }) => dsi.push({
                    id, url, code, isKFS, isKogi, title,
                    image, name: `${title} (${code})`
                });

                if (products && Array.isArray(products) && products.length) {
                    products.forEach(fillDressingInfo);
                }

                if (subRecipes && subRecipes.length) {

                    subRecipes.forEach((sub) => {
                        const { related: { products: subProducts } } = sub;

                        if (subProducts && subProducts.length) {
                            subProducts.forEach(fillDressingInfo);
                        }
                    });
                }

                if (plusOnes && Array.isArray(plusOnes) && plusOnes.length) {

                    plusOnes.forEach((plusOne) => {
                        const { related: { products: plusOneProducts } } = plusOne;

                        if (plusOneProducts && plusOneProducts.length) {
                            plusOneProducts.forEach(fillDressingInfo);
                        }
                    });
                }

                updatedState.dressingInfo = _orderBy(
                    _uniqBy(dsi, 'id'),
                    ['isKFS'],
                    ['desc']
                );
            }

            break;
        case types.RESET_RECIPE_DETAILS:
            updatedState.details = [];
            updatedState.selected = null;
            break;
        case types.SEND_EMAIL_WITH_FILES_START:
            updatedState.emailSending = true;
            break;
        case types.SEND_EMAIL_WITH_FILES_SUCCESS:
            updatedState.emailSending = false;
            updatedState.emailShowSuccessMessage = true;
            break;
        case types.SEND_EMAIL_WITH_FILES_FAILED:
            updatedState.emailSending = false;
            if (action.error && !action.error.errors)
                updatedState.emailSomethingWentWrong = true;
            break;
        case types.SEND_EMAIL_WITH_FILES_CLEAR:
            updatedState.emailShowSuccessMessage = false;
            updatedState.emailSomethingWentWrong = false;
            break;
        case types.FETCH_RELATED_RECIPES_BY_TYPES_START:
            updatedState.relatedRecipesLoading = true;
            break;
        case types.FETCH_RELATED_RECIPES_BY_TYPES_SUCCESS:
            updatedState.relatedRecipes = action.payload.data;
            updatedState.relatedRecipesLoading = false;
            break;
        case types.FETCH_RELATED_RECIPES_BY_TYPES_FAILED:
            updatedState.relatedRecipesLoading = false;
            break;
        case types.RESET_RELATED_RECIPES:
            updatedState.relatedRecipes = {};
            break;
        default:
            break;
    }

    return updatedState;
}
