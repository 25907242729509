import { createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState={}, history) => {
    const middleware = [sagaMiddleware, routerMiddleware(history)];

    let store;
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        store = createStore(
            rootReducer(history),
            initialState,
            compose(
                applyMiddleware(...middleware),
                window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
            )
        );
    } else {
        store = createStore(
            rootReducer(history),
            initialState,
            compose(
                applyMiddleware(...middleware)
            )
        );
    }

    sagaMiddleware.run(sagas);
    return store;
};

export default configureStore;
