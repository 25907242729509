import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './wings.scss';

import wings from '../../assets/img/wings-board.png';
import set1 from '../../assets/img/wings-set1.png';
import set2 from '../../assets/img/wings-set2.png';

import wingsInsightsPDF from '../../assets/pdf/Kens_wings_TechnomicSellSheet_v5.pdf';
import wingsRecipesPDF from '../../assets/pdf/Kens_Wings_RecipeSellSheet_v5.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f5517125', displayOrder: 1 }, // spicy buffalo wing sauce
    { _id: '5bd97315a842a126f55170b7', displayOrder: 2 }, // cannonball barbecue sauce
    { _id: '5bd97315a842a126f55170fd', displayOrder: 3 }, // honey mustard sauce
    { _id: '5bd97315a842a126f551711f', displayOrder: 4 }  // lemon vinaigrette
];
const recipes = [
    { _id: '5bd97312a842a126f5516e6b', displayOrder: 1 }, // Charred lemon grilled wings
    { _id: '5cfecd3551089e0010c69ff8', displayOrder: 2 }, // Thai satay chicken wings
    { _id: '5bd97312a842a126f5516e9a', displayOrder: 3 }, // Texas voodoo wings
    { _id: '5d9207aa6b0a410012435cc8', displayOrder: 4 }, // Boom boom wings
    { _id: '5d83de6fc8ee390011ea01e0', displayOrder: 5 }  // Buffa-que wings
];

class Wings extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Wings menu insights</h1>
                    <div className="descr short">Learn which wing flavors are gaining ground, find new recipes and discover sauces that can take your wings to new heights.
                    </div>
                </div>

                <div className="hero-wrapper wings"></div>

                <div className="body-wrapper wings">
                    <div className="feature-wrapper">
                        <img src={wings} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">50%</div>
                                <div className="descr">of consumers order boneless wings as an appetizer occasionally</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">48%</div>
                                <div className="descr">higher purchase intent when wings are paired with blue cheese</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">22%</div>
                                <div className="descr">of consumers would be interested in trying chicken with new flavors</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper wings">
                    <h2>The popularity of bold, spicy flavors is heating up</h2>
                </div>

                <div className="body-wrapper wings">
                    <div className="benefits-wrapper">
                        <div className="descr"><span>42%</span>would order tangy flavors on chicken</div>
                        <div className="descr"><span>34%</span>would order ethnic flavors on chicken dishes</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>THAI SATAY CHICKEN WINGS WITH KEN’S THAI SATAY SAUCE</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>TEXAS VOODOO WINGS WITH KEN’S JALAPEño ranch dressing</span>
                        </div>
                        <div className="descr"><span>41%</span>would order spicy flavors on chicken</div>
                        <div className="descr"><span>28%</span>of consumers eat more fried chicken with spicy flavors now than they were two years ago</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={wingsInsightsPDF} data-gtm-download-more-insights-wings-button-link-a download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={wingsRecipesPDF} data-gtm-see-all-insights-wings-button-link-a download>download wing brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5bd97314a842a126f551704e" }}} className="button" data-gtm-see-all-insights-wings-button-link-a>see all wing recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts
                            className="wings"
                            settings={products}
                            title="Wing sauces we know you'll love" />
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="wings" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Wings);
