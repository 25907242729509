import Qs from 'qs';
import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const fetchSearchResults = function* (action) {
    try {
        const query = Qs.stringify(Object.assign({}, action.payload));
        const apiData = yield call([ApiClient, ApiClient.get], `/api/search?${query}`);
        yield put({ type: types.FETCH_SEARCH_RESULTS_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[fetchSearchResults] error: ', JSON.stringify(err));
        yield put({ type: types.FETCH_SEARCH_RESULTS_FAILED, message: err.message });
    }
};

export default [
    takeLatest(types.FETCH_SEARCH_RESULTS_START, fetchSearchResults)
];
