import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    sending: false,
    showSuccessModal: false
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.SUBMIT_CONTACT_US_START:
            updatedState.sending = true;
            updatedState.showSuccessModal = false;
            break;
        case types.SUBMIT_CONTACT_US_COMPLETED:
            updatedState.showSuccessModal = false;
            break;
        case types.SUBMIT_CONTACT_US_SUCCESS:
            updatedState.sending = false;
            updatedState.showSuccessModal = true;
            break;
        default:
            break;
    }

    return updatedState;
}
