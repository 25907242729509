import Slider from 'react-slick';
import _sortBy from 'lodash/sortBy';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class SourceDataProducts extends PureComponent {

    render() {
        const response = [];
        const { searchResults, title, settings, className, children } = this.props;
        const entity = searchResults.find((sr) => sr['products']);
        const productsEntity = entity && entity['products'];
        const additionalCls = className || 'pampas';

        if (productsEntity) {
            for (const item of productsEntity) {
                const settingEnt = settings.find(({ _id }) => _id === item.id);

                if (settingEnt) {
                    response.push({
                        displayOrder: settingEnt.displayOrder,
                        content: (
                            <div key={item.id}>
                                <Link to={`/products/${item.slug}`} className="flavor-item">
                                    <img src={item.details.websiteThumbnail} alt={item.details.name} />
                                    <span>{item.details.name}</span>
                                </Link>
                            </div>
                        )
                    });
                }
            }

            const sliderSettings = {
                dots: false,
                infinite: true,
                slidesToShow: response.length,
                slidesToScroll: response.length,
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1
                        }
                    }
                ]
            };

            return (
                <div className={`body-wrapper ${additionalCls}`}>
                    <h2>{title || 'Featured Products'}</h2>
                    <Slider {...sliderSettings}>
                        {_sortBy(response, 'displayOrder').map(resp => resp.content)}
                    </Slider>
                    {children}
                </div>
            );
        }

        return null;
    }
}

const mapStateToProps = state => ({
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps)(SourceDataProducts);
