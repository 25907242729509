export const actionTypes = {
    FETCH_RECIPES_START: 'FETCH_RECIPES_START',
    FETCH_RECIPES_SUCCESS: 'FETCH_RECIPES_SUCCESS',
    FETCH_RECIPES_FAILED: 'FETCH_RECIPES_FAILED',

    UPDATE_RECIPES_FILTERS: 'UPDATE_RECIPES_FILTERS',

    RECIPE_DETAILS_SELECTED: 'RECIPE_DETAILS_SELECTED',
    FETCH_RECIPE_DETAILS_START: 'FETCH_RECIPE_DETAILS_START',
    FETCH_RECIPE_DETAILS_SUCCESS: 'FETCH_RECIPE_DETAILS_SUCCESS',
    FETCH_RECIPE_DETAILS_FAILED: 'FETCH_RECIPE_DETAILS_FAILED',

    RESET_RECIPE_DETAILS: 'RESET_RECIPE_DETAILS',

    SEND_EMAIL_WITH_FILES_START: 'SEND_EMAIL_WITH_FILES_START',
    SEND_EMAIL_WITH_FILES_SUCCESS: 'SEND_EMAIL_WITH_FILES_SUCCESS',
    SEND_EMAIL_WITH_FILES_FAILED: 'SEND_EMAIL_WITH_FILES_FAILED',
    SEND_EMAIL_WITH_FILES_CLEAR: 'SEND_EMAIL_WITH_FILES_CLEAR',

    FETCH_RELATED_RECIPES_BY_TYPES_START: 'FETCH_RELATED_RECIPES_BY_TYPES_START',
    FETCH_RELATED_RECIPES_BY_TYPES_SUCCESS: 'FETCH_RELATED_RECIPES_BY_TYPES_SUCCESS',
    FETCH_RELATED_RECIPES_BY_TYPES_FAILED: 'FETCH_RELATED_RECIPES_BY_TYPES_FAILED',
    RESET_RELATED_RECIPES: 'RESET_RELATED_RECIPES',
};
