import { actionTypes as types } from './constants';

export const updateFilters = (filters) => ({
    type: types.UPDATE_RECIPES_FILTERS,
    payload: filters
});

export const fetchRecipes = (filters, paging, sort, resetResults) => ({
    type: types.FETCH_RECIPES_START,
    filters,
    paging,
    sort,
    resetResults
});

export const fetchRecipeDetails = (slug) => ({
    type: types.FETCH_RECIPE_DETAILS_START,
    payload: {slug}
});

export const setSelectedRecipe = (recipeID) => ({
    type: types.RECIPE_DETAILS_SELECTED,
    recipeID
});

export const resetRecipeDetails = () => ({
    type: types.RESET_RECIPE_DETAILS
});

export const sendEmailWithFiles = (payload) => ({
    type: types.SEND_EMAIL_WITH_FILES_START,
    payload
});

export const clearEmailWithFilesData = () => ({
    type: types.SEND_EMAIL_WITH_FILES_CLEAR
});

export const fetchRelatedRecipes = (payload) => ({
    type: types.FETCH_RELATED_RECIPES_BY_TYPES_START,
    payload
});

export const resetRelatedRecipes = () => ({
    type: types.RESET_RELATED_RECIPES
});
