import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import _IndexOf from 'lodash/indexOf'
import _SortBy from 'lodash/sortBy'
import Select from 'react-select'

import { fetchAllProducts } from '../products/actions'
import { submitFeedback, completeFeedback } from './actions'
import { triggerGAEvent } from '../../helpers/googleTagManager'
import packages from '../../assets/img/products-essentials.png';

import './get-sample.scss'

const Modal = lazy(() => import('../../components/modal/Modal'))
const ReCaptcha = lazy(() => import('../../components/re-captcha/ReCaptcha'))

const business = [
    {
        value: 'distributor',
        name:
            'My business currently buys Ken’s products through a Foodservice Distributor',
    },
    {
        value: 'cashcarry',
        name:
            'My business currently buys Ken’s products through a Cash and Carry Distributor (ie: Restaurant Depot, Jetro, GFS Store, etc.)',
    },
    {
        value: 'existing',
        name:
            'My business is interested in buying Ken’s products through our existing Foodservice Distributor',
    },
    {
        value: 'finding',
        name:
            'My business is interested in buying Ken’s products and finding a Foodservice Distributor',
    },
    {
        value: 'consumer',
        name:
            'I am a consumer or home business interested in buying Ken’s products',
    },
]

const consumers = [
    { value: 'residential', name: 'Residential' },
    { value: 'homeBusiness', name: 'Home Business' },
]

const find = [
    { value: 'magazine', name: 'Trade magazine' },
    { value: 'show', name: 'Trade/distributor show' },
    { value: 'search', name: 'Search engine' },
    { value: 'mouth', name: 'Word of mouth' },
    { value: 'enewsletter', name: 'eNewsletter' },
    { value: 'other', name: 'other' },
]

const locations = [
    { value: 'one', name: '1' },
    { value: 'twoTen', name: '2-10' },
    { value: '11more', name: '11+' },
]

const segments = [
    { value: 'restaurant', name: 'Restaurant' },
    { value: 'k12', name: 'K-12 School' },
    { value: 'college', name: 'College/University' },
    { value: 'healthcare', name: 'Healthcare' },
    { value: 'business', name: 'Business & Industry' },
    { value: 'military', name: 'Military' },
    { value: 'recreation', name: 'Recreation' },
    { value: 'convenience', name: 'Convenience Store' },
    { value: 'supermarket', name: 'Supermarket / Deli' },
    { value: 'vending', name: 'Vending' },
    { value: 'other', name: 'Other' },
]

class GetSample extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: null,
            isProductProcessed: false,
            isGaEventTriggered: false,
            zip: '',
            find: '',
            city: '',
            state: '',
            fname: '',
            lname: '',
            email: '',
            phone: '',
            title: '',
            sample: '',
            company: '',
            address: '',
            segment: '',
            website: '',
            message: '',
            locations: '',
            consumer: '',
            captchaId: '',
            description: '',
            distributor: '',
            captchaValidation: '',
            MAX_TITLE_LENGTH: '50',
            MAX_MSG_LENGTH: '500',
        }

        this.handleInput = this.handleInput.bind(this)
        this.onCaptchaChange = this.onCaptchaChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.recaptchaRef = React.createRef()
    }

    componentDidMount() {
        const { allProducts } = this.props
        window.scrollTo(0, 0)

        if (!allProducts.length) this.props.fetchAllProducts()

        this.maybeUpdateProductSamples()

        this.maybeFireGAEvent()
    }

    componentDidUpdate() {
        const { errors } = this.props
        const { errors: stateErrors } = this.state

        if (!stateErrors && errors && !!errors.length) this.setState({ errors })

        this.maybeUpdateProductSamples()

        this.maybeFireGAEvent()
    }

    consumerCheck() {
        const { consumer, description } = this.state
        const isConsumerSelected =
            description && description.value === 'consumer'
        const isResidentialSelected =
            consumer && consumer.value === 'residential'
        const response = {
            shouldDisplayConsumer: false,
            shouldDisplayMiniForm: false,
        }

        if (isConsumerSelected) {
            response.shouldDisplayConsumer = true

            if (isResidentialSelected) {
                response.shouldDisplayMiniForm = true
            }
        }

        return response
    }

    maybeFireGAEvent() {
        const {
            location: { state },
        } = this.props
        const { isGaEventTriggered } = this.state
        const shouldTrigger = state && state.gaFrom && !isGaEventTriggered

        if (shouldTrigger) {
            triggerGAEvent({
                label: state.gaFrom,
                action: 'Button Click',
                category: 'Sample Requests',
            })
            this.setState({ isGaEventTriggered: true })
        }
    }

    maybeUpdateProductSamples() {
        const {
            location: { state },
            allProducts,
        } = this.props
        const { isProductProcessed } = this.state
        const shouldUpdateSamples =
            state &&
            (state.code || state.group) &&
            !isProductProcessed &&
            allProducts.length

        if (shouldUpdateSamples) {
            let filteredProduct = allProducts.filter(p => p.code === state.code)

            if (!filteredProduct.length)
                filteredProduct = allProducts.filter(
                    p => p.websiteGroup === state.group
                )

            this.setState({
                isProductProcessed: true,
                sample: filteredProduct,
            })
        }
    }

    handleSubmit(e) {
        const {
            zip,
            find,
            city,
            state,
            fname,
            lname,
            email,
            phone,
            title,
            sample,
            company,
            address,
            captchaId,
            description,
            distributor,
            segment,
            website,
            message,
            locations,
            consumer,
        } = this.state
        const {
            location: { state: propsState },
        } = this.props
        const {
            shouldDisplayConsumer,
            shouldDisplayMiniForm,
        } = this.consumerCheck()
        const shouldTrigger = propsState && propsState.gaLabel

        let dropdowns = [
            'description',
            'find',
            'sample',
            'locations',
            'segment',
        ]

        if (shouldDisplayConsumer) {
            dropdowns.push('consumer')
        }

        if (shouldDisplayMiniForm) {
            dropdowns = ['description', 'find', 'consumer']
        }

        e.preventDefault()

        if (!captchaId) {
            return this.setState({
                captchaValidation: 'Please solve the captcha above.',
            })
        }

        let errors = []
        let validate = []

        dropdowns.forEach(ent => {
            !this.state[ent] &&
                errors.push({ param: ent, value: !this.state[ent] })
            validate.push(!!this.state[ent])
        })

        if (_IndexOf(validate, false) >= 0) {
            this.setState({ errors })
        } else {
            let feedback = {
                fname,
                lname,
                email,
                phone,
                title,
                sample,
                company,
                address,
                zip,
                find: find.name,
                city,
                state,
                captchaId,
                description: description.name,
                distributor,
                segment: segment.name,
                website,
                message,
                locations: locations.name,
            }

            if (shouldDisplayConsumer) {
                feedback.isConsumer = true
                feedback.consumer = consumer.name
            }

            if (shouldDisplayMiniForm) {
                feedback = {
                    find: find.name,
                    isConsumer: true,
                    message,
                    captchaId,
                    consumer: consumer.name,
                    fname,
                    lname,
                    email,
                    phone,
                    description: description.name,
                }
            }

            this.props.submitFeedback(feedback)
        }

        if (shouldTrigger) {
            triggerGAEvent({
                label: propsState.gaLabel,
                action: 'Button Click',
                category: 'Sample Requests',
            })
        }
    }

    handleInput(e) {
        const updates = {}
        updates[e.target.name] = e.target.value

        this.setState(updates)
    }

    handleSelectChange(type, opt) {
        const updates = {}
        const { errors } = this.state
        const filtered = errors && errors.filter(err => err['param'] === type)

        updates[type] = opt

        if (filtered && !!filtered.length)
            updates['errors'] = errors.filter(err => err['param'] !== type)

        this.setState(updates)
    }

    handleClose() {
        this.resetForm()
        this.props.completeFeedback()
        this.props.history.push('/')
    }

    resetForm() {
        this.setState(
            {
                zip: '',
                find: '',
                city: '',
                state: '',
                fname: '',
                lname: '',
                email: '',
                phone: '',
                title: '',
                sample: '',
                company: '',
                address: '',
                segment: '',
                website: '',
                message: '',
                locations: '',
                consumer: '',
                captchaId: '',
                description: '',
                distributor: '',
                captchaValidation: '',
            },
            () => {
                this.recaptchaRef.current.reset()
            }
        )
    }

    onCaptchaChange(value) {
        this.setState({
            captchaId: value,
            captchaValidation: '',
        })
    }

    getRequestForm() {
        const { errors } = this.state
        const { sending, allProducts } = this.props
        const productPlaceholder = !!allProducts.length
            ? 'Select a product or type your selection*'
            : 'Loading ...'
        const errorMsg = <p className="error">Please, select an option</p>
        const isError = name => {
            const filtered =
                errors && errors.filter(err => err['param'] === name)
            return filtered && !!filtered.length
        }
        const {
            shouldDisplayConsumer,
            shouldDisplayMiniForm,
        } = this.consumerCheck()

        return (
            <form className="request-form" onSubmit={this.handleSubmit}>
                <div
                    className={`form-group ${isError('description') &&
                        'is-invalid'}`}
                >
                    <label>
                        Please choose the option that best describes your
                        request:
                    </label>
                    <Select
                        required
                        isClearable
                        name="description"
                        className="select"
                        classNamePrefix="select"
                        placeholder="Select an option*"
                        options={business}
                        getOptionLabel={i => i.name}
                        getOptionValue={i => i.value}
                        onChange={opt =>
                            this.handleSelectChange('description', opt)
                        }
                        value={this.state.description}
                    />
                    {errorMsg}
                </div>

                {shouldDisplayConsumer && (
                    <div
                        className={`form-group ${isError('consumer') &&
                            'is-invalid'}`}
                    >
                        <label>Select a Consumer type:</label>
                        <Select
                            required
                            isClearable
                            name="consumer"
                            className="select"
                            classNamePrefix="select"
                            placeholder="Select an option*"
                            options={consumers}
                            getOptionLabel={i => i.name}
                            getOptionValue={i => i.value}
                            onChange={opt =>
                                this.handleSelectChange('consumer', opt)
                            }
                            value={this.state.consumer}
                        />
                        {errorMsg}
                    </div>
                )}

                <div
                    className={`form-group ${isError('find') && 'is-invalid'}`}
                >
                    <label>How did you find this page?</label>
                    <Select
                        required
                        name="find"
                        className="select"
                        classNamePrefix="select"
                        placeholder="Select an option*"
                        options={find}
                        getOptionLabel={i => i.name}
                        getOptionValue={i => i.value}
                        onChange={opt => this.handleSelectChange('find', opt)}
                        value={this.state.find}
                    />
                    {errorMsg}
                </div>

                {!shouldDisplayMiniForm && (
                    <>
                        <div
                            className={`form-group ${isError('sample') &&
                                'is-invalid'}`}
                        >
                            <label>I'd like to sample</label>
                            <Select
                                required
                                isMulti
                                name="sample"
                                className="select"
                                classNamePrefix="select"
                                placeholder={productPlaceholder}
                                options={_SortBy(allProducts, 'name')}
                                getOptionLabel={i =>
                                    `${i.name || ''} ${
                                        i.code ? `[${i.code}]` : ''
                                    }`
                                }
                                getOptionValue={i => i.id}
                                onChange={opt =>
                                    this.handleSelectChange('sample', opt)
                                }
                                value={this.state.sample}
                            />
                            {errorMsg}
                        </div>
                        <div className="two-columns">
                            <div>
                                <div className="form-group">
                                    <label>Your company</label>
                                    <input
                                        type="text"
                                        name="company"
                                        placeholder="Company name*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.company}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Address*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.address}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="City*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.city}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="State*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.state}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="zip"
                                        placeholder="Zip*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.zip}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="website"
                                        placeholder="Company website*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.website}
                                    />
                                </div>
                                <div
                                    className={`form-group ${isError(
                                        'locations'
                                    ) && 'is-invalid'}`}
                                >
                                    <Select
                                        required
                                        isClearable
                                        name="locations"
                                        className="select"
                                        classNamePrefix="select"
                                        placeholder="Select a number of locations*"
                                        options={locations}
                                        getOptionLabel={i => i.name}
                                        getOptionValue={i => i.value}
                                        onChange={opt =>
                                            this.handleSelectChange(
                                                'locations',
                                                opt
                                            )
                                        }
                                        value={this.state.locations}
                                    />
                                    {errorMsg}
                                </div>
                                <div
                                    className={`form-group ${isError(
                                        'segment'
                                    ) && 'is-invalid'}`}
                                >
                                    <Select
                                        required
                                        isClearable
                                        name="segment"
                                        className="select"
                                        classNamePrefix="select"
                                        placeholder="Select a segment*"
                                        options={segments}
                                        getOptionLabel={i => i.name}
                                        getOptionValue={i => i.value}
                                        onChange={opt =>
                                            this.handleSelectChange(
                                                'segment',
                                                opt
                                            )
                                        }
                                        value={this.state.segment}
                                    />
                                    {errorMsg}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="distributor"
                                        placeholder="Distributor*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.distributor}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="form-group">
                                    <label>Your information</label>
                                    <input
                                        type="text"
                                        name="fname"
                                        placeholder="First name*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.fname}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="lname"
                                        placeholder="Last name*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.lname}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.email}
                                        placeholder="Business email address*"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone number*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.phone}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Title"
                                        maxLength={50}
                                        onChange={this.handleInput}
                                        value={this.state.title}
                                    />
                                    <small>
                                        {`${this.state.title.length}/${this.state.MAX_TITLE_LENGTH}`}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>How can we help?</label>
                                    <textarea
                                        rows="7"
                                        maxLength={500}
                                        name="message"
                                        placeholder="How can we help?"
                                        onChange={this.handleInput}
                                        value={this.state.message}
                                    />
                                    <small>
                                        {`${this.state.message.length}/${this.state.MAX_MSG_LENGTH}`}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {shouldDisplayMiniForm && (
                    <>
                        <div className="two-columns">
                            <div>
                                <div className="form-group">
                                    <label>Your information</label>
                                    <input
                                        type="text"
                                        name="fname"
                                        placeholder="First name*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.fname}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone number*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.phone}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <input
                                        type="text"
                                        name="lname"
                                        placeholder="Last name*"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.lname}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        onChange={this.handleInput}
                                        value={this.state.email}
                                        placeholder="Email*"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>How can we help?</label>
                            <textarea
                                rows="7"
                                maxLength={500}
                                name="message"
                                placeholder="How can we help?"
                                onChange={this.handleInput}
                                value={this.state.message}
                            />
                            <small>
                                {`${this.state.message.length}/${this.state.MAX_MSG_LENGTH}`}
                            </small>
                        </div>
                    </>
                )}

                <div className="two-columns">
                    <Suspense fallback={null}>
                        <ReCaptcha
                            recaptchaRef={this.recaptchaRef}
                            onChange={this.onCaptchaChange}
                        />
                    </Suspense>
                    {!!this.state.captchaValidation && (
                        <p style={{ color: '#900' }}>
                            {this.state.captchaValidation}
                        </p>
                    )}

                    <div className="submit">
                        {sending && (
                            <button disabled>Sending, please wait...</button>
                        )}
                        {!sending && (
                            <button type="submit">Send request</button>
                        )}
                    </div>
                </div>
            </form>
        )
    }

    render() {
        return (
            <div>
                <div className="body-wrapper request secondary">
                    <img className="packages" src={packages} alt="" />
                    <h1 className="short">Request a Sample</h1>
                    <div className="descr">Experience Unmatched Variety and Quality in Premium Barbecue, Wing Sauces, Specialty Blends, and More!</div>
                </div>
                <div className="body-wrapper request">
                    <div className="descr">
                        Thanks for considering us. A Ken&rsquo;s representative will
                        contact you soon about your request.
                    </div>
                    {this.getRequestForm()}
                    <Suspense fallback={null}>
                        <Modal
                            visible={this.props.showSuccessModal}
                            onClose={() => this.handleClose()}
                        >
                            <h2>Thank you for considering Ken's!</h2>
                            <p className="descr">
                                A Ken's representative will contact you soon about
                                your request.
                            </p>
                        </Modal>
                    </Suspense>
                    <Suspense fallback={null}>
                        <Modal
                            visible={this.props.somethingWentWrong}
                            onClose={() => this.props.completeFeedback()}
                        >
                            <h2>Oops! Something went wrong.</h2>
                        </Modal>
                    </Suspense>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errors: state.sample.errors,
    sending: state.sample.sending,
    allProducts: state.products.all,
    showSuccessModal: state.sample.showSuccessModal,
    somethingWentWrong: state.sample.somethingWentWrong,
})

export default connect(
    mapStateToProps,
    { fetchAllProducts, submitFeedback, completeFeedback }
)(GetSample)
