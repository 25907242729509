import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';

class PlusOnePdf extends PureComponent {

    render() {
        let plusOne = null;
        const { selected, selectedFromSearch } = this.props;

        if (selectedFromSearch) {
            plusOne = selectedFromSearch.details;
        } else {
            plusOne = selected;
        };

        return (
            <Document file={plusOne.pdfFilePath}>
                <Page pageNumber={1} scale={1.3} />
            </Document>
        );
    }
}

const mapStateToProps = state => ({
    selected: state.plusOnes.selected,
    selectedFromSearch: state.searchResults.selected
});

export default connect(mapStateToProps)(PlusOnePdf);
