import { takeLatest, put, call } from 'redux-saga/effects';
import _get from 'lodash/get';

import GTM, { submitSampleRequestFormEvent, submitSampleRequestConsumerEvent } from '../../helpers/gtm';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const submitFeedback = function* (action) {
    try {
        const samplePath = action.payload.isConsumer ? '/api/getconsumersample' : '/api/getsample';
        const apiResponse = yield call([ApiClient, ApiClient.post], samplePath, action.payload);
        yield put({ type: types.SUBMIT_FEEDBACK_SUCCESS, payload: apiResponse });
        const gtmEvent = action.payload.isConsumer ? submitSampleRequestConsumerEvent : submitSampleRequestFormEvent;
        gtmEvent.eventValue = _get(apiResponse, 'data.id');
        yield call([GTM, GTM.pushEvent], gtmEvent);
    }
    catch (err) {
        console.error('[submitFeedback] ->', JSON.stringify(err));
        yield put({ type: types.SUBMIT_FEEDBACK_FAILED, error: err });
    }
};

export default [
    takeLatest(types.SUBMIT_FEEDBACK_START, submitFeedback)
];
