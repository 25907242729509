import _find from 'lodash/find'

export * from './events'

/*
    New setup for GTM events.
*/

// console.log('window.dataLayer  ', window.dataLayer);
// console.log("window['google_tag_manager']", window['google_tag_manager']);

function _getDataLayer() {
    return !!window && window.dataLayer
}

function _dataLayerIsset(dataLayer) {
    return !!dataLayer && Array.isArray(dataLayer)
}

function getEvent(desc) {
    const dataLayer = _getDataLayer()

    return _dataLayerIsset(dataLayer) ? _find(dataLayer, desc) : null
}

function pushEvent(event) {
    // console.log(event)

    const dataLayer = _getDataLayer()

    _dataLayerIsset(dataLayer) && dataLayer.push(event)
}

const GTM = {
    getEvent,
    pushEvent,
}

export default GTM
