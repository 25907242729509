import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';

class ProductPdf extends PureComponent {

    render() {
        const item = this.props.selected;

        return (
            <Document file={item.files.pdf}>
                <Page pageNumber={1} scale={1.5} />
            </Document>
        );
    }
}

const mapStateToProps = state => ({
    selected: state.products.selected
});

export default connect(mapStateToProps)(ProductPdf);
