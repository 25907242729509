import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './burgers.scss';

import burger from '../../assets/img/burger-board.png';
import set1 from '../../assets/img/burger-set1.png';
import set2 from '../../assets/img/burger-set2.png';
import product1 from '../../assets/img/products/ke1662_basil_pesto_aioli.png';
import product2 from '../../assets/img/products/ke2632_2.png';

import burgerInsightsPDF from '../../assets/pdf/Kens_Burgers_TechnomicSellSheet_v5.pdf';
import burgerRecipesPDF from '../../assets/pdf/Kens_Burger_Foods_RecipeSellSheet_v5.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f551715d', displayOrder: 1 }, // brewpub style mustard sauce
    { _id: '5bd97315a842a126f551711a', displayOrder: 2 }, // basil pesto aioli
    { _id: '5c89dea5abf060001034e058', displayOrder: 3 }, // horseradish aioli
    { _id: '5bd97315a842a126f551709d', displayOrder: 4 }  // garlic aioli
];
const recipes = [
    { _id: '5bd97312a842a126f5516ee2', displayOrder: 1 }, // turkey burger with goat cheese and arugula
    { _id: '5bd97312a842a126f5516eb3', displayOrder: 2 }, // holy trinity charburger
    { _id: '5bd97312a842a126f5516e98', displayOrder: 3 }, // tex-mex burger
    { _id: '5bd97312a842a126f5516f26', displayOrder: 4 }, // cabo wabo burger
    { _id: '5bd97312a842a126f5516ea1', displayOrder: 5 }, // cajun andouille burger
    { _id: '5bd97312a842a126f5516f1c', displayOrder: 6 }  // the chip shop burger
];

class Burgers extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Burger menu insights</h1>
                    <div className="descr short">See which flavors are on the rise, find recipes customers are craving and discover the sauces your burger menu is missing.
                    </div>
                </div>

                <div className="hero-wrapper burger"></div>

                <div className="body-wrapper burger">
                    <div className="feature-wrapper">
                        <img src={burger} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">58%</div>
                                <div className="descr">of consumers say it is very important to be able to customize toppings</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">57%</div>
                                <div className="descr">of consumers eat burgers at least once a week</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">43%</div>
                                <div className="descr">of consumers say they are interested in trying new condiments and flavors on burgers</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper burger">
                    <h2>The popularity of flavored aiolis keeps on growing</h2>
                </div>

                <div className="body-wrapper burger">
                    <div className="benefits-wrapper">
                        <div className="descr">Pesto Aioli<span>6.3%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Chipotle Aioli<span>62.5%</span>Growth</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>TURKEY BURGER with goat cheese, arugula and Ken’s Signature Chipotle Mayonnaise SAUCE</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Chip Shop Burger with Ken’s Signature Horseradish Aioli</span>
                        </div>
                        <div className="descr">Horseradish Aioli<span>14.3%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Spicy Mustard<span>9.1%</span>Growth</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={burgerInsightsPDF} data-gtm-download-more-insights-burger-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={burgerRecipesPDF} data-gtm-see-all-insights-burger-button-link-a download>download burger brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5ddc337402debe0012826cbd" }}} className="button" data-gtm-see-all-insights-burger-button-link-a>see all burger recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts
                            className="burger"
                            settings={products}
                            title="Burger sauces we know you'll love" />
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="burgers" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Burgers);
