import { takeLatest, put, call, debounce } from 'redux-saga/effects';
import Qs from 'qs';

import ApiClient from '../../helpers/api'

import { actionTypes as types } from './constants';
import { ADD_ERROR } from '../../actions/types'

const search = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], `/api/search/?searchTerm=${encodeURIComponent(action.payload)}`);;
        yield put({ type: types.SEARCH_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[search] error: ', JSON.stringify(err));
        yield put({ type: types.SEARCH_FAILED, message: err.message });
    }
}

const loadBlocks = function* (action) {
    const url = `/api/dxp-data/?${Qs.stringify({dataset: ['homepageBlocks']})}`;
    const results = yield call([ApiClient, ApiClient.get], url);

    if (results && results.data && Array.isArray(results.data) && !!results.data.length && results.data[0].dataset === 'homepageBlocks') {
        yield put({ type: types.LOAD_BLOCKS_SUCCESS, payload: results.data[0].data });
    } else {
        yield put({ type: ADD_ERROR, message: 'Failed to load home page content' });
    }
}

export default [
    debounce(750, types.SEARCH_START, search),
    takeLatest(types.LOAD_BLOCKS_START, loadBlocks)
];
