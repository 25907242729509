import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './stadium.scss';

import logo from '../../assets/img/kens-logo-white.svg';
import chips from '../../assets/img/offers/stadium-staples/chips.jpg';
import fries from '../../assets/img/offers/stadium-staples/fries.jpg';
import hotDog from '../../assets/img/offers/stadium-staples/hotdog.jpg';

const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f5517130', displayOrder: 1 }, // Ken's Signature Dijon Horseradish Sauce
    { _id: '5bd97315a842a126f551709d', displayOrder: 2 }, // Ken's Signature Garlic Aioli
    { _id: '5bd97315a842a126f5517094', displayOrder: 3 }, // Ken's Signature Chipotle Mayonnaise
    { _id: '5bd97315a842a126f55170b7', displayOrder: 4 }, // Cannonball Barbecue Sauce
    { _id: '5bd97315a842a126f551709c', displayOrder: 5 }, // Ken's Signature Remoulade Sauce
    { _id: '5bd97315a842a126f551715d', displayOrder: 6 }  // Ken's Signature Brewpub Style Mustard Sauce
];
const recipes = [
    { _id: '5c6379c2a7b08a0011d1c1bf', displayOrder: 1 }, // Beer boiled shrimp with texas blue ribbon barbecue sauce
    { _id: '5c636a0da7b08a0011d1c1b2', displayOrder: 2 }, // Beer cooked bratwurst with brewpub mustard
    { _id: '5c637398a7b08a0011d1c1ba', displayOrder: 3 }, // Blackened catfish po boy tacos
    { _id: '5c61a3b7a7b08a0011d18b1e', displayOrder: 4 }, // Stadium pulled pork nachos
    { _id: '5c619c8da7b08a0011d18b19', displayOrder: 5 }, // Corn dogs with ken's dijon horseradish sauce
    { _id: '5c636601a7b08a0011d1c1ae', displayOrder: 6 }, // Lobster salad stuffed potato skins with garlic aioli
    { _id: '5c636c7fa7b08a0011d1c1b4', displayOrder: 7 }, // Buttermilk brined hot chicken sandwich with chipotle mayonnaise
    { _id: '5c61a074a7b08a0011d18b1c', displayOrder: 8 }  // Fried chicken drumsticks with ken's creole chili pepper sauce
];

class Stadium extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="hero-wrapper stadium">
                    <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                    <h1>Score big with stadium staples</h1>
                    <div className="descr">Draw a crowd with ballpark favorites like corn dogs, pulled pork and more</div>
                    <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Stadium Staples' }}}>request a sample</Link>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Featured stadium recipes</h2>
                    <div className="descr short">Dig into small bites with big flavor</div>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                </div>

                <div className="body-wrapper stadium">
                    <h2>Classic snacks still pack the house</h2>
                    <div className="descr short">Hungry event-goers continue to seek out stadium favorites.</div>
                    <div className="benefits-wrapper">
                        <img src={chips} alt="" />
                        <div className="descr"><span>42%</span>of consumers purchase nachos at least occasionally.</div>
                        <img src={fries} alt="" />
                        <div className="descr"><span>51%</span>of consumers purchase french fries at least occasionally.</div>
                        <img src={hotDog} alt="" />
                        <div className="descr"><span>31%</span>of consumers prefer sausage as a sandwich meat.</div>
                    </div>
                    <div className="remark">Source: Technomic 2018 Sandwich & Snacking Consumer Trend Reports</div>
                </div>

                {isDataLoaded && <Suspense fallback={null}>
                    <SourceDataProducts settings={products} />
                </Suspense>}

                <div className="body-wrapper column primary">
                    <h2>Interested? Try it yourself.</h2>
                    <Link className="button" to="/get-sample">request a sample</Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Stadium);
