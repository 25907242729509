import React, { PureComponent } from 'react';

import './page-not-found.scss';

class PageNotFound extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="body-wrapper column page404">
                <h1>404 - page not found</h1>
            </div>
        );
    }
}

export default PageNotFound;
