import clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    error: null,
    sent: false,
    sending: false,
};

export default (state = initialState, action) => {
    let updatedState = clone(state);

    switch (action.type) {
        case types.SUBMIT_PIZZAEXPO_REGISTRATION_START:
            updatedState = {
                error: null,
                sent: false,
                sending: true,
            };
            break;
        case types.SUBMIT_PIZZAEXPO_REGISTRATION_SUCCESS:
            updatedState = { sent: true, sending: false };
            break;
        case types.SUBMIT_PIZZAEXPO_REGISTRATION_FAILED:
            updatedState = {
                sending: false,
                error: action.error,
            };
            break;
        case types.CLEAR_PIZZAEXPO_REGISTRATION:
            updatedState = { ...initialState };
            break;
        default:
            break;
    }

    return updatedState;
}
