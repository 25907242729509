import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import FeedbackWrapper from '../../components/FeedbackWrapper';
import SourceDataRecipes from '../../components/sourceDataItems/Recipes';
import SourceDataProducts from '../../components/sourceDataItems/Products';

import './tacos.scss';

import taco from '../../assets/img/tacos-board.png';
import set1 from '../../assets/img/tacos-set1.png';
import set2 from '../../assets/img/tacos-set2.png';
import product1 from '../../assets/img/products/ke3191_2.png';
import product2 from '../../assets/img/products/ke1152.png';

import tacosInsightsPDF from '../../assets/pdf/Kens_TacosTechnomic_R3.pdf';
import tacosRecipesPDF from '../../assets/pdf/Kens_Tacos_Recipe_Sheet.pdf';

const products = [
    { _id: '5bd97315a842a126f5517159', displayOrder: 1 }, // Chipotle Ranch Dressing
    { _id: '5bd97315a842a126f5517074', displayOrder: 2 }, // Ranch Dressing
    { _id: '5bd97315a842a126f55170df', displayOrder: 3 }, // Cucumber Wasabi Dressing
    { _id: '5bd97315a842a126f5517158', displayOrder: 4 }  // Ken's Signature Creole Chili Pepper Sauce
];
const recipes = [
    { _id: '5bd97312a842a126f5516e5c', displayOrder: 1 }, // Tex-Nawlin’s Chicken Salad Tacos
    { _id: '5bd97312a842a126f5516e63', displayOrder: 2 }, // Boom Boom Crispy Fish Tacos
    { _id: '5bd97312a842a126f5516e80', displayOrder: 3 }, // Picadillo Beef Tacos
    { _id: '5ed69fd2f185dc0011d73c1f', displayOrder: 4 }, // Creole Shrimp and Grits Taco
    { _id: '5f84b6fc3b6f3e0011f5d62f', displayOrder: 5 }, // Grilled Mushroom Fajita Steak Tacos
    { _id: '5f84b4ca7bab1800118b2331', displayOrder: 6 },  // Cuban Pig Tacos
    { _id: '5f84b4777bab1800118b2330', displayOrder: 7 },  // Yucatan Pork Taco
    { _id: '5f3bdf6aa751e50011e6c5f8', displayOrder: 8 }  // South of Buffalo Chicken Tacos
];

class Tacos extends Component {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Taco menu insights</h1>
                    <div className="descr short">Learn what customers are craving, find new recipes and discover saucesthat will take your tacos to the next level.</div>
                </div>

                <div className="hero-wrapper tacos"></div>

                <div className="body-wrapper tacos">
                    <div className="feature-wrapper">
                        <img src={taco} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">71%</div>
                                <div className="descr">of consumers agree Mexican cuisine is unique and exciting</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">55%</div>
                                <div className="descr">of consumers are likely to order dishes with ethnic flavors for dinner</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">47%</div>
                                <div className="descr">of consumers would order fish tacos at a restaurant at least occasionally</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper tacos">
                    <h2>These are the sauces getting the biggest boost from taco lovers around the country</h2>
                </div>

                <div className="body-wrapper tacos">
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Blackened Catfish Po Boy Tacos with Ken's Signature Chipotle Mayonnaise Sauce</span>
                        </div>
                        <div className="descr">Chipotle Aioli<span>12.2%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Garlic Mayonnaise<span>10%</span>Growth</div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="descr">Chipotle Ranch Dressing<span>5%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Ranch<span>4.5%</span>Growth</div>
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Baja fish tacos with Ken’s jalapeno ranch dressing</span>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={tacosInsightsPDF} data-gtm-download-more-insights-tacos-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <SourceDataRecipes settings={recipes} /> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={tacosRecipesPDF} data-gtm-see-all-insights-tacos-button-link-a download>download taco brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5bd97314a842a126f551704d" }}} className="button" data-gtm-see-all-insights-tacos-button-link-a>see all taco recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <SourceDataProducts
                        className="tacos"
                        settings={products}
                        title="Taco sauces we know you'll love" />
                )}

                <FeedbackWrapper page="tacos" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Tacos);
