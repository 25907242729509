import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import FeedbackWrapper from '../../components/FeedbackWrapper';
import SourceDataRecipes from '../../components/sourceDataItems/Recipes';
import SourceDataProducts from '../../components/sourceDataItems/Products';

import './deli.scss';

import deli from '../../assets/img/deli-board.png';
import set1 from '../../assets/img/deli-set1.png';
import set2 from '../../assets/img/deli-set2.png';
import product1 from '../../assets/img/products/ke3192_2.png';
import product2 from '../../assets/img/products/ke1183_2.png';

import deliInsightsPDF from '../../assets/pdf/Kens_deli_technomic_sheet_R2.pdf';
import deliRecipesPDF from '../../assets/pdf/Kens_Deli_Recipe_Sheet.pdf';

const products = [
    { _id: '5bd97315a842a126f55170d2', displayOrder: 1 }, // Premium Mayonnaise
    { _id: '5bd97315a842a126f551715d', displayOrder: 2 }, // Ken's Signature Brewpub Style Mustard Sauce
    { _id: '5bd97315a842a126f551711a', displayOrder: 3 }, // Ken's Signature Basil Pesto Aioli
    { _id: '5bd97315a842a126f551713b', displayOrder: 4 }  // Home Style Ranch Dressing
];
const recipes = [
    { _id: '5bd97312a842a126f5516f3e', displayOrder: 1 }, // Pastrami Sandwich With Spicy Brewpub Mustard
    { _id: '5bd97312a842a126f5516f42', displayOrder: 2 }, // Beef Weck Sandwich
    { _id: '5f84bd173b6f3e0011f5d641', displayOrder: 3 }, // The Hot Mess Beef Sandwich
    { _id: '5f84c2467bab1800118b2346', displayOrder: 4 }, // Old Venice Italian Cheesesteak with Broccoli Rabe
    { _id: '5f84bef47bab1800118b233e', displayOrder: 5 }, // Meat Lover's Panini
    { _id: '5bd97312a842a126f5516ea2', displayOrder: 6 },  // The Spicy Cuban
    { _id: '5f84c5a87bab1800118b2350', displayOrder: 7 },  // Ham and Swiss Pretzel Sandwich with Honey Lime
    { _id: '5f442eab46a27400121e2c12', displayOrder: 8 }  // Hail Caesar Rotisserie Roasted Chicken Sandwich
];

class Deli extends Component {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Deli sandwich menu insights</h1>
                    <div className="descr short">Learn what customers are craving, find new recipes and discover sauces that will take your deli sandwiches to the next level.</div>
                </div>

                <div className="hero-wrapper deli"></div>

                <div className="body-wrapper deli">
                    <div className="feature-wrapper">
                        <img src={deli} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">66%</div>
                                <div className="descr">of foodservice operators menu sandwiches</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">61%</div>
                                <div className="descr">of consumers eat a sandwich once a week or more</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">39%</div>
                                <div className="descr">of customers would like restaurants to offer more sandwiches with new or unique flavors</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper deli">
                    <h2>Sauces and toppings are moving into the spotlight as a key differentiator for sandwiches</h2>
                </div>

                <div className="body-wrapper deli">
                    <div className="benefits-wrapper">
                        <div className="descr">Chili Mayonnaise<span>10%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Horseradish Aioli<span>21.4%</span>Growth</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>BEEF WECK SANDWICH WITH KEN’s signature horseradish aioli</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>turkey club with ken’s signature garlic aioli</span>
                        </div>
                        <div className="descr">Garlic Aioli<span>4.5%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Chipotle Mayonnaise<span>3.9%</span>Growth</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={deliInsightsPDF} data-gtm-download-more-insights-deli-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <SourceDataRecipes settings={recipes} /> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={deliRecipesPDF} data-gtm-see-all-insights-deli-button-link-a download>download deli brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { courses: "5ddc63536a636e0012924f3c" }}} className="button" data-gtm-see-all-insights-deli-button-link-a>see all deli recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <SourceDataProducts
                        className="deli"
                        settings={products}
                        title="Deli sandwich sauces we know you'll love" />
                )}

                <FeedbackWrapper page="deli" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Deli);
