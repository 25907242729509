import _clone from 'lodash/clone';
import _find from 'lodash/find';
import { actionTypes as types } from './constants';

const initialState = {
    list: [],
    selected: null,
    loading: false,
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_SEARCH_RESULTS_START:
            updatedState.loading = true;
            break;
        case types.FETCH_SEARCH_RESULTS_SUCCESS:
            if (action.payload.data) {
                const results = [];
                action.payload.data.forEach(d =>
                    Object.keys(d)[0] && results.push(d));
                updatedState.list = results;
            } else {
                updatedState.list = [];
            }
            updatedState.loading = false;
            break;
        case types.FETCH_SEARCH_RESULTS_FAILED:
            updatedState.loading = false;
            break;
        case types.ON_SELECTED:
            const plusOnes = _find(updatedState.list, 'plusones')['plusones'];
            updatedState.selected = plusOnes.find(d => d.id === action.itemID);
            break;
        default:
            break;
    }

    return updatedState;
}
