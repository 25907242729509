import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './fries.scss';

import fries from '../../assets/img/fries-board.png';
import set1 from '../../assets/img/fries-set1.png';
import set2 from '../../assets/img/fries-set2.png';
import product1 from '../../assets/img/products/ke1055.png';
import product2 from '../../assets/img/products/ke0443.png';

import friesInsightsPDF from '../../assets/pdf/Kens_LoadedFries_Technomics_v4.pdf';
import friesRecipesPDF from '../../assets/pdf/Kens_LoadedFries_RecipeSellSheet_v4.pdf';
import friesFlavorPDF from '../../assets/pdf/KENS_PlusOnes_July_LoadedFries_v4.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f551711b', displayOrder: 1 }, // Boom Boom Sauce
    { _id: '5bd97315a842a126f55170e3', displayOrder: 2 }, // Green Chili Aioli
    { _id: '5bd97315a842a126f5517159', displayOrder: 3 }, // Chipotle Ranch Dressing
    { _id: '5bd97315a842a126f551709d', displayOrder: 4 }  // Garlic Aioli
];
const recipes = [
    { _id: '5e58101f6e7ecb00101eeee6', displayOrder: 1 }, // Loaded tots rings
    { _id: '5dd42b4c153c9f00120f3fb5', displayOrder: 2 }, // Bacon queso smothered fries
    { _id: '5e5809066e7ecb00101eeee3', displayOrder: 3 }, // Brewpub loaded fries
    { _id: '5e5828d56e7ecb00101eeee7', displayOrder: 4 }, // White truffle garlic aioli fries
    { _id: '5dd4256e153c9f00120f3fb3', displayOrder: 5 }, // Ranch hand loaded fries
    { _id: '5dd424c4153c9f00120f3fb1', displayOrder: 6 }, // Classic southwest loaded fries
    { _id: '5dd4225bfab0660011f6e88f', displayOrder: 7 }  // Chili cheese fries
];

class Fries extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Loaded fries menu insights</h1>
                    <div className="descr short">See what toppings customers are craving, discover new recipes and find delicious sauce pairings below.
                    </div>
                </div>

                <div className="hero-wrapper fries"></div>

                <div className="body-wrapper fries">
                    <div className="feature-wrapper">
                        <img src={fries} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">56%</div>
                                <div className="descr">of consumers like to try different kinds of fries</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">45%</div>
                                <div className="descr">Novelty and uniqueness of an appetizer is important to younger consumers</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">42%</div>
                                <div className="descr">of consumers would order loaded fries appetizers or small plates</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper fries">
                    <h2>Popular sauces are driving topped fries forward</h2>
                </div>

                <div className="body-wrapper fries">
                    <div className="benefits-wrapper">
                        <div className="descr">Barbecue Sauce<span>100%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Jalapeño Dressing<span>33.3%</span>Growth</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>classic southwest loaded fries with ken’s jalapeño ranch dressing</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Ranch hand loaded fries with ken’s ranch dressing</span>
                        </div>
                        <div className="descr">Ranch<span>8.9%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Buffalo<span>66.7%</span>Growth</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={friesInsightsPDF} data-gtm-download-more-insights-fries-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={friesRecipesPDF} data-gtm-see-all-insights-fries-button-link-a download>download fries brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5ddc52ac6a636e0012924f35" }}} className="button" data-gtm-see-all-insights-fries-button-link-a>see all fries recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts settings={products} className="fries" title="Loaded fries sauces we know you'll love">
                            <a className="button" href={friesFlavorPDF}>Make it your own ideas</a>
                        </SourceDataProducts>
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="fries" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Fries);
