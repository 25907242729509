import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './salads.scss';

import salad from '../../assets/img/salad-board.png';
import set1 from '../../assets/img/salad-set1.jpg';
import set2 from '../../assets/img/salad-set2.jpg';
import set3 from '../../assets/img/salad-set3.jpg';

import saladInsightsPDF from '../../assets/pdf/kens_salads_technomic_v5.pdf';
import saladRecipesPDF from '../../assets/pdf/Kens_Salads_Foods_RecipeSellSheet_v5.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f5517120', displayOrder: 1 }, // white balsamic vinaigrette
    { _id: '5bd97315a842a126f551711f', displayOrder: 2 }, // lemon vinaigrette
    { _id: '5bd97315a842a126f55170a9', displayOrder: 3 }, // lite raspberry vinaigrette
    { _id: '5dc646a6877c420011694b25', displayOrder: 4 }  // green goddess dressing
];
const recipes = [
    { _id: '5bd97312a842a126f5516e99', displayOrder: 1 }, // Avocado citrus salad
    { _id: '5bd97312a842a126f5516e87', displayOrder: 2 }, // Pittsburgh steak salad
    { _id: '5bd97312a842a126f5516f09', displayOrder: 3 }, // 15 shades of green salad
    { _id: '5bd97312a842a126f5516ec4', displayOrder: 4 }, // Vietnamese noodle salad
    { _id: '5bd97312a842a126f5516f01', displayOrder: 5 }, // Whiskey chicken salad
    { _id: '5bd97312a842a126f5516f4b', displayOrder: 6 }, // Fall harvest salad
    { _id: '5bd97312a842a126f5516ecd', displayOrder: 7 }, // Shaved cauliflower salad
    { _id: '5bd97312a842a126f5516e6f', displayOrder: 8 }  // Chopped greek salad
];

class Salads extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Salad menu insights</h1>
                    <div className="descr short">See what salad flavors are on the rise, find new recipes and discover dressings you need to give your menu a refresh.
                    </div>
                </div>

                <div className="hero-wrapper salad"></div>

                <div className="body-wrapper salad">
                    <div className="feature-wrapper">
                        <img src={salad} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">65%</div>
                                <div className="descr">of consumers say dressing is important for adding flavor to salads</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">51%</div>
                                <div className="descr">of consumers think it’s important for a restaurant to carry a wide variety of dressings</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">39%</div>
                                <div className="descr">of consumers go to certain restaurants specifically because they enjoy their salads</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper salad">
                    <h2>Young consumers are driving interest<br/> in unique salads forward</h2>
                </div>

                <div className="body-wrapper salad">
                    <div className="benefits-wrapper">
                        <div className="descr"><span>36%</span>of 18- to 34-year-old consumers are interested in trying more ethnic salads</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Vietnamese Noodle Salad with Ken’s Citrus Dijon Vinaigrette</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Pittsburgh Steak Salad with Ken’s Essentials Bleu Cheese Dressing</span>
                        </div>
                        <div className="descr"><span>33%</span>of 18- to 34-year-old consumers are more likely to order a warm entrée salad</div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="descr"><span>30%</span>of 18- to 34-year-old consumers are interested in trying more grain-based salads</div>
                        <div className="img-block">
                            <img src={set3} alt="" />
                            <span>Quinoa Lime Ginger Salad with Grilled Shrimp with Ken’s Lime Vinaigrette Dressing and Marinade</span>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={saladInsightsPDF} data-gtm-download-more-insights-salad-button-link-a  download>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={saladRecipesPDF} data-gtm-see-all-insights-salad-button-link-a download>download salad brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "5ddc54fa6a636e0012924f38" }}} className="button" data-gtm-see-all-insights-salad-button-link-a>see all salad recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts
                            className="salad"
                            settings={products}
                            title="Salad dressings we know you'll love" />
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="salads" />
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Salads);
