import React, { PureComponent, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import './bottles.scss';

import bottle1 from '../../assets/img/products/ke0858zy_essentials_golden_italian.png';
import bottle2 from '../../assets/img/products/ke0677zy_essentials_deluxe__ranch_adj.png';
import bottle4 from '../../assets/img/products/ke0670zy_essentials_creamy_caesar_adj.png';
import bottle5 from '../../assets/img/products/ke0636zy_essentials_lite_balsamic_adjusted.png';
import bottle6 from '../../assets/img/products/ke0616zy_essentials_thousand_island_adj.png';
import bottle7 from '../../assets/img/products/ke0665zy_essentials_blue_cheese_adj.png';
import bottle8 from '../../assets/img/products/ke0630zy_fat_free_raspberry.png';
import bottle9 from '../../assets/img/products/ke0601zy_reduced_calorie_italain_adj.png';
import bottle10 from '../../assets/img/products/ke0552zy_honey_mustard_adj.png';

import oneBottle from '../../assets/img/packages/bottles-one-bottle.png';
import label from '../../assets/img/packages/bottles-label.png';
import creamyBottle from '../../assets/img/products/ke0670zy_essentials_creamy_caesar_adj.png';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

const bottlesSlider = [
    {img: bottle1, link: "/products/kens-essentials-golden-italian-dressing/bar bottle"},
    {img: bottle2, link: "/products/kens-essentials-deluxe-ranch-dressing/bar bottle"},
    {img: bottle4, link: "/products/kens-essentials-classic-caesar-dressing/bar bottle"},
    {img: bottle5, link: "/products/kens-essentials-lite-balsamic-with-olive-oil-vinaigrette/bar bottle"},
    {img: bottle6, link: "/products/kens-essentials-thousand-island-dressing/bar bottle"},
    {img: bottle7, link: "/products/kens-essential-bleu-cheese-dressing/bar bottle"},
    {img: bottle8, link: "/products/fat-free-raspberry-vinaigrette/bar bottle"},
    {img: bottle9, link: "/products/reduced-calorie-italian-dressing/bar bottle"},
    {img: bottle10, link: "/products/honeycomb-mustard-dressing/bar bottle"}
];

class Bottles extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getBottles() {
      return (
          bottlesSlider.map((item, idx) => {
              return (
                  <Link key={idx} to={item.link}>
                      <img src={item.img} alt="" />
                  </Link>
              )
          })
      );
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 9,
            slidesToScroll: 9,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                }
            ]
        };

        return (
            <div>
                <div className="body-wrapper bottles column pampas">
                    <h1 className="short">Raise the (salad) bar</h1>
                    <Slider {...settings}>
                        {this.getBottles()}
                    </Slider>
                    <div className="descr short">More and more people are eating healthy these days. So more operators are adding salad bars. To increase diner visits, give them the full-on flavor they crave, with Ken’s pour bottles. So many delicious flavors in easy-to-use bottles.</div>
                    <Link
                        className="button"
                        to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b65'}}}
                    >See all pour bottles</Link>
                </div>

                <div className="hero-wrapper bottles">
                    <h2>Ken’s pour bottles put customers in control</h2>
                    <div className="descr">Easy-to-use, easy-to-implement and easy-to-fall-in-love-with, our pour bottles make it simple for your customers to add their favorite Ken’s flavors to whatever they’re eating.</div>
                </div>

                <div className="body-wrapper bottles">
                    <h2>Designed for the real world</h2>
                    <div className="bottle-wrapper">
                        <div className="bottle-item">
                            <div className="circle"><img src={oneBottle} alt="" /></div>
                            <div className="descr">Pourable bottle design and hinged cap limits air from impacting product and reduces spoilage after opening.</div>
                        </div>
                        <div className="bottle-item">
                            <div className="circle"><img src={oneBottle} alt="" /></div>
                            <div className="descr">Heat-sealed neckband makes varieties easy to identify.</div>
                        </div>
                        <div className="bottle-item">
                            <div className="circle"><img src={label} alt="" /></div>
                            <div className="descr">Transparent, consumer-friendly labeling that displays nutritional and ingredient information on every bottle.</div>
                        </div>
                    </div>
                </div>

                <div className="hero-wrapper bottles2">
                    <h2>Clean up with clean label</h2>
                </div>

                <div className="body-wrapper bottles">
                    <div className="benefits-wrapper">
                        <img src={creamyBottle} alt="" />
                        <div>
                            <div className="descr">Today’s diners want to spend less time trying to pronounce ingredients and more time enjoying them. That’s why they love <Link to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b65', productLines: '5bd97315a842a126f5517054' }}}>Ken’s Essentials</Link> pour bottles. They give customers everything they want, and nothing they don’t. No artificial flavors, preservatives or high fructose corn syrup.</div>
                            <div className="descr"><span>72%</span> of consumers express increased concern regarding food additives.</div>
                            <div className="descr"><span>82%</span> of operators agree that clean labels will have a great or moderate influence on purchase decisions in the near future.</div>
                        </div>
                    </div>
                    <div className="remark">Source: Technomic 2016, Clean Labeling and Sustainability</div>
                </div>

                <div className="body-wrapper">
                    <Link to={{ pathname: '/products', state: { productLines: '5bd97315a842a126f5517054' }}} className="button">
                        See all ken's essentials
                    </Link>
                </div>

                <div className="body-wrapper column">
                    <h2>Want us to do you a flavor?</h2>
                    <div className="button-group">
                        <Link className="button" to="/get-sample">request a sample</Link>
                        <Link className="button" to={{ pathname: '/products', state: { package: '5b6c4ea8b7400b01f9a16b65'}}}>get more details</Link>
                    </div>
                </div>

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="bar-bottles" />
                </Suspense>
            </div>
        );
    }
}

export default Bottles;
