import React, { PureComponent, Suspense, lazy } from 'react';

import './about.scss';

import aboutThumb1 from '../../assets/img/about-thumb1.png';
import aboutThumb2 from '../../assets/img/about-thumb2.png';
import aboutThumb3 from '../../assets/img/about-thumb3.png';
import aboutThumb4 from '../../assets/img/about-thumb4.png';

import service1 from '../../assets/img/icon-service1.png';
import service2 from '../../assets/img/icon-service2.png';
import service3 from '../../assets/img/icon-service3.png';
import service4 from '../../assets/img/icon-service4.png';
import service5 from '../../assets/img/icon-service5.png';

import chef1 from '../../assets/img/chef-chris.jpg';
import chef2 from '../../assets/img/chef-gregory.jpg';
import chef3 from '../../assets/img/chef-john.jpg';

// import logo1 from '../../assets/img/wing-logo.png';
// import logo2 from '../../assets/img/dominos-logo.png';
// import logo3 from '../../assets/img/duke-logo.png';
// import logo4 from '../../assets/img/bk-logo.png';

import feature1 from '../../assets/img/feature1.jpg';
import feature2 from '../../assets/img/feature2.jpg';
import feature3 from '../../assets/img/feature3.jpg';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));

class About extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
                <div className="hero-wrapper about">
                    <h1>Ken's. Kings of flavor.</h1>
                    <div className="descr short">We’re all about flavor. Creating it, perfecting it and delivering it in the widest variety of dressings, sauces and marinades - and in every package size and shape. Whatever you need, we’re ready to roll up our sleeves and get it done.</div>
                </div>

                <div className="body-wrapper about">
                    <div className="info-block">
                        <div className="circle">Why Kens</div>
                        <ul>
                            <li><strong>Dressings:</strong> Unparalleled breadth and depth. No one even comes close.</li>
                            <li><strong>Sauces:</strong> Premium barbecue, wing sauces, specialty sauces and much more.</li>
                            <li><strong>Packaging:</strong> Every size and shape imaginable. (Yep, even that one.)</li>
                            <li><strong>Market smarts:</strong> Customers across the country have a wide range of preferences. We can satisfy them all.</li>
                            <li><strong>Number one</strong> foodservice dressing and sauce brand in the country. Hate to brag, but…</li>
                        </ul>
                    </div>
                </div>

                <div className="body-wrapper about pampas">
                    <h2>One family’s obsession with flavor</h2>
                    <div className="descr short">It all started in the basement of Ken’s Steakhouse in Framingham, Massachusetts eight decades ago. What began as a single beloved family recipe is now a thriving business with hundreds of flavors sold nationwide. Ken’s is still run by the same flavor-obsessed family. We’ve surrounded ourselves with some of the best culinary minds in the business as we continue to create the widest variety of delicious sauces and dressings.
                    </div>
                    <div className="img-wrapper">
                        <div className="img-item">
                            <img src={aboutThumb1} alt="" />
                            <small>1941: Ken’s Steak House opens in Framingham, MA</small>
                        </div>
                        <div className="img-item">
                            <img src={aboutThumb2} alt="" />
                            <small>1958: Frank and Louise Crowley begin bottling and selling the steak house’s popular dressing from their kitchen</small>
                        </div>
                        <div className="img-item">
                            <img src={aboutThumb3} alt="" />
                            <small>1978: Ken’s Foodservice begins operation, supplying dressings and sauces to local partners</small>
                        </div>
                        <div className="img-item">
                            <img src={aboutThumb4} alt="" />
                            <small>1984: Ground is broken on new corporate headquarters in Marlborough, MA</small>
                        </div>
                    </div>
                </div>

                <div className="hero-wrapper about2">
                    <h2>Culinary service. And we do mean service.</h2>
                </div>

                <div className="body-wrapper about">
                    <div className="service-wrapper">
                        <div className="service">
                            <div className="service-img"><img src={service1} alt="" /></div>
                            <p><strong>Our chefs</strong> have been in your shoes. They understand your problems, and can help you solve them.</p>
                        </div>
                        <div className="service">
                            <div className="service-img"><img src={service2} alt="" /></div>
                            <p><strong>Menu Ideation:</strong> We work with you to develop a holistic menu strategy based on Technomic data and customer preferences.</p>
                        </div>
                        <div className="service">
                            <div className="service-img"><img src={service3} alt="" /></div>
                            <p>Ken’s has a state-of-the-art <strong>commercial kitchen</strong> that enables us to re-create your environment and see how our ideas play out in the real world.</p>
                        </div>
                        <div className="service">
                            <div className="service-img"><img src={service4} alt="" /></div>
                            <p>A beautiful <strong>presentation kitchen</strong> where you can sample all our big ideas in person.</p>
                        </div>
                        <div className="service">
                            <div className="service-img"><img src={service5} alt="" /></div>
                            <p><strong>An R&D team</strong> that’s triple the size of our nearest competitor. So we can help keep your menu on trend.</p>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper about pampas">
                    <h2>Meet the chefs</h2>
                    <div className="descr short">Our chefs are culinary masterminds with a combined 70 years of experience.<br /> They understand this is a business and are focused on helping you build yours.</div>
                    <div className="service-wrapper">
                        <div className="block flip">
                            <div className="flip-inner">
                                <div className="front">
                                    <div className="block-img"><img className="no-zoom" src={chef1} alt="" /></div>
                                    <div className="block-txt">
                                        <div className="block-title">Chris<br/> Gatto</div>
                                    </div>
                                </div>
                                <div className="back dark">
                                    <p><strong>Chris Gatto, Corporate Executive Chef (CEC)</strong><br/>
                                    Chris’ combination of manufacturing and operations experience allows him to understand the customer’s needs and create products that can be easily executed at the unit level. Chris holds an Associate Degree in Culinary Arts, a Bachelor’s in Food Marketing and a Masters of Arts in Teacher Education. He is a certified executive chef with the American Culinary Federation and a member of the Research Chefs Association.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block flip">
                            <div className="flip-inner">
                                <div className="front">
                                    <div className="block-img"><img className="no-zoom" src={chef2} alt="" /></div>
                                    <div className="block-txt">
                                        <div className="block-title">Gregory<br/> Schweizer</div>
                                    </div>
                                </div>
                                <div className="back dark">
                                    <p><strong>Gregory Schweizer, Corporate Executive Chef</strong><br/>
                                    Chef Greg is committed to high quality culinary solutions using knowledge, strategy, and innovation. He trained extensively at the CIA, Le Istituto Culinario de Toscano, Halliburton Institute of Technology, Eckerd College Management Development Institute, Darden University, and Applebee’s Leadership Institute.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper about features">
                    <div className="feature-block">
                        <h3>Supply chain</h3>
                        <img src={feature1} alt="" />
                        <p>With four strategically located manufacturing plants, we can deliver flavor to your doorstep in no time flat. Hear that, Sheboygan?</p>
                    </div>
                    <div className="feature-block">
                        <h3>Food safety and quality</h3>
                        <img src={feature2} alt="" />
                        <p>We’re all about flavor. We’ve made that clear. But we’re also hyper-focused on food safety and quality control. Just ask the pros at SQF, where we achieved all three certification levels.</p>
                    </div>
                    <div className="feature-block">
                        <h3>Sustainability</h3>
                        <img src={feature3} alt="" />
                        <p>We’re always looking for ways to reduce impact and improve efficiency. Some recent initiatives:</p>
                        <ul>
                            <li>Our Combined Heat & Power Project (CHP) creates usable fuel for generators that supply 60% of plant electricity and 30% of steam.</li>
                            <li>37 tons of ingredient containers gets recycled.</li>
                            <li>260 tons of corrugated scrap gets recycled.</li>
                            <li>600 tons of reusable corrugated boxes is returned to vendor.</li>
                            <li>1,200 tons of cardboard gets recycled.</li>
                            <li>4,000 steel and plastic drums get recycled.</li>
                            <li>Battery-powered forklifts are used in lieu of propane, diesel or gasoline.</li>
                        </ul>
                    </div>
                </div>

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="about-us" />
                </Suspense>
            </div>
        );
    }
}


export default About;
