import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSearchResults } from '../search-result/actions';

import './chickn-dipn.scss';

import logo from '../../assets/img/kens-logo-white.svg';
import buffaloWing from '../../assets/img/offers/chickn-dipn/buffalo_wing_sauce.png';
import sweetGarlic from '../../assets/img/offers/chickn-dipn/sweet_garlic_teriyaki.png';
import greenChili from '../../assets/img/offers/chickn-dipn/ke3192_2_signature_green_chili_aioli.png';

import chicknDipnSellSheet from '../../assets/pdf/kens_signature_chickn_dipn_sauce_pos.pdf';
import restaurant from '../../assets/img/offers/chickn-dipn/chickn-dipn-img1.jpg';
import operator from '../../assets/img/offers/chickn-dipn/chickn-dipn-img2.jpg';
import customer from '../../assets/img/offers/chickn-dipn/chickn-dipn-img3.jpg';
import fries from '../../assets/img/offers/chickn-dipn/chickn-dipn-dish1.png';
import sandwich from '../../assets/img/offers/chickn-dipn/chickn-dipn-dish2.png';
import biscuit from '../../assets/img/offers/chickn-dipn/chickn-dipn-dish3.png';

const SourceDataProductDetails = lazy(() => import('./components/SourceDataProductDetails'));

const productId = [ '5d71e6a4fd9c500013cd89ec' ]; // Chick’n Dip’n Sauce (KE3419-2)

class ChicknDipn extends PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: productId,
            keys: [ 'products']
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    filterResults(key) {
        const { searchResults } = this.props;
        const entity = searchResults.find((sr) => sr[key]);
        return entity && (key === 'products' ? entity[key][0] : entity[key]);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);
        const product = isDataLoaded && this.filterResults('products');
        const dipnThumb = product && (
            <img src={product.details.websiteThumbnail} alt={product.details.name} />
        );

        return (
            <>
                <div className="hero-wrapper chickn-dipn">
                    <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                    <h1>Introducing a whole new breed of chicken dipping sauce.</h1>
                </div>

                <div className="body-wrapper chickn-dipn">
                    <div className="product-intro">
                        {dipnThumb}
                        <div>
                            <h2>Ken’s new Chick’n Dip’n sauce.<br/> All the flavors chicken lovers love.<br/> All in one delicious sauce.</h2>
                            <p>Most chicken dipping sauces are just honey mustard spin-offs. Yawn. But our new sauce goes further. It’s a tempting blend of everything diners want – smoky, peppery, tomatoey, tangy goodness.</p>
                            <div className="button-group">
                                <a className="button" href={chicknDipnSellSheet}>download sell sheet</a>
                                {product && (
                                    <>
                                        <Link to={`/products/${product.slug}`} className="button">see more product info</Link>
                                        <a className="button" href={product.details.files.pdf}>view specs</a>
                                    </>
                                )}
                                <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Chick&#39;n Dip&#39;n Landing page' }}}>request a sample</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper pampas chickn-dipn">
                    <h2>Cash in on a growing trend</h2>
                    <div className="descr">Dipping sauces and chicken are on the rise, and customers want them. Badly. Sales are taking off: the top 500 LSR chicken chains grew 8 percent to $27.1 billion. To take advantage of these growing trends, operators are enhancing classic fried chicken dishes and sandwiches with unique sauces and flavors.</div>
                    <div className="feature-wrapper">
                        <div>
                            <div className="feature-block">
                                <img src={restaurant} alt="" />
                                <span>42%</span>
                            </div>
                            <div className="descr">of consumers expect restaurants to offer signature flavors, and 50% of millennials agree.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={operator} alt="" />
                                <span>43%</span>
                            </div>
                            <div className="descr">of foodservice operators have a dipping sauce on their menu.</div>
                        </div>
                        <div>
                            <div className="feature-block">
                                <img src={customer} alt="" />
                                <span>Plus,</span>
                            </div>
                            <div className="descr">customers are more willing to order an item if it comes with a signature sauce.</div>
                        </div>
                    </div>
                    <div className="remark">Sources: Technomic 2019 Consumer Trend Report</div>
                </div>

                <div className="body-wrapper chickn-dipn">
                    <h2>Chick’n Dip’n Sauce recipes</h2>
                    <div className="descr">Bold new flavors that will have customers chowin’ down more chicken.</div>
                    <div className="recipe-wrapper">
                        <Link to="/recipes/dipn-chicken-fries" className="recipe-item">
                            <span>Dip’n Chicken Fries</span>
                            <img src={fries} alt="Dip’n Chicken Fries" />
                        </Link>
                        <Link to="/recipes/chickn-dipn-club-sandwich" className="recipe-item">
                            <span>Chicken Dip’n Club Sandwich</span>
                            <img src={sandwich} alt="Chicken Dip’n Club Sandwich" />
                        </Link>
                        <Link to="/recipes/crispy-chicken-dipn-biscuit" className="recipe-item">
                            <span>Crispy Chicken Dip’n Biscuit</span>
                            <img src={biscuit} alt="Crispy Chicken Dip’n Biscuit" />
                        </Link>
                    </div>
                </div>

                <div className="body-wrapper pampas column chickn-dipn">
                    <h2>Product Information</h2>
                    {isDataLoaded ? <Suspense fallback={null}>
                        <SourceDataProductDetails settings={{
                                size: '2/1 Gallon',
                            weight: '17.7 lbs',
                            storage: 'Shelf Stable'
                        }} />
                    </Suspense> : <p>Loading...</p>}
                </div>

                <div className="body-wrapper column chickn-dipn">
                    <h2>Make it your own</h2>
                    <div className="descr short">Put your own spin on things with these unique dip’n sauce recipes. Better yet, create one of your own. They’re super versatile, and always delicious.</div>
                    <div className="make-it-wrapper">
                        <div className="make-it-item">
                            <div className="make-it-imgs">{dipnThumb}+<img src={buffaloWing} alt="" /></div>
                            <p><strong>Dip’n Buffalo</strong></p>
                            <p>16 fl oz Ken’s Signature Chick’n Dip’n Sauce</p>
                            <p>8 fl oz Sweet Baby Ray’s Buffalo Wing Sauce (SJ1736)</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs">{dipnThumb}+<img src={sweetGarlic} alt="" /></div>
                            <p><strong>Sweet Garlic Soy Dip’n</strong></p>
                            <p>12 fl oz Ken’s Signature Chick’n Dip’n Sauce</p>
                            <p>12 fl oz Kogi Sweet Garlic Teriyaki (KG3262HG)</p>
                        </div>
                        <div className="make-it-item">
                            <div className="make-it-imgs">{dipnThumb}+<img src={greenChili} alt="" /></div>
                            <p><strong>Green Chili Dip’n</strong></p>
                            <p>12 fl oz Ken’s Signature Chick’n Dip’n Sauce</p>
                            <p>12 fl oz Ken’s Signature Green Chili Aioli (KE3192-2)</p>
                        </div>
                    </div>
                    <p>To prepare, place all ingredients in a bowl and mix well, then cover, label, date and refrigerate.</p>
                </div>

                <div className="body-wrapper secondary">
                    <h2>Go ahead. Get dip’n.</h2>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(ChicknDipn);
