import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    errors: null,
    sending: false,
    showSuccessModal: false,
    somethingWentWrong: false,
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.SUBMIT_FEEDBACK_START:
            updatedState.sending = true;
            updatedState.showSuccessModal = false;
            break;
        case types.SUBMIT_FEEDBACK_FAILED:
            updatedState.sending = false;
            if (action.error) {
                if (action.error.errors)
                    updatedState.errors = action.error.errors;
                else
                    updatedState.somethingWentWrong = true;
            }
            break;
        case types.SUBMIT_FEEDBACK_SUCCESS:
            updatedState.sending = false;
            updatedState.showSuccessModal = true;
            break;
        case types.SUBMIT_FEEDBACK_COMPLETED:
            updatedState.showSuccessModal = false;
            updatedState.somethingWentWrong = false;
            break;
        default:
            break;
    }

    return updatedState;
}
