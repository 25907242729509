import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Modal from '../../../components/modal/Modal'

import {
    sendEmailWithFiles,
    clearEmailWithFilesData,
} from '../../recipes/actions'

class EmailModal extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleClose() {
        this.props.closeModal()
        this.props.clearEmailWithFilesData()
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleSubmit(e) {
        const attachments = []
        const { email } = this.state
        const { type, pdfFilePath } = this.props

        e.preventDefault()

        if (pdfFilePath) {
            attachments.push({ href: pdfFilePath })
        }

        if (attachments.length) {
            this.props.sendEmailWithFiles({ email, attachments, type })
        }
    }

    render() {
        const {
            productName,
            display,
            emailSending,
            emailShowSuccessMessage,
            emailSomethingWentWrong,
        } = this.props

        return (
            <Modal visible={display} onClose={this.handleClose}>
                {emailShowSuccessMessage && (
                    <h3>Thanks so much, your email is on the way.</h3>
                )}
                {emailSomethingWentWrong && (
                    <h3>Oops! Something went wrong.</h3>
                )}
                {!emailShowSuccessMessage && !emailSomethingWentWrong && (
                    <>
                        <h3>Product information for {productName}</h3>
                        <p className="descr">
                            Include recipes, flavor foundations, nutritional
                            facts, and product information
                        </p>
                        <div className="modal-content">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group one-row">
                                    <input
                                        required
                                        type="email"
                                        name="email"
                                        onChange={this.handleInputChange}
                                        placeholder="Email address*"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                    <div className="submit">
                                        {emailSending && (
                                            <button disabled>
                                                Sending, please wait...
                                            </button>
                                        )}
                                        {!emailSending && (
                                            <button type="submit">Send</button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    emailSending: state.recipes.emailSending,
    emailShowSuccessMessage: state.recipes.emailShowSuccessMessage,
    emailSomethingWentWrong: state.recipes.emailSomethingWentWrong,
})

export default connect(
    mapStateToProps,
    {
        sendEmailWithFiles,
        clearEmailWithFilesData,
    }
)(EmailModal)
