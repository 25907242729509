import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router";

import { useLocation, useHistory } from 'react-router-dom';

import routes from './routes.json';

import Api from '../src/helpers/api';
import MetaTags from '../src/components/meta';

const RouteComponent = ({ path, component, routes }) => {
    const Component = require(`${component}.jsx`).default;

    const [seo, setSeo] = useState({ title: '', description: '', canonical: '' });

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (Array.isArray(routes)) {
            // Skip meta tags for sub router components
            return;
        }

        (async () => {
            const { data } = await Api.get(`/api/seo?path=${path}`)
            if (data) {
                const { title, description, canonical } = data;
                setSeo({ title, description, canonical });
            }
        })();
    }, []);

    return (
        <>
            {seo && (
                <MetaTags
                    noIndexNoFollow={process.env.REACT_APP_ENVIRONMENT === 'staging'}
                    {...seo}
                />
            )}
            <Component history={history} location={location} />
        </>
    );
};

export default () => {

    return (
        <Switch>
            {routes.map(({ path, component, props, routes }, index) => {
                const properties = props || {};
                if (path) {
                    properties.path = path;
                }

                return (
                    <Route key={index} {...properties}>
                        <RouteComponent path={path} component={component} routes={routes} />
                    </Route>
                );
            })}
        </Switch>
    );
};
