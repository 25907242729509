import { takeLatest, put } from 'redux-saga/effects';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const getSiteKey = function* (action) {
    try {
        const apiResponse = yield ApiClient.get('/api/sitekey', action.payload);
        yield put({ type: types.GET_RECAPTCHA_SITEKEY_SUCCESS, payload: apiResponse });
    }
    catch (err) {
        yield put({ type: types.GET_RECAPTCHA_SITEKEY_FAILED, error: err });
    }
};

export default [
    takeLatest(types.GET_RECAPTCHA_SITEKEY_START, getSiteKey)
];
