import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import FeedbackWrapper from '../../components/FeedbackWrapper';
import SourceDataRecipes from '../../components/sourceDataItems/Recipes';
import SourceDataProducts from '../../components/sourceDataItems/Products';

import './bowl.scss';

import bowl from '../../assets/img/bowl-board.png';
import set1 from '../../assets/img/bowl-set1.png';
import set2 from '../../assets/img/bowl-set2.png';
import product1 from '../../assets/img/products/ke0777.png';
import product2 from '../../assets/img/products/ke2018.png';

import bowlInsightsPDF from '../../assets/pdf/Kens_Bowl_Technomics.pdf';
import bowlBrochurePDF from '../../assets/pdf/Kens_Bowls_RecipePOS.pdf';

const products = [
    { _id: '5bd97315a842a126f5517159', displayOrder: 1 }, // Chipotle Ranch Dressing
    { _id: '5bd97315a842a126f5517127', displayOrder: 2 }, // Mandarin Orange Sesame Ginger Dressing
    { _id: '5bd97315a842a126f55170b7', displayOrder: 3 }, // Cannonball Barbecue Sauce
    { _id: '5bd97315a842a126f551713b', displayOrder: 4 }  // Home Style Ranch Dressing
];
const recipes = [
    { _id: '6035128caffdf000118395dc', displayOrder: 1 }, // All American Greens And Grains Bowl
    { _id: '5bd97312a842a126f5516e68', displayOrder: 2 }, // Farro Salad With Blackberries And Tarragon Shallot Vinaigrette
    { _id: '5bd97312a842a126f5516f05', displayOrder: 3 }, // Crispy Rice Salad With Sherry Shallot Vinaigrette
    { _id: '603517ce8ef26b00114061d6', displayOrder: 4 }, // Crunchy Mandarin Orange Rice Bowl
    { _id: '5bd97312a842a126f5516e9f', displayOrder: 5 }, // Big Tex Taco Salad
    { _id: '6035842daffdf000118396f1', displayOrder: 6 },  // Thai Satay Noodle Bowl
    { _id: '6035892eaffdf00011839702', displayOrder: 7 },  // Korean Zoodle Bowl
    { _id: '603530c2affdf00011839612', displayOrder: 8 }  // Mediterranean Chicken And Whole Grain Bowl
];

class Bowl extends Component {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Bowl menu insights</h1>
                    <div className="descr short">Learn what customers are craving, find new recipes and discover sauces that 
                    will take your power bowls to the next level.</div>
                </div>

                <div className="hero-wrapper bowl"></div>

                <div className="body-wrapper bowl">
                    <div className="feature-wrapper">
                        <img src={bowl} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">27%</div>
                                <div className="descr">of Gen Zers and millennials would order poke bowls</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">10.5%</div>
                                <div className="descr">growth of power bowls and keto bowls across menus at U.S. top 1,500 restaurants </div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">12%</div>
                                <div className="descr">of consumers have tried or would try a keto diet</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper bowl">
                    <h2>These top dressing flavors are<br/> driving the popularity of power bowls</h2>
                </div>

                <div className="body-wrapper bowl">
                    <div className="benefits-wrapper">
                        <div className="descr">Ranch Dressing<span>#1</span></div>
                        <img src={product1} alt="" />
                        <div className="descr">Chipotle Sauce<span>#2</span></div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Southwest Chicken Harvest Rice Bowl with Ken’s chipotle ranch dressing</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Mediterranean Chicken and Whole Grain Bowl WITH KEN’S BALSAMIC VINAIGRETTE DRESSING</span>
                        </div>
                        <div className="descr">Balsamic Vinaigrette<span>#3</span></div>
                        <img src={product2} alt="" />
                        <div className="descr">Lime Dressing<span>#4</span></div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a
                        className="button" 
                        href={bowlInsightsPDF}
                        data-gtm-download-more-insights-bowl-button-link-a
                        download
                    >
                        download more insights
                    </a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <SourceDataRecipes settings={recipes} /> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a 
                            className="button" 
                            href={bowlBrochurePDF} 
                            data-gtm-download-brochure-insights-bowl-button-link-a 
                            download
                        >
                            download bowls brochure
                        </a>
                        <Link 
                            to={{ pathname: '/recipes', hash: '#allRecipes', state: { dishes: "60185e932b9fd90012f9e4c7" }}} 
                            className="button" 
                            data-gtm-see-all-insights-bowl-button-link-a>
                                see all bowls recipes
                        </Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <SourceDataProducts
                        className="bowl"
                        settings={products}
                        title="Bowl sauces we know you'll love" />
                )}

                <FeedbackWrapper page="bowl" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Bowl);
