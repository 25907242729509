import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './hot.scss';

import img1 from '../../assets/img/hot-img1.jpg';
import img2 from '../../assets/img/boom_boom.jpg';
import img3 from '../../assets/img/thumb.png';
import img4 from '../../assets/img/hot-img6.jpg';

const blocks = [
    {link: '/offers/stadium-staples', blockImg: img1, title: "8 winning ways to own Game Day"},
    {link: '/offers/boom-boom', blockImg: img2, title: "This sauce goes to 11"},
    {link: '/cups', blockImg: img3, title: "The skinny on dipping"},
    {link: '/offers/fall-flavors', blockImg: img4, title: "It’s salad pickin’ time"}
];

class Hot extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getBlocks() {

        return (
            blocks.map((item, idx) => {
                return (
                    <Link to={item.link} className="block recipe gray" key={idx}>
                        <div className="block-img"><img className="no-zoom" src={item.blockImg} alt="" /></div>
                        <div className="block-txt">
                            <div className="block-title"><p>{item.title}</p></div>
                        </div>
                    </Link>
                )
            })
        );
    }

    render() {

        return (
            <div>
                <div className="hero-wrapper hot">
                    <h1>What's hot now</h1>
                    <div className="descr">The latest flavors, hottest new recipes, menu trends and industry analysis. All at your fingertips.</div>
                </div>
                <div className="body-wrapper">
                    {this.getBlocks()}
                </div>
            </div>
        );
    }
}

export default Hot;
