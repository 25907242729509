import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';


const initialState = {
    blocks: [],
    searchResults: [],
    loadingResults: false,
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.SEARCH_START:
            updatedState.loadingResults = true;
            break;
        case types.SEARCH_SUCCESS:
            if (action.payload.data) {
                const results = [];
                action.payload.data.forEach(d =>
                    Object.keys(d)[0] && results.push(d));
                updatedState.searchResults = results;
            } else {
                updatedState.searchResults = [];
            }
            updatedState.loadingResults = false;
            break;
        case types.SEARCH_FAILED:
            updatedState.loadingResults = false;
            break;
        case types.SEARCH_CLEAR:
            updatedState.searchResults = [];
            break;
        case types.LOAD_BLOCKS_SUCCESS:
            updatedState.blocks = action.payload;
            break;
        default:
            break;
    }

    return updatedState;
}
