import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';

const Pdf = lazy(() => import('./Pdf'));
const EmailModal = lazy(() => import('./EmailModal'));

class SourceDataProductDetails extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            display: false
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({ display: !this.state.display });
    }

    render() {
        const { searchResults, settings } = this.props;
        const entity = searchResults.find((sr) => sr['products']);
        const productEntity = entity && entity['products'] && entity['products'][0];

        if (productEntity) {
            const {
                details: {
                    name, productCode, caseInfo,
                    files: { pdf: pdfAssetUrl },
                    claims: { marketing }, websiteThumbnail
                }
            } = productEntity;
            const { kosher = '', kosherDairy = '' } = marketing || {};

            return (
                <>
                    <div className="product-wrapper">
                        <img src={websiteThumbnail} alt={name} />
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Product Name</td>
                                        <td>{name}</td>
                                    </tr>
                                    <tr>
                                        <td>Product Code</td>
                                        <td>{productCode}</td>
                                    </tr>
                                    <tr>
                                        <td>UPC</td>
                                        <td>{caseInfo.caseGtin}</td>
                                    </tr>
                                    <tr>
                                        <td>Size</td>
                                        <td>{settings.size}</td>
                                    </tr>
                                    {window.location.pathname === "/offers/dill-pickle" || window.location.pathname === "/offers/avocado-ranch" ? null : (
                                        <tr>
                                            <td>Weight</td>
                                            <td>{settings.weight}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Storage</td>
                                        <td>{settings.storage}</td>
                                    </tr>
                                    <tr>
                                        <td>Gluten Free</td>
                                        <td>{marketing.gluten}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                          Kosher {kosher === 'Yes' && kosherDairy === 'Yes' ? 'Dairy' : ''}
                                        </td>
                                        <td>{kosher || 'No'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="button-group">
                                <button onClick={() => this.toggleModal()}>email product info</button>
                                <ReactToPrint
                                    content={() => this.componentRef}
                                    trigger={() => <button className="button">print product info</button>}
                                />
                                <div style={{ display: "none" }}>
                                    <Suspense fallback={null}>
                                        <Pdf ref={el => (this.componentRef = el)} path={pdfAssetUrl} />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Suspense fallback={<p className="loading">Loading...</p>}>
                        <EmailModal
                            type='product'
                            productName={name}
                            pdfFilePath={pdfAssetUrl}
                            display={this.state.display}
                            closeModal={this.toggleModal} />
                    </Suspense>
                </>
            );
        }

        return null;
    }
}

const mapStateToProps = state => ({
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps)(SourceDataProductDetails);
