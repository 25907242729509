import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSearchResults } from '../search-result/actions';

import './boom.scss';

import logo from '../../assets/img/kens-logo-white.svg';
import sandwiches from '../../assets/img/offers/boom-boom/sandwiches.png';
import shrimps from '../../assets/img/offers/boom-boom/shrimps.png';
import fritters from '../../assets/img/offers/boom-boom/fritters.png';

const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProductDetails = lazy(() => import('./components/SourceDataProductDetails'));

const productId = [ '5bd97315a842a126f551711b' ]; // Boom Boom Sauce (KE1936)
const recipes = [
    { _id: '5bf865ea552e042ce4ad3cea', displayOrder: 1 }, // Street corn fritters with boom boom sauce
    { _id: '5bf86ebd38bbe1336aab01be', displayOrder: 2 }, // Butcher block deluxe sandwich
    { _id: '5bf87155285ac8343f6c80ef', displayOrder: 3 }, // Boom boom shrimp and calamari
    { _id: '5bf872ffa458553553ed595e', displayOrder: 4 }, // Stuffed poblano peppers
    { _id: '5bf877b31c5d6d363380d31c', displayOrder: 5 }, // Boom boom fried catfish
    { _id: '5bf87a02f7db843878a0b8ae', displayOrder: 6 }, // Bacon wrapped tater tot bombs
    { _id: '5bf87be0411fc3391faed4cb', displayOrder: 7 }, // Boom boom breakfast pizza
    { _id: '5bf87e16dfdcaf39e3ecd22d', displayOrder: 8 }  // Boom boom mac & cheese
];

class Boom extends PureComponent {

    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            keys: [ 'products', 'recipes' ],
            ids: [...recipes.map(r => r._id), ...productId ]
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <>
                <div className="hero-wrapper boom">
                    <Link to="/" className="logo"><img src={logo} alt="Ken's - A Family Owned Company" /></Link>
                    <h1>Here comes the Boom Boom</h1>
                    <div className="descr">Give your menu a kick with Boom Boom Sauce, a sizzling, savory sauce that brings the flavor and attitude customers crave.</div>
                </div>

                <div className="body-wrapper boom">
                    <h2>Fiery flavors are hotter than ever</h2>
                    <div className="descr short">More and more, consumers are looking for bold, ethnic, or even spicy flavors when they go out to eat.</div>
                    <div className="benefits-wrapper">
                        <div>
                            <div className="descr"><span>52%</span>of younger customers find bold flavors more appealing than older customers.</div>
                            <img src={sandwiches} alt="" />
                        </div>
                        <div>
                            <div className="descr"><span>62%</span>of consumers say they are likely to use hot sauce on certain, if not most, foods.</div>
                            <img src={shrimps} alt="" />
                        </div>
                        <div>
                            <div className="descr"><span>50%</span>of consumers enjoy very spicy flavors.</div>
                            <img src={fritters} alt="" />
                        </div>
                    </div>
                    <div className="remark">Source: Technomic, 2018 Soup & Salad, 2017 Flavor Consumer Trend Reports</div>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Featured boom boom sauce recipes</h2>
                    <div className="descr short">Dishes that pack a punch.</div>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                </div>

                <div className="body-wrapper column boom">
                    <h2>Product Information</h2>
                    {isDataLoaded ? <Suspense fallback={null}>
                        <SourceDataProductDetails settings={{
                            size: '4/1 Gallon',
                            weight: '33.67 LB',
                            storage: 'From 50°F to 80°F'
                        }} />
                    </Suspense> : <p>Loading...</p>}
                </div>

                <div className="body-wrapper column primary">
                    <h2>Interested? Try it yourself.</h2>
                    <Link className="button" to={{ pathname: '/get-sample', state: { gaLabel: 'Sample Request Submit – Boom Boom Landing page' }}}>request a sample</Link>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(Boom);
