import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { PureComponent, Suspense, lazy } from 'react';

import { fetchSearchResults } from '../search-result/actions';

import './breakfast-sandwiches.scss';

import breakfastSandwich from '../../assets/img/sandwich-breakfast-board.png';
import set1 from '../../assets/img/sandwich-breakfast-set1.png';
import set2 from '../../assets/img/sandwich-breakfast-set2.png';
import product1 from '../../assets/img/products/ke1183_2.png';
import product2 from '../../assets/img/products/ke1662_basil_pesto_aioli.png';

import breakfastSandwichInsightsPDF from '../../assets/pdf/Kens_BreakfastSandwich_Technomics_v4.pdf';
import breakfastSandwichRecipesPDF from '../../assets/pdf/Kens_Breakfast_RecipeSellSheet_v4.pdf';
import breakfastSandwichFlavorPDF from '../../assets/pdf/KENS_PlusOnes_July_BreakfastSandwich_v4.pdf';

const FeedbackWrapper = lazy(() => import('../../components/FeedbackWrapper'));
const SourceDataRecipes = lazy(() => import('../../components/sourceDataItems/Recipes'));
const SourceDataProducts = lazy(() => import('../../components/sourceDataItems/Products'));

const products = [
    { _id: '5bd97315a842a126f551709d', displayOrder: 1 }, // Garlic Aioli
    { _id: '5bd97315a842a126f551709c', displayOrder: 2 }, // Remoulade Sauce
    { _id: '5c89dea5abf060001034e058', displayOrder: 3 }, // Horseradish Aioli
    { _id: '5bd97315a842a126f551711d', displayOrder: 4 }  // Apple CiderVinaigrette
];
const recipes = [
    { _id: '5e431df7e40908001182783f', displayOrder: 1 }, // Breakfast waffle club
    { _id: '5bd97312a842a126f5516f41', displayOrder: 2 }, // Benedict florentine
    { _id: '5e431c88e40908001182783e', displayOrder: 3 }, // California Breakfast Croissant
    { _id: '5bd97312a842a126f5516ec1', displayOrder: 4 }, // Big tex benedict
    { _id: '5e4320f4e409080011827845', displayOrder: 5 }, // Harissa bacon egg & cheese sandwich
    { _id: '5dc1a59dac9e690012f8b748', displayOrder: 6 }, // The italian stallion
    { _id: '5bd97312a842a126f5516f40', displayOrder: 7 }  // Route 9 breakfast sandwich
];

class BreakfastSandwiches extends PureComponent {
    constructor(props) {
        super(props);

        this.props.fetchSearchResults({
            ids: [
                ...recipes.map(r => r._id),
                ...products.map(p => p._id)
            ],
            keys: [ 'products', 'recipes' ]
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, searchResults } = this.props;
        const isDataLoaded = !loading && searchResults && Array.isArray(searchResults);

        return (
            <div>
                <div className="body-wrapper">
                    <h1>Breakfast sandwich menu insights</h1>
                    <div className="descr short">Learn what customers are craving, find new recipes and discover sauces that will take your breakfast sandwiches to the next level.
                    </div>
                </div>

                <div className="hero-wrapper breakfastsandwich"></div>

                <div className="body-wrapper breakfastsandwich">
                    <div className="feature-wrapper">
                        <img src={breakfastSandwich} alt="" />
                        <div className="feature-content">
                            <div className="feature-block">
                                <div className="percent-circle">54%</div>
                                <div className="descr">of consumers agree it’s unhealthy to skip breakfast</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">53%</div>
                                <div className="descr">of consumers will order a breakfast sandwich during the week</div>
                            </div>
                            <div className="feature-block">
                                <div className="percent-circle">40%</div>
                                <div className="descr">of consumers eat brunch at least once a week</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-wrapper breakfastsandwich">
                    <h2>A wide range of flavors are<br/> driving breakfast sandwich popularity forward</h2>
                </div>

                <div className="body-wrapper breakfastsandwich">
                    <div className="benefits-wrapper">
                        <div className="descr">Chipotle Mayo<span>8.3%</span>Growth</div>
                        <img src={product1} alt="" />
                        <div className="descr">Spicy Mustard<span>33.3%</span>Growth</div>
                        <div className="img-block">
                            <img src={set1} alt="" />
                            <span>Belgian Waffle Club with Ken’s SIGNATURE Brewpub Style Mustard Sauce</span>
                        </div>
                    </div>
                    <div className="benefits-wrapper">
                        <div className="img-block">
                            <img src={set2} alt="" />
                            <span>Benedict Florentine with Ken’s Signature Basil Pesto Aioli and Balsamic fig glaze</span>
                        </div>
                        <div className="descr">Balsamic<span>16.7%</span>Growth</div>
                        <img src={product2} alt="" />
                        <div className="descr">Aioli<span>7.7%</span>Growth</div>
                    </div>
                </div>

                <div className="body-wrapper">
                    <a className="button" href={breakfastSandwichInsightsPDF} download data-gtm-download-more-insights-breakfastSandwich-button-link-a>download more insights</a>
                </div>

                <div className="body-wrapper pampas">
                    <h2>Recipes</h2>
                    <div className="recipe-list">
                        {isDataLoaded ? <Suspense fallback={null}>
                            <SourceDataRecipes settings={recipes} />
                        </Suspense> : <p>Loading...</p>}
                    </div>
                    <div className="button-group">
                        <a className="button" href={breakfastSandwichRecipesPDF} data-gtm-see-all-insights-breakfastsandwich-button-link-a download>download breakfast sandwich brochure</a>
                        <Link to={{ pathname: '/recipes', hash: '#allRecipes', state: { courses: "5bd97313a842a126f5517034" }}} className="button" data-gtm-see-all-insights-breakfastsandwich-button-link-a>see all breakfast recipes</Link>
                    </div>
                </div>

                {isDataLoaded && (
                    <Suspense fallback={null}>
                        <SourceDataProducts settings={products} className="breakfastsandwich" title="Breakfast sandwich sauces we know you'll love">
                            <a className="button" href={breakfastSandwichFlavorPDF}>Make it your own ideas</a>
                        </SourceDataProducts>
                    </Suspense>
                )}

                <Suspense fallback={<p className="loading">Loading...</p>}>
                    <FeedbackWrapper page="breakfast-sandwiches" />
                </Suspense>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    searchResults: state.searchResults.list
});

export default connect(mapStateToProps, { fetchSearchResults })(BreakfastSandwiches);
